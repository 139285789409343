import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import {
  BidDeal,
  ProfileBidOffer,
  UserBid,
  UserBidDTO,
  UserBidListRequestFilter,
  UserBidListRequestSorting,
  UserBidState,
} from './types'

const initialState: UserBidState = {
  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: 10000,

  bidDeals: {},
}

class UserBidReducer extends ImmerReducer<UserBidState> {
  listRequested(params: {
    filter?: UserBidListRequestFilter
    sorting?: UserBidListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: UserBid[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = {
      ...this.draftState.items,
      ...arrToDict(list),
    }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map((item) => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }

  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: UserBid) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  bidDealRequested(id: string) {
    this.draftState.bidDeals[id] = {
      ...this.draftState.bidDeals[id],
      fetchProgress: Progress.WORK,
    }
  }

  bidDealRequestSucceed(id, deals: BidDeal[]) {
    this.draftState.bidDeals[id] = {
      ...this.draftState.bidDeals[id],
      fetchProgress: Progress.SUCCESS,
      deals,
    }
  }

  bidDealRequestFailed(id) {
    this.draftState.bidDeals[id] = {
      ...this.draftState.bidDeals[id],
      fetchProgress: Progress.ERROR,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(params: { dto: UserBidDTO }) {
    this.draftState.addProgress = Progress.WORK
  }

  addSucceed(item: UserBid) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  updateRequested(id: string, _item: Partial<UserBidDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
  }

  updateSucceed(item: UserBid) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }

  updateFailed(id: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
  }

  filterUpdated(filter: UserBidListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: UserBidListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: UserBid[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex((item) => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    this.draftState.meta[id].removeProgress = Progress.ERROR
  }

  sendBidOfferRequested(bidId: string, dealId: string, _dto: ProfileBidOffer) {
    this.draftState.bidDeals[bidId] = {
      ...this.draftState.bidDeals[bidId],
      offer: {
        [dealId]: {
          sendProgress: Progress.WORK,
        },
      },
    }
  }

  sendBidOfferSuccess(bidId: string, dealId: string) {
    this.draftState.bidDeals[bidId] = {
      ...this.draftState.bidDeals[bidId],
      offer: {
        [dealId]: {
          sendProgress: Progress.SUCCESS,
        },
      },
    }
  }

  sendBidOfferError(bidId: string, dealId: string) {
    this.draftState.bidDeals[bidId] = {
      ...this.draftState.bidDeals[bidId],
      offer: {
        [dealId]: {
          sendProgress: Progress.ERROR,
        },
      },
    }
  }
}

export const UserBidActions = createActionCreators(UserBidReducer)
export default UserBidActions
export const reducer = createReducerFunction(UserBidReducer, initialState)
