// const stripTrailingSlash = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url)
const addTrailingSlash = (url: string) => (url.endsWith('/') ? url : `${url}/`)

const idRx = /{id}/gi

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const urlMaker = (url: string) => (id?: number | string) => {
  if (typeof id === 'undefined') {
    return addTrailingSlash(url)
  }
  if (url.match(idRx)) {
    return addTrailingSlash(url.replace(idRx, String(id)))
  }
  return `${addTrailingSlash(url)}${id}/`
}

export const endpoints = {
  requestSmsCode: () => `/auth/get_code/`,
  emailLogin: () => '/auth/login/email/',
  phoneLogin: () => '/auth/login/',
  refreshToken: () => '/auth/refresh/',
  profile: () => '/users/me/',
  updateProfile: () => 'users/me/update_profile/',
  userAddress: urlMaker('/users/me/addresses/'),
  bid: urlMaker('/market/bids/'),
  bidPrice: urlMaker('/market/bids/{id}/prices/'),
  bidResponse: urlMaker('/market/bids/{id}/response/'),
  product: urlMaker('/products/'),
  companyDadata: () => '/companies/suggest/',
  signUpProfile: () => '/signup/profile/',
  signUpCompany: () => '/signup/company/',
  signUpAddress: () => '/signup/address/',
  fileUpload: () => '/file/upload/',

  offer: urlMaker('/bids/{id}/response/'),
  prices: urlMaker('/bids/{id}/prices/'),
  deal: urlMaker('/users/me/deals/'),
  userBid: urlMaker('/users/me/bids/'),
  userBidDeals: (id: string) => `/users/me/bids/${id}/deals/`,
  profileOffer: (id: string) => `/users/me/deals/${id}/response/`,

  company: urlMaker('/companies'),
}
