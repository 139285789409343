import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BackButton, Button, Input, useAction, useDidMount } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { AnalyticsEvent, Progress } from 'modules/types'
import { AvailableCountries } from 'types/entities'

const DELAY = 60

const SmsCodeInfo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const SmsInputContainer = styled.div`
  width: 128px;
  margin-top: 16px;
  position: relative;
`

const SmsCodeError = styled.span`
  position: absolute;
  left: 0;
  font-size: 12px;
  bottom: -22px;
  font-weight: 500;
  color: ${(props) => props.theme.color.accentDestructive};
  white-space: nowrap;
`

const RetryDelay = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
  white-space: nowrap;
`

const SmsFormButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 48px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

const SmsSubmitButtonContainer = styled.div`
  & > * {
    width: 100%;
  }
  width: 180px;

  ${(props) => props.theme.media.mobile`
    width: 100%;
  `}
`

const RetryBtn = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.primary500};
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    outline: none;
  }
`

const RetryWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(calc(100% + 16px));
  height: 40px;
  display: flex;
  align-items: center;
`

const SmsCancelButtonContainer = styled.div``

export type SmsCodeVerificationFormProps = {
  code: string
}

const SmsCodeVerificationForm: React.FC<{
  phone: string
  country: AvailableCountries
  onSubmit(props: SmsCodeVerificationFormProps): void
}> = ({ phone, country, onSubmit }) => {
  const { t } = useTranslation(['auth', 'common'])

  const progress = useSelector(AuthSelectors.smsVerificationProgress)

  const backAction = useAction(AuthActions.setStep, 'credentials')
  const submitPhoneAction = useAction(AuthActions.phoneSubmitted)
  const { track } = useAnalyticsSSR()
  const codeInputRef = useRef<HTMLInputElement>(null)
  const formik = useFormik<SmsCodeVerificationFormProps>({
    initialValues: {
      code: '',
    },
    onSubmit,
  })

  const [countdown, setCountdown] = useState(DELAY)
  const [timer, setTimer] = useState<number | null>(null)
  const [blocked, setBlocked] = useState(false)

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'signin_sms_screen' })
  })

  useEffect(() => {
    if (countdown) {
      const timer = window.setTimeout(() => {
        setCountdown((value) => Math.max(value - 1, 0))
      }, 1000)
      setTimer(timer)
    }
  }, [countdown])

  useEffect(
    () => () => {
      if (timer) {
        clearTimeout(timer)
      }
    },
    [timer],
  )

  useEffect(() => {
    codeInputRef.current?.focus()
  }, [])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    track('sms_confirm_tap')
    formik.submitForm()
    setBlocked(true)
  }

  const handleRetrySmsCode = useCallback(() => {
    track('sms_resend_code_tap')
    submitPhoneAction(phone, country)
    setCountdown(DELAY)
  }, [country, phone, submitPhoneAction, track])

  const suggestedPhone = useMemo(
    () => `<span style="font-weight: 600;white-space: nowrap;">${phone}</span>`,
    [phone],
  )

  const handleInputFocus = useCallback(() => {
    setBlocked(false)
  }, [])

  return (
    <form onSubmit={handleSubmit} data-test-id="sms-form" id="sms-form">
      <SmsCodeInfo dangerouslySetInnerHTML={{ __html: t('codeSentMsg', { phone: suggestedPhone }) }} />
      <SmsInputContainer>
        <Input
          {...formik.getFieldProps('code')}
          onChange={({ target: { value } }) => {
            formik.setFieldValue('code', value.replace(/\s*/g, ''))
          }}
          label={t('SmsCode')}
          autoComplete="one-time-code"
          invalid={progress === Progress.ERROR}
          onFocus={handleInputFocus}
          ref={codeInputRef}
        />
        {progress === Progress.ERROR && <SmsCodeError data-test-id="error">{t('wrongCode')}</SmsCodeError>}
        <RetryWrapper>
          {countdown ? (
            <RetryDelay dangerouslySetInnerHTML={{ __html: t('retry', { seconds: countdown }) }} />
          ) : (
            <RetryBtn onClick={handleRetrySmsCode} disabled={countdown > 0}>
              {t('resendCode')}
            </RetryBtn>
          )}
        </RetryWrapper>
      </SmsInputContainer>
      <SmsFormButtonsContainer>
        <SmsCancelButtonContainer>
          <BackButton
            onClick={() => {
              track('sms_phone_change_tap')
              backAction()
            }}
            disabled={progress === Progress.WORK}
            size="big"
          />
        </SmsCancelButtonContainer>
        <SmsSubmitButtonContainer>
          <Button
            id="sms-form-next-button"
            intent="primary"
            filled
            size="big"
            type="submit"
            disabled={blocked}
          >
            {t('common:confirm')}
          </Button>
        </SmsSubmitButtonContainer>
      </SmsFormButtonsContainer>
    </form>
  )
}

export default SmsCodeVerificationForm
