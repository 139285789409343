import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { ProfileBidOfferPage } from 'views/pages/Profile/ProfileBidOfferPage/ProfileBidOfferPage'
import { BidAdd } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAdd'
import { BidAddSuccess } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAddSuccess'
import { BidEdit } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidEdit'
import ProfileRoutes from 'views/pages/Profile/routes'

import ProfileBidItem from './ProfileBidItem'
import ProfileBidsList from './ProfileBidsList'

const ProfileBidsPage: React.FC = () => (
  <Switch>
    <Route path={ProfileRoutes.ProfileBidItem} exact>
      <ProfileBidItem />
    </Route>
    <Route path={ProfileRoutes.ProfileBidEdit}>
      <BidEdit />
    </Route>
    <Route path={ProfileRoutes.ProfileBidOffer}>
      <ProfileBidOfferPage />
    </Route>
    <Route path={ProfileRoutes.ProfileBids} exact>
      <ProfileBidsList />
    </Route>
    <Route path={ProfileRoutes.ProfileBidAdd} exact>
      <BidAdd />
    </Route>
    <Route path={ProfileRoutes.ProfileBidAddSuccess} exact>
      <BidAddSuccess />
    </Route>
    <Redirect to={ProfileRoutes.ProfileBids} />
  </Switch>
)

export default ProfileBidsPage
