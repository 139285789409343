import Cookies from 'js-cookie'

import { CookieKey } from 'service/token/cookieKey'
import { TokenService as ITokenService } from 'service/token/interface'

const cookieOpts = {
  path: '/',
  secure: false,
  sameSite: 'strict' as const,
  httpOnly: false,
  expires: 30,
}

class TokenService implements ITokenService {
  init = (cookies: Record<CookieKey, string | null>) => {
    Object.entries(cookies).forEach(([name, value]) => {
      if (value === null) {
        Cookies.remove(name)
      } else {
        Cookies.set(name, value, cookieOpts)
      }
    })
  }

  saveAccessToken = (accessToken: string): void => {
    Cookies.set(CookieKey.ACCESS_TOKEN, accessToken, cookieOpts)
  }

  getAccessToken = (): string | null => Cookies.get(CookieKey.ACCESS_TOKEN) || null

  saveRefreshToken = (refreshToken: string): void => {
    Cookies.set(CookieKey.REFRESH_TOKEN, refreshToken, cookieOpts)
  }

  getRefreshToken = (): string | null => Cookies.get(CookieKey.REFRESH_TOKEN) || null

  clearToken = (): void => Cookies.remove(CookieKey.ACCESS_TOKEN)
}

export default TokenService
