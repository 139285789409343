import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useLocation } from 'react-router-dom'

import { Button, IconSort, Pagination, useAction, useMatchMedia } from '@agro-club/agroclub-shared'
import { pick } from 'ramda'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid, BidListSortFields } from 'modules/domain/bid/types'
import { Progress } from 'modules/types'
import { queries } from 'theme/theme'
import BidItemRoutes from 'views/pages/BidItem/routes'
import { PriceData, PriceDataSkeleton } from 'views/pages/Bids/BidsMosaic/PriceData'
import { useIsPriceHidden } from 'views/pages/Bids/hooks'
import CardLink from 'views/ui/CardLink/CardLink'
import CardLinkSkeleton from 'views/ui/CardLinkSkeleton/CardLinkSkeleton'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

const Wrapper = styled.div`
  ${(props) => props.theme.media.desktop`
    display: none;
  `}
`
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 16px;
  align-items: center;
  padding: 0 8px;
`
const ListWrapper = styled.div`
  margin-top: 32px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 16px;
  padding: 0 8px;
`

const ItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const ItemSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const Skeleton: React.FC = () => (
  <CardLinkSkeleton>
    <ItemHeader>
      <SkeletonText $width={55} $height={24} />
    </ItemHeader>
    <ItemSubHeader>
      <SkeletonText $width={70} $height={20} />
    </ItemSubHeader>
    <PriceDataSkeleton />
  </CardLinkSkeleton>
)

const FilterWrapper = styled.button<{ active?: boolean; reversed?: boolean }>`
  border: 0;
  outline: none;
  font-family: Montserrat;
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: ${(props) => (props.active ? 600 : 500)};
  color: ${(props) =>
    props.active ? props.theme.color.onPrimaryDark : props.theme.color.onSurfaceMidEmphasys};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  user-select: none;

  ${(props) => props.theme.media.mobile`
    justify-content: space-between;
  `}

  svg {
    fill: ${(props) =>
      props.active ? props.theme.color.onPrimaryDark : props.theme.color.onSurfaceMidEmphasys};
    margin-left: 10px;
    width: 18px;
    height: 18px;
    transform: scale(1, ${(props) => (props.reversed ? -1 : 1)});
  }
`

const Filter: React.FC<{ onClick(): void; active?: boolean; reversed?: boolean; title: string }> = ({
  onClick,
  active,
  reversed,
  title,
}) => (
  <FilterWrapper onClick={onClick} active={active} reversed={reversed}>
    {title}
    <IconSort />
  </FilterWrapper>
)

const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
  text-align: center;
  > *:not(:first-child) {
    margin-top: 16px;
  }
`

const Error: React.FC<{ errorText: string; onFilterReset?: () => void }> = ({ errorText, onFilterReset }) => {
  const { t } = useTranslation('BidsMosaic')
  return (
    <ErrorWrapper>
      <div>{errorText}</div>
      {onFilterReset ? (
        <Button onClick={onFilterReset} intent="clear">
          {t('resetFilterButtonText')}
        </Button>
      ) : null}
    </ErrorWrapper>
  )
}

export const BidsMosaic: React.FC<{
  progress: Progress
  items: Bid[]
  onFilterReset?: () => void
}> = ({ items, onFilterReset, progress }) => {
  const { t } = useTranslation('BidsMosaic')
  const { track } = useAnalyticsSSR()
  const isMobile = useMatchMedia(queries.mobile)
  const sorting = useSelector(BidSelectors.sorting)
  const isFilterApplied = useSelector(BidSelectors.isFilterApplied)
  const updateSorting = useAction(BidActions.sortingUpdated)
  const requestList = useAction(BidActions.listRequested)
  const pageSize = useSelector(BidSelectors.pageSize)
  const pages = useSelector(BidSelectors.pages)
  const total = useSelector(BidSelectors.total)
  const currentPage = useSelector(BidSelectors.page)
  const location = useLocation()
  const formatNumber = useFormatNumber()
  const fetchItems = React.useCallback(
    (num) => {
      requestList({ page: num })
    },
    [requestList],
  )

  const renderItems = () =>
    items.map((item, position) => {
      const [firstParam] = item.parameters
      const paramValue = firstParam?.parameter_value
      const conditionLabel = firstParam?.condition_label
      const paramName = firstParam?.parameter?.name

      return (
        <CardLink
          to={{
            pathname: generatePath(BidItemRoutes.BidItem, { id: item.id }),
            state: { from: { ...pick(['pathname', 'search'], location) } },
          }}
          onClick={() => track('harvest_bids_bid_tap', { name: item.product.title, id: item.id, position })}
          key={item.id}
        >
          <ItemHeader>{item.product.title}</ItemHeader>
          {!!firstParam && (
            <ItemSubHeader>{`${paramName} ${conditionLabel} ${formatNumber(paramValue)}`}</ItemSubHeader>
          )}
          <PriceData item={item} />
        </CardLink>
      )
    })

  const sortHandler = (field: BidListSortFields) => () => {
    if (sorting.sort_field === field && sorting.sort_reversed) {
      return updateSorting({})
    }

    updateSorting({
      sort_field: field,
      sort_reversed: sorting.sort_field === field,
    })
  }

  const isActive = (field: BidListSortFields) => field === sorting.sort_field
  const isReversed = (field: BidListSortFields) => field === sorting.sort_field && sorting.sort_reversed
  // eslint-disable-next-line react/no-array-index-key
  const renderSkeletons = () => new Array(10).fill(0).map((_, i) => <Skeleton key={i} />)

  const renderList = () => {
    if (progress === Progress.ERROR) {
      return <Error onFilterReset={onFilterReset} errorText={t('dataFetchErrorText')} />
    }
    if (progress === Progress.WORK) {
      return <ListWrapper>{renderSkeletons()}</ListWrapper>
    }

    if (isFilterApplied && !items.length) {
      return <Error onFilterReset={onFilterReset} errorText={t('nothingFoundForFilterErrorText')} />
    }

    if (!items.length) {
      return <Error errorText={t('nothingFoundErrorText')} />
    }

    return <ListWrapper>{renderItems()}</ListWrapper>
  }

  const { isCptHidden, isExwHidden } = useIsPriceHidden()

  return (
    <Wrapper>
      <HeaderWrapper>
        {!isCptHidden && (
          <Filter
            onClick={sortHandler('price_cpt')}
            title={t('filterLabelPrice')}
            active={isActive('price_cpt')}
            reversed={isReversed('price_cpt')}
          />
        )}
        {!isExwHidden && (
          <Filter
            onClick={sortHandler('price_exw')}
            title={t('filterLabelPrice')}
            active={isActive('price_exw')}
            reversed={isReversed('price_exw')}
          />
        )}
        <Filter
          onClick={sortHandler('distance')}
          title={t('filterLabelDistance')}
          active={isActive('distance')}
          reversed={isReversed('distance')}
        />
        <Filter
          onClick={sortHandler('quantity')}
          title={t('filterLabelVolume')}
          active={isActive('quantity')}
          reversed={isReversed('quantity')}
        />
      </HeaderWrapper>
      {renderList()}
      {total > pageSize ? (
        <Pagination total={pages} current={currentPage} onClick={fetchItems} max={isMobile ? 5 : undefined} />
      ) : null}
    </Wrapper>
  )
}
