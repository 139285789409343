import styled, { css } from 'styled-components'

import { Article } from 'views/layouts/MainLayout/MainLayout'

export const CategoryGroupListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 16px;
  max-width: 100%;
  padding: 0 8px 24px;
  margin: 24px 0 0;
`

const layoutPadding = css`
  ${(props) => props.theme.media.mobile`
    padding: 0 12px;
  `}
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: row;
  margin-top: 56px;
  grid-gap: 56px;
  ${(props) => props.theme.media.mobile`
    margin-top: 36px;
    grid-gap: 36px;
  `}
`

export const HeaderGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -12px -12px;
  align-items: center;
  > * {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;
  }
  ${layoutPadding}
`

export const H2 = styled.h2`
  margin-bottom: 0;
  padding: 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;

  ${(props) => props.theme.media.mobile`
    line-height: 36px;
    font-size: 28px;
  `}
`

export const CategorySection = styled.section`
  min-width: 0;
`

export const PreFooter = styled(Article)`
  margin-top: 64px;
  padding: 80px 0;
  background: ${(props) => props.theme.color.onPrimaryLight};
  ${(props) => props.theme.media.mobile`
    margin-top: 24px;
    padding: 40px 12px;
  `}
`
