import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { Button } from '@agro-club/agroclub-shared'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthSelectors from 'modules/domain/auth/selectors'
import ProfileRoutes from 'views/pages/Profile/routes'
import { Routes } from 'views/pages/routes'

import HeaderEvents from './events'

export const SaleButton: React.FC = () => {
  const { t } = useTranslation('header')
  const { track } = useAnalyticsSSR()
  const history = useHistory()
  const location = useLocation()
  const isAuthorized = useSelector(AuthSelectors.isAuthenticated)

  return (
    <Button
      intent="primary"
      filled
      onClick={() => {
        track(HeaderEvents.UpperlineClick, { type: 'create_offer' })
        history.push(isAuthorized ? ProfileRoutes.ProfileBidAdd : Routes.SignIn, {
          from: location.pathname,
        })
      }}
    >
      {t('saleHarvest')}
    </Button>
  )
}
