import { useCallback, useRef, useState } from 'react'

import { Progress } from '@agro-club/agroclub-shared'
import axios, { CancelTokenSource } from 'axios'

import { getGeocoderUrl } from 'helpers/geolocation'
import { useNotificationProgress } from 'hooks/useNotificationProgress'

export type Coordinates = [number, number]

const useAddress = ({
  initialCoords,
  initialSearch,
  onChange,
  apiKey,
}: {
  initialCoords?: Coordinates | null
  initialSearch?: string
  onChange?: (v: { coords: Coordinates | null; address: string; geoObject: any }) => void
  apiKey: string
}) => {
  const [zoom, setZoom] = useState(initialCoords ? 14 : 9)
  const [coords, setCoords] = useState<Coordinates | null>(initialCoords || null)
  const [pinCoords, setPinCoords] = useState<Coordinates | null>(initialCoords || null)
  const [search, setSearch] = useState(initialSearch || '')
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  useNotificationProgress(progress)

  const prevCancelTokenRef = useRef<CancelTokenSource | null>(null)

  const onInputChange = useCallback(
    (search: string) => {
      setSearch(search)
      setProgress(Progress.WORK)

      prevCancelTokenRef.current?.cancel()
      const cancelToken = axios.CancelToken.source()
      prevCancelTokenRef.current = cancelToken

      if (search.length < 3) {
        return onChange?.({ coords: null, address: search, geoObject: null })
      }

      axios
        .get(getGeocoderUrl(apiKey, search), { cancelToken: cancelToken.token })
        .then((res) => {
          const geoObject = res.data?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject
          const coordsStr = geoObject?.Point?.pos

          if (coordsStr) {
            const split = coordsStr.split(' ')
            if (split.length) {
              const coords: Coordinates = [parseFloat(split[1]), parseFloat(split[0])]
              setCoords(coords)
              setPinCoords(coords)
              onChange?.({ coords, address: search, geoObject })
              setZoom(14)
            }
          }
          setProgress(Progress.SUCCESS)
        })
        .catch((err) => {
          console.error(err)
          setProgress(Progress.ERROR)
        })
        .finally(() => {
          prevCancelTokenRef.current = null
        })
    },
    [apiKey, onChange],
  )

  const onMapCoordsChange = useCallback(
    (coords: Coordinates) => {
      setPinCoords(coords)
      setProgress(Progress.WORK)

      prevCancelTokenRef.current?.cancel()
      const cancelToken = axios.CancelToken.source()
      prevCancelTokenRef.current = cancelToken

      axios
        .get(getGeocoderUrl(apiKey, [coords[1], coords[0]].join(',')), { cancelToken: cancelToken.token })
        .then((res) => {
          const geoObject = res.data?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject
          const addressText: string = geoObject?.metaDataProperty?.GeocoderMetaData?.text

          if (addressText) {
            setCoords(coords)
            setSearch(addressText)
            onChange?.({ coords, address: addressText, geoObject })
          }
          setProgress(Progress.SUCCESS)
        })
        .catch((err) => {
          console.error(err)
          setProgress(Progress.ERROR)
        })
        .finally(() => {
          prevCancelTokenRef.current = null
        })
    },
    [apiKey, onChange],
  )

  const onMapZoomChange = useCallback((zoom: number) => {
    setZoom(zoom)
  }, [])

  return {
    onInputChange,
    onMapCoordsChange,
    onMapZoomChange,
    search,
    coords,
    pinCoords,
    progress,
    zoom,
  }
}

export default useAddress
