import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import * as S from 'views/pages/Profile/ProfileBidsPage/BidAdd/styled'

export const BottomRemark: React.FC = () => {
  const profileType = useSelector(AuthSelectors.profileType)
  const { t } = useTranslation('bid')
  return (
    <S.BottomRemark>
      {t('form.bottomRemark', {
        profileType:
          profileType === ProfileType.seller ? t('form.bottomRemarkCustomer') : t('form.bottomRemarkSeller'),
      })}
    </S.BottomRemark>
  )
}
