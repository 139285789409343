export type AvailableCountries = 'RU' | 'BR'

export enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export enum AvailableLanguages {
  ru = 'ru',
  pt_BR = 'pt-BR',
  en = 'en',
}
