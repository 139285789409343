import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api'
import ProfileRoutes from 'views/pages/Profile/routes'

import DealActions from './duck'
import * as managers from './managers'
import DealSelectors from './selectors'
import { Deal } from './types'

export const fetchList = function* () {
  try {
    let currentPage = yield select(DealSelectors.page)
    const filter = yield select(DealSelectors.filter)
    const sorting = yield select(DealSelectors.sorting)
    const pageSize = yield select(DealSelectors.pageSize)

    let response: ListResponse<Deal> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, current, count } = response
    yield put(DealActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, ProfileRoutes.ProfileDeals, { page: currentPage })
  } catch (err) {
    yield put(DealActions.listRequestFailed())
  }
}

export const fetchListNext = function* () {
  try {
    const page = yield select(DealSelectors.page)
    const filter = yield select(DealSelectors.filter)
    const sorting = yield select(DealSelectors.sorting)
    const pageSize = yield select(DealSelectors.pageSize)
    const { data, total_count }: { data: Deal[]; total_count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(DealActions.listRequestNextSucceed(data, total_count))
  } catch (err) {
    yield put(DealActions.listRequestNextFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof DealActions.itemRequested>) {
  try {
    const item: Deal = yield call(apiCall, managers.getItem, id)
    yield put(DealActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(DealActions.itemRequestFailed(id))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof DealActions.addRequested>) {
  try {
    const item: Deal = yield call(apiCall, managers.addItem, dto)
    yield put(DealActions.addSucceed(item))
  } catch (err) {
    yield put(DealActions.addFailed())
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof DealActions.updateRequested>) {
  try {
    const item: Deal = yield call(apiCall, managers.updateItem, id, dto)
    yield put(DealActions.updateSucceed(item))
  } catch (err) {
    yield put(DealActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof DealActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(DealActions.removeSucceed(payload))
  } catch (err) {
    yield put(DealActions.removeFailed(payload))
  }
}

const routeWatcher = makeRouteWatcher(ProfileRoutes, function* ({ match, silent, params }) {
  if (silent) {
    return
  }
  switch (match) {
    case ProfileRoutes.ProfileDeals: {
      yield put(DealActions.listRequested({}))
      break
    }
    case ProfileRoutes.ProfileDealsItem: {
      yield put(DealActions.itemRequested(params.id))
      break
    }
  }
})

const DealSaga = function* () {
  yield all([
    takeLatest(DealActions.itemRequested.type, fetchItem),
    takeLatest(DealActions.listRequested.type, fetchList),
    takeLatest(DealActions.filterUpdated.type, fetchList),
    takeLatest(DealActions.sortingUpdated.type, fetchList),
    takeLatest(DealActions.filterHasBeenReset.type, fetchList),
    takeLatest(DealActions.sortingHasBeenReset.type, fetchList),

    takeLatest(DealActions.listRequestedNext.type, fetchListNext),

    takeLatest(DealActions.addRequested.type, addItem),
    takeLatest(DealActions.updateRequested.type, updateItem),
    takeLatest(DealActions.removeRequested.type, removeItem),

    fork(routeWatcher),
  ])
}

export default DealSaga
