import { createSelector } from 'reselect'

import { AppGlobalState } from 'modules/types'

const getError = (state: AppGlobalState): string => state.auth.error
const getResetPasswordError = (state: AppGlobalState): string => state.auth.resetPasswordError
const getResetEmail = (state: AppGlobalState): string => state.auth.email
const pathname = (state: AppGlobalState): string => state.router.location.pathname
const search = (state: AppGlobalState): string => state.router.location.search
const locationState = (state: AppGlobalState) => state.router.location.state as { from: string } | undefined
const profile = (state: AppGlobalState) => state.auth.profile
const userCompanyId = (state: AppGlobalState) => state.auth.profile?.profile?.company?.id
const smsVerificationProgress = (state: AppGlobalState) => state.auth.smsCodeVerificationProgress
const submitCredentialsProgress = (state: AppGlobalState) => state.auth.submitCredentialsProgress
const step = (state: AppGlobalState) => state.auth.step
const suggestedPhone = (state: AppGlobalState) => state.auth.suggestedPhone || state.auth.phone
const email = (state: AppGlobalState) => state.auth.email
const phone = (state: AppGlobalState) => state.auth.phone
const initProgress = (state: AppGlobalState) => state.auth.initProgress
const isAuthenticated = (state: AppGlobalState) => state.auth.isAuthenticated
const isProfileFulfilled = (state: AppGlobalState) =>
  isAuthenticated(state) && state.auth.profile?.signup_step === 'complete'
const authProgress = (state: AppGlobalState) => state.auth.authProgress
const selectedCountry = (state: AppGlobalState) => state.auth.selectedCountry
const registerProgress = (state: AppGlobalState) => state.auth.registerProgress
const getAuthError = (state: AppGlobalState) => state.auth.authError
const profileInfoUpdateProgress = (state: AppGlobalState) => state.auth.profileInfoUpdateProgress
const profileAddressUpdateProgress = (state: AppGlobalState) => state.auth.profileAddressUpdateProgress
const profileCompanyUpdateProgress = (state: AppGlobalState) => state.auth.profileCompanyUpdateProgress
const currentProfileUpdateProgress = (state: AppGlobalState) => state.auth.currentProfileUpdateProgress
const signUpStep = createSelector(profile, (profile) => profile?.signup_step || 'profile')
const profileType = createSelector(profile, (profile) => profile?.profile?.profile_type || null)

const profileCompanyUpdateError = (state: AppGlobalState) => state.auth.profileCompanyUpdateError
const profileCompanyUpdateErrorDetail = (state: AppGlobalState) => state.auth.profileCompanyUpdateErrorDetail
const profileCompanyUpdateErrorFields = (state: AppGlobalState) => state.auth.profileCompanyUpdateErrorFields

const AuthSelectors = {
  getError,
  getAuthError,
  getResetPasswordError,
  getResetEmail,
  pathname,
  search,
  locationState,
  profile,
  userCompanyId,
  smsVerificationProgress,
  submitCredentialsProgress,
  step,
  suggestedPhone,
  email,
  phone,
  initProgress,
  isAuthenticated,
  isProfileFulfilled,
  authProgress,
  selectedCountry,
  registerProgress,
  profileInfoUpdateProgress,
  profileAddressUpdateProgress,
  profileCompanyUpdateProgress,
  profileCompanyUpdateError,
  profileCompanyUpdateErrorDetail,
  profileCompanyUpdateErrorFields,
  currentProfileUpdateProgress,
  signUpStep,
  profileType,
}

export default AuthSelectors
