import { generatePath, matchPath } from 'react-router'
import { delay, put, select } from 'redux-saga/effects'

import { getLocation, replace } from 'connected-react-router'

import { stringifyUrl } from 'helpers/stringifyUrl'

type Param = string | number | undefined

export function* updateLocationQuery(nextLocation: string, query?: Record<string, Param | Param[]>) {
  const currentLocation = yield select(getLocation)
  const match = matchPath(currentLocation.pathname, { path: nextLocation, exact: true })
  if (match) {
    const result = stringifyUrl({
      url: generatePath(nextLocation, match.params),
      query,
    })
    const oldResult = stringifyUrl({ url: currentLocation.pathname, query: currentLocation.query })
    // prevent unnecessary push
    if (oldResult !== result) {
      yield put(replace(result, { silent: true }))
      // eslint-disable-next-line no-restricted-globals
      if (typeof history !== 'undefined') {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({ ...history.state?.state, silent: false }, '')
      }
    }
  }
}

export function* exponentialDelay(attemt = 0) {
  const newAttempt = attemt < 0 ? 0 : attemt
  const delayMs = newAttempt ** 2 * 1000
  yield delay(delayMs)
}
