import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProfileAddressAdd from 'views/pages/Profile/ProfileAddressPage/ProfileAddressAdd'
import ProfileRoutes from 'views/pages/Profile/routes'

import ProfileAddressItem from './ProfileAddressItem'
import ProfileAddressList from './ProfileAddressList'

const ProfileAddressPage: React.FC = () => (
  <Switch>
    <Route path={ProfileRoutes.ProfileAddressItem}>
      <ProfileAddressItem />
    </Route>
    <Route path={ProfileRoutes.ProfileAddressesAdd}>
      <ProfileAddressAdd />
    </Route>
    <Route path={ProfileRoutes.ProfileAddresses}>
      <ProfileAddressList />
    </Route>
  </Switch>
)

export default ProfileAddressPage
