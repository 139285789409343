import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  IconFilter,
  Input,
  PrefixInput,
  SimpleSelectProps,
  useBoolean,
  useScrollFlags,
} from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

import { Address } from 'modules/domain/types'
import Drawer from 'views/ui/Drawer/Drawer'

import { CurrentUserAddressSelect } from '../CurrentUserAddressSelect/CurrentUserAddressSelect'

// const stickyFilterHeight = '56px'

const FilterHeader = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.media.mobile`
    display: ${(props: StyledProps<{ collapsed: boolean }>) => (props.collapsed ? 'none' : 'block')};
  `}
`
const FilterFooter = styled.div<{ collapsed?: boolean }>`
  margin-top: 24px;
  ${(props) => props.theme.media.mobile`
    display: ${(props: StyledProps<{ collapsed: boolean }>) => (props.collapsed ? 'none' : 'block')};
  `}
`

const FilterContainer = styled.div`
  background: ${(props) => props.theme.color.onPrimaryLight};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  position: relative;
  padding: 8px;

  ${(props) => props.theme.media.desktop`
    align-self: start;
  `}

  ${(props) => props.theme.media.mobile`
    border-radius: 8px 8px 0 0;
    box-shadow: 0px -8px 16px rgba(0, 0, 0, 0.08);
    width: 100%;
    min-height: 56px;
    max-height: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;

    ${FilterFooter} {
      &[data-collapsed="false"] {
        display: flex;
      }
    }

    &[data-sticky="true"][data-collapsed="true"] {
      position: fixed;
    }
  `}
`

const FilterBody = styled.div`
  position: relative;
  ${(props) => props.theme.media.mobile`
    display: block;
    overflow-y: auto;
    &::before{
      display: block;
      content: '';
      position: sticky;
      top: 0;
      left: 0;
      transition: opacity 0.1s ease-in;
      opacity: 0;
      width: 100%;
      box-shadow: 0 7px 19px 4px ${(props) => props.theme.color.secondary200};
      z-index: 1;
    }
    &::after{
      display: block;
      content: '';
      position: sticky;
      bottom: 0;
      left: 0;
      transition: opacity 0.1s ease-in;
      opacity: 0;
      width: 100%;
      box-shadow: 0 -7px 19px 4px ${(props) => props.theme.color.secondary200};
      z-index: 1;
    }

    &[data-top-shadow='true'] {
      &::before {
        opacity: 1;
      }
    }
    &[data-bottom-shadow='true'] {
      &::after{
        opacity: 1;
      }
    }

    &[data-collapsed='true'] {
      display: none;
    }
  `}
`

export const FiltersList = styled.div`
  margin-top: 24px;
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 16px;
`
const FilterItemWrapper = styled.div``
const FilterLabel = styled.div<{ active?: boolean }>`
  font-weight: ${(props) => (props.active ? '600' : '500')};
  font-size: 14px;
  line-height: 20px;
`
const FilterInner = styled.div`
  margin-top: 8px;
`
export const FilterStringInput: React.FC<{
  id: string
  label: string
  value: string
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
}> = ({ id, label, value, placeholder, onChange }) => (
  <FilterItemWrapper>
    <FilterLabel>{label}</FilterLabel>
    <FilterInner>
      <Input id={id} placeholder={placeholder} value={value} onChange={onChange} />
    </FilterInner>
  </FilterItemWrapper>
)
export const FilterNumberInput: React.FC<{
  id: string
  label: string
  value: number
  placeholder?: string
  onChange: (v: number) => void
}> = ({ id, label, value, placeholder, onChange }) => (
  <FilterItemWrapper>
    <FilterLabel>{label}</FilterLabel>
    <FilterInner>
      <Input
        id={id}
        placeholder={placeholder}
        type="number"
        value={value}
        onChange={(e) => onChange(parseInt(e.target.value, 10))}
      />
    </FilterInner>
  </FilterItemWrapper>
)
const FilterRangeInputsWrapper = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
`
export const FilterRangeInput: React.FC<{
  id: string
  label: string
  active?: boolean
  valueLeft?: number
  valueRight?: number
  min?: number
  max?: number
  minPlaceholder?: string
  maxPlaceholder?: string
  minLabel?: string
  maxLabel?: string
  onLeftChange: (v: number) => void
  onRightChange: (v: number) => void
}> = ({
  id,
  label,
  active,
  valueLeft,
  valueRight,
  min,
  max,
  minPlaceholder,
  maxPlaceholder,
  minLabel,
  maxLabel,
  onLeftChange,
  onRightChange,
}) => {
  const { t } = useTranslation('FilterCard')
  const handleLeftChange = useCallback(
    (_: any, v: string) => onLeftChange(parseInt(v, 10) || 0),
    [onLeftChange],
  )
  const handleRightChange = useCallback(
    (_: any, v: string) => onRightChange(parseInt(v, 10) || 0),
    [onRightChange],
  )
  return (
    <FilterItemWrapper>
      <FilterLabel active={active}>{label}</FilterLabel>
      <FilterInner>
        <FilterRangeInputsWrapper>
          <PrefixInput
            prefix={t('fromLabel')}
            value={valueLeft || ''}
            type="number"
            min={min}
            max={max}
            placeholder={minPlaceholder}
            label={minLabel}
            id={`${id}-left`}
            onChange={handleLeftChange}
          />
          <PrefixInput
            prefix={t('toLabel')}
            value={valueRight || ''}
            type="number"
            min={min}
            max={max}
            placeholder={maxPlaceholder}
            label={maxLabel}
            id={`${id}-right`}
            onChange={handleRightChange}
          />
        </FilterRangeInputsWrapper>
      </FilterInner>
    </FilterItemWrapper>
  )
}

export const FilterAddressSelect: React.FC<{
  label: string
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  errorText?: string
  placeholder?: string
  noOptionsMessage?: SimpleSelectProps['noOptionsMessage']
  required?: boolean
  onChange: (address: Address) => void
}> = ({ label, ...props }) => (
  <FilterItemWrapper>
    <FilterLabel>{label}</FilterLabel>
    <FilterInner>
      <CurrentUserAddressSelect {...props} />
    </FilterInner>
  </FilterItemWrapper>
)

const FilterOpenButtonWrapper = styled.div`
  display: none;
  margin-bottom: 8px;
  svg {
    fill: ${(props) => props.theme.color.onPrimaryLight};
    margin-right: 8px;
  }
  ${(props) => props.theme.media.mobile`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `}
`

const FilterOpenButton: React.FC<{ onClick(state: boolean): void; collapsed: boolean }> = ({
  onClick,
  collapsed,
}) => {
  const { t } = useTranslation('FilterCard')
  if (!collapsed) {
    return null
  }
  return (
    <FilterOpenButtonWrapper>
      <Button intent="primary" filled onClick={() => onClick(!collapsed)}>
        <IconFilter />
        {collapsed ? t('filterExpandButtonText') : t('filterCollapseButtonText')}
      </Button>
    </FilterOpenButtonWrapper>
  )
}

const FilterCard: React.FC<{
  sticky?: boolean
  renderFooter?: ({ collapse }: { collapse: () => void }) => React.ReactNode
  renderHeader?: ({ collapse }: { collapse: () => void }) => React.ReactNode
}> = ({ children, sticky = false, renderHeader, renderFooter }) => {
  const [collapsed, collapse] = useBoolean(true)
  const { innerRef, wrapperRef, reachedBottom, reachedTop } = useScrollFlags()
  const bothReached = reachedBottom && reachedTop
  const topShadow = !reachedTop && !bothReached
  const bottomShadow = !reachedBottom && !bothReached

  return (
    <Drawer open={!collapsed} onClickOutside={collapse} initialHeight={56}>
      <FilterContainer data-sticky={sticky} data-collapsed={collapsed}>
        <FilterOpenButton onClick={() => collapse(!collapsed)} collapsed={collapsed} />
        {renderHeader ? (
          <FilterHeader collapsed={collapsed}>{renderHeader({ collapse })}</FilterHeader>
        ) : null}
        <FilterBody
          ref={wrapperRef}
          data-collapsed={collapsed}
          data-top-shadow={topShadow}
          data-bottom-shadow={bottomShadow}
        >
          <div ref={innerRef}>{children}</div>
        </FilterBody>
        {renderFooter ? (
          <FilterFooter collapsed={collapsed}>{renderFooter({ collapse })}</FilterFooter>
        ) : null}
      </FilterContainer>
    </Drawer>
  )
}

export default FilterCard
