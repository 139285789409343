import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { PERSONAL_DATA_LINK, USER_AGREEMENT_LINK } from 'modules/constants'

import { FooterEvents } from './events'
import * as Styled from './styles'

const Footer: React.FC = () => {
  const { t } = useTranslation(['footer', 'common'])
  const { track } = useAnalyticsSSR()

  return (
    <Styled.Wrapper>
      <Styled.Area area="copyright">
        <Styled.Text>{t('copyright', { year: new Date().getFullYear() })}</Styled.Text>
      </Styled.Area>
      <Styled.Area area="policies">
        <Styled.Link>
          <a
            onClick={() => track(FooterEvents.BasementClick, { type: 'user_agreement' })}
            id="phone_agreement_tap"
            href={USER_AGREEMENT_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:userAgreement')}
          </a>
        </Styled.Link>
        <Styled.Link>
          <a
            onClick={() => track(FooterEvents.BasementClick, { type: 'privacy_policy' })}
            id="phone_personal_data_tap"
            href={PERSONAL_DATA_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:personalData')}
          </a>
        </Styled.Link>
      </Styled.Area>
      <Styled.Area area="contacts">
        <Styled.Text>
          <a
            onClick={() => track(FooterEvents.BasementClick, { type: 'our_phone' })}
            href={`tel:${t('common:supportTel')}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:supportTel')}
          </a>
        </Styled.Text>
        <Styled.Link>
          <a
            onClick={() => track(FooterEvents.BasementClick, { type: 'our_email' })}
            href={`mailto:${t('common:supportEmail')}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common:supportEmail')}
          </a>
        </Styled.Link>
      </Styled.Area>
    </Styled.Wrapper>
  )
}

export default Footer
