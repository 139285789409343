import { JSONSchemaType } from 'ajv'

import { newQueryValidator } from 'helpers/query'
import { BidListRequestFilter, BidListRequestSorting } from 'modules/domain/bid/types'

export type QuerySchema = { page?: number } & BidListRequestFilter
export const filterSchema: JSONSchemaType<QuerySchema> = {
  type: 'object',
  additionalProperties: false,
  properties: {
    product: { type: 'string', nullable: true },
    page: { type: 'number', nullable: true },
    search: { type: 'string', nullable: true },
    bid_type: { type: 'string', nullable: true },
    distance_min: { type: 'number', nullable: true },
    distance_max: { type: 'number', nullable: true },
    price_exw_min: { type: 'number', nullable: true },
    price_exw_max: { type: 'number', nullable: true },
    price_cpt_min: { type: 'number', nullable: true },
    price_cpt_max: { type: 'number', nullable: true },
    quantity_min: { type: 'number', nullable: true },
    quantity_max: { type: 'number', nullable: true },
  },
}

export const sortSchema: JSONSchemaType<BidListRequestSorting> = {
  type: 'object',
  additionalProperties: false,
  properties: {
    sort_field: { type: 'string', nullable: true },
    sort_reversed: { type: 'boolean', nullable: true, default: false },
  },
}

export const queryValidator = newQueryValidator(filterSchema)
export const sortValidator = newQueryValidator(sortSchema)
