import React from 'react'

import Footer from 'views/components/Footer/Footer'
import Header from 'views/components/Header/Header'
import * as Layout from 'views/layouts/MainLayout/MainLayout'

const PageWrapper: React.FC = ({ children }) => (
  <Layout.Root>
    <Layout.Header>
      <Header />
    </Layout.Header>
    <Layout.Main>{children}</Layout.Main>
    <Layout.Footer>
      <Footer />
    </Layout.Footer>
  </Layout.Root>
)

export default PageWrapper
