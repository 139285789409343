import { FormikContextType } from 'formik'
import { toString } from 'ramda'

export const useServerValidation = (
  errorFields: Record<string, string[]> | undefined,
  fieldName: string,
  formikInstance: FormikContextType<unknown>,
): [boolean, string | undefined] => {
  const serverError = errorFields?.[fieldName]?.join(',')

  const isInvalid = !!(
    serverError ||
    (formikInstance.touched[fieldName] && !!formikInstance.errors[fieldName])
  )
  const formikErrors = formikInstance.errors[fieldName]
  const formikErrorsFormatted =
    formikErrors && Array.isArray(formikErrors) ? formikErrors.join(',') : toString(formikErrors)

  const errorText = serverError || formikErrorsFormatted

  return [isInvalid, errorText]
}
