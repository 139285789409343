import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import { Progress, SimpleSelect, SimpleSelectOption, SimpleSelectProps } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useCompaniesDadata } from 'modules/domain/collection/hooks'
import { CompanyDadata } from 'modules/domain/collection/types'

const OptionName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const OptionRow = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const OptionWrapper = styled.div<{ focused: boolean; selected: boolean }>`
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.color.outlineNeutral};
  }

  background-color: ${(props) =>
    props.focused
      ? props.theme.color.primary100
      : props.selected
      ? props.theme.color.primary50
      : props.theme.color.onPrimaryLight};
`

type IsMulti = false

const Option: React.FC<
  OptionProps<SimpleSelectOption, IsMulti> & {
    data: {
      value: string
      inn: string
      name: string
      addressFull: string
      addressShort: string
    }
  }
> = (props) => {
  const { innerProps, isFocused, isSelected } = props
  return (
    <OptionWrapper onClick={innerProps.onClick} focused={isFocused} selected={isSelected}>
      <OptionName>{props.data.name}</OptionName>
      <OptionRow>{`${props.data.inn}`}</OptionRow>
      <OptionRow>{`${props.data.addressShort}`}</OptionRow>
    </OptionWrapper>
  )
}

export type CompanySelectProps = {
  autoFocus?: boolean
  onCompanyChange: (company: CompanyDadata) => void
  invalid?: SimpleSelectProps['invalid']
}

export const CompanySelectDadata: React.FC<CompanySelectProps> = ({
  onCompanyChange,
  invalid,
  autoFocus = false,
}) => {
  const { t } = useTranslation(['CompanySelectDadata'])
  const [query, setQuery] = useState<string>('')
  const [progress, companies] = useCompaniesDadata(query)
  const [value, setValue] = useState<string | number>()
  const options = useMemo<SimpleSelectProps['options']>(
    () =>
      query
        ? companies.map((c) => ({
            id: c.inn,
            inn: c.inn,
            title: c.name_short,
            name: c.name_short,
            addressShort: c.address_full,
            addressFull: c.address_full,
          }))
        : [],
    [companies, query],
  )

  const onChange = useCallback(
    (inn: string | number | undefined) => {
      setValue(inn)
      const company = companies.find((c) => c.inn === inn)
      company && onCompanyChange(company)
    },
    [setValue, onCompanyChange, companies],
  )

  return (
    <SelectWrapper>
      <SimpleSelect
        autoFocus={autoFocus}
        id="company-select"
        name="company-select"
        invalid={invalid}
        errorText={t('noCompanyError')}
        progress={progress}
        onChange={onChange}
        label={t('label')}
        placeholder={t('placeholder')}
        loadingMessage={t('loadingText')}
        noOptionsMessage={() =>
          query && progress === Progress.SUCCESS ? t('noOptionsMessage') : t('startSearchMessage')
        }
        options={options}
        value={value}
        onSearchChange={(value: string) => setQuery(value)}
        filterOption={() => true}
        isSearchable
        isClearable
        components={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Option,
        }}
      />
    </SelectWrapper>
  )
}
