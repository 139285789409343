import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import { Product, ProductDTO, ProductState } from './types'

const initialState: ProductState = {
  items: {},
  meta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,
}

class ProductReducer extends ImmerReducer<ProductState> {
  listRequested() {
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestSucceed(list: Product[]) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map((item) => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
  }

  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Product) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested(dto: ProductDTO) {
    this.draftState.addProgress = Progress.WORK
  }

  addSucceed(item: Product) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<ProductDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
  }

  updateSucceed(item: Product) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }

  updateFailed(id: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex((item) => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    this.draftState.meta[id].removeProgress = Progress.ERROR
  }
}

export const ProductActions = createActionCreators(ProductReducer)
export default ProductActions
export const reducer = createReducerFunction(ProductReducer, initialState)
