import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, useAction, useDidMount, useScrollTrigger } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useBidTypeByProfile from 'hooks/useBidTypeByProfile'
import useComponentWillMount from 'hooks/useComponentWillMount'
import useHelmet from 'hooks/useHelmet'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import BidActions from 'modules/domain/bid/duck'
import { useBidsListManaged } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import { BidListRequestFilter, BidType } from 'modules/domain/bid/types'
import { AnalyticsEvent } from 'modules/types'
import FilterCard, {
  FilterAddressSelect,
  FilterRangeInput,
  FiltersList,
} from 'views/components/FilterCard/FilterCard'
import { BidsMosaic } from 'views/pages/Bids/BidsMosaic/BidsMosaic'
import { BidsTable } from 'views/pages/Bids/BidsTable'
import {
  Article,
  FilterFooterWrapper,
  FilterWrapper,
  H1,
  TableWrapper,
  Wrapper,
} from 'views/pages/Bids/styled'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'
import FlatSegment from 'views/ui/FlatSegment/FlatSegment'

let isDefaultSortingApplied = false

const BidsPage: React.FC = () => {
  const { t } = useTranslation('BidsPage')
  const { track } = useAnalyticsSSR()
  const isAuthorized = useSelector(AuthSelectors.isAuthenticated)
  const updateSorting = useAction(BidActions.sortingUpdated)
  const updateFilter = useAction(BidActions.filterUpdated)
  const filterValue = useSelector(BidSelectors.filter)
  const sorting = useSelector(BidSelectors.sorting)
  const profileType = useSelector(AuthSelectors.profileType)
  const defaultBidType = useBidTypeByProfile()
  const addressLabel =
    profileType === ProfileType.seller || profileType === ProfileType.customer
      ? t(`addressLabel.${profileType}`)
      : t('common:addressForm.address')

  useComponentWillMount(() => {
    // set default filter
    if (defaultBidType && filterValue.bid_type !== defaultBidType) {
      updateFilter({ ...filterValue, bid_type: defaultBidType })
    }
    // set default sorting
    if (!sorting.sort_field && defaultBidType && !isDefaultSortingApplied) {
      isDefaultSortingApplied = true
      updateSorting({
        sort_field: defaultBidType === BidType.purchase ? 'price_exw' : 'price_cpt',
        sort_reversed: false,
      })
    }
  })
  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'harvest_bids_screen', product_name: filterValue.product })
  })

  const [progress, items = []] = useBidsListManaged()
  const resetFilter = useAction(BidActions.filterHasBeenReset)
  const [sticky, setSticky] = useState(false)
  const enterAnchorHandler = useCallback(() => {
    setSticky(false)
  }, [])

  const leaveAnchorHandler = useCallback(() => {
    setSticky(true)
  }, [])

  const { anchor } = useScrollTrigger(enterAnchorHandler, leaveAnchorHandler)

  const formik = useFormik<BidListRequestFilter>({
    initialValues: filterValue,
    enableReinitialize: true,
    onSubmit: () => {
      updateFilter(formik.values)
    },
  })

  const handleResetFilter = () => {
    track('harvest_bids_filter_clear')
    formik.resetForm()
    if (defaultBidType) {
      updateFilter({ bid_type: defaultBidType, product: filterValue.product })
    } else {
      resetFilter()
    }
  }

  const filterItems = Object.keys(BidType)
    .filter((type) => {
      if (!defaultBidType) {
        return true
      }
      return type === defaultBidType
    })
    .map((type) => ({
      id: type,
      title: t(`bidType_${type}`),
    }))

  const trackChangedFilterFields = () => {
    const newValues = {}
    for (const key in formik.values) {
      if (
        Object.prototype.hasOwnProperty.call(formik.values, key) &&
        formik.values[key] !== filterValue[key]
      ) {
        newValues[key] = formik.values[key]
      }
    }
    track('harvest_bids_filter_apply', newValues)
  }

  const product = items[0]?.product

  useHelmet({ title: product?.title })

  return (
    <Article>
      <Breadcrumbs
        items={[
          {
            title: product?.title || '',
          },
        ]}
      />
      <Wrapper>
        <H1>{product?.title}</H1>
        <FilterWrapper>
          <FilterCard
            sticky={sticky}
            renderFooter={({ collapse }) => (
              <FilterFooterWrapper>
                <Button
                  intent="primary"
                  filled
                  disabled={!formik.dirty}
                  onClick={() => {
                    trackChangedFilterFields()
                    formik.submitForm()
                    collapse()
                  }}
                >
                  {t('showButtonText')}
                </Button>
                <Button
                  intent="cancel"
                  style={{ border: 0 }}
                  onClick={() => {
                    handleResetFilter()
                    collapse()
                  }}
                >
                  {t('clearFiltersButtonText')}
                </Button>
              </FilterFooterWrapper>
            )}
            renderHeader={() => (
              <FlatSegment
                flat
                responsive
                id="bid-type-select"
                value={formik.values.bid_type}
                items={filterItems}
                onChange={(value) => formik.setFieldValue('bid_type', value)}
              />
            )}
          >
            <FiltersList>
              {isAuthorized && (
                <FilterAddressSelect
                  onChange={(v) => formik.setFieldValue('addressId', v.id)}
                  label={addressLabel}
                />
              )}
              {defaultBidType === BidType.purchase && (
                <FilterRangeInput
                  id="range-filter"
                  label={t('exwPriceLabel')}
                  valueLeft={formik.values.price_exw_min}
                  valueRight={formik.values.price_exw_max || 0}
                  min={0}
                  max={1000000}
                  onLeftChange={(v) => formik.setFieldValue('price_exw_min', v)}
                  onRightChange={(v) => formik.setFieldValue('price_exw_max', v)}
                />
              )}
              {defaultBidType === BidType.sale && (
                <FilterRangeInput
                  id="range-filter"
                  label={t('cptPriceLabel')}
                  valueLeft={formik.values.price_cpt_min}
                  valueRight={formik.values.price_cpt_max}
                  min={0}
                  max={1000000}
                  onLeftChange={(v) => formik.setFieldValue('price_cpt_min', v)}
                  onRightChange={(v) => formik.setFieldValue('price_cpt_max', v)}
                />
              )}
              <FilterRangeInput
                id="range-filter"
                label={t('volumeLabel')}
                valueLeft={formik.values.quantity_min}
                valueRight={formik.values.quantity_max}
                min={0}
                max={1000000}
                onLeftChange={(v) => formik.setFieldValue('quantity_min', v)}
                onRightChange={(v) => formik.setFieldValue('quantity_max', v)}
              />
              {isAuthorized && (
                <FilterRangeInput
                  id="range-filter"
                  label={t('distanceLabel')}
                  valueLeft={formik.values.distance_min}
                  valueRight={formik.values.distance_max}
                  min={0}
                  max={1000000}
                  onLeftChange={(v) => formik.setFieldValue('distance_min', v)}
                  onRightChange={(v) => formik.setFieldValue('distance_max', v)}
                />
              )}
            </FiltersList>
          </FilterCard>
        </FilterWrapper>
        <TableWrapper>
          <BidsMosaic progress={progress} items={items} onFilterReset={handleResetFilter} />
          <BidsTable progress={progress} items={items} onFilterReset={handleResetFilter} />
        </TableWrapper>
      </Wrapper>
      {anchor}
    </Article>
  )
}

export default BidsPage
