import styled, { keyframes } from 'styled-components'

const skeletonGradient = keyframes`
  0% {
    background-position-x: 100%;
  }
  50% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -100%;
  }
`

const SkeletonText = styled.span<{ $width: number; $height: number; $marginTop?: number }>`
  height: ${(props) => `${props.$height - 4}px`};
  width: 100%;
  border-radius: 4px;
  position: relative;
  margin-bottom: 2px;
  margin-top: ${(props) => props.$marginTop || 2}px;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 48%, #fff 50%, rgba(255, 255, 255, 0) 52%);
    background-size: 200% 200%;
    animation: ${skeletonGradient} 1.1s cubic-bezier(0.5, 0.02, 0.46, 0.92) infinite;
  }
  &::before {
    width: ${(props) => props.$width}%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #efeded;
    display: block;
    border-radius: 4px;
  }
`

export default SkeletonText
