import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState, Progress } from '../../types'
import { Product } from './types'

const dict = (state: AppGlobalState) => state.product.items
const ids = (state: AppGlobalState) => state.product.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Product | undefined => state.product.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata =>
  state.product.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.product.itemFetchProgress
const itemUpdateProgress = (state: AppGlobalState, id: string) =>
  state.product.meta[id]?.updateProgress || Progress.IDLE
const listFetchProgress = (state: AppGlobalState) => state.product.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.product.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.product.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.product.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Product[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const updateProgress = (state: AppGlobalState) => state.product.updateProgress
const removeProgress = (state: AppGlobalState) => state.product.removeProgress
const addProgress = (state: AppGlobalState) => state.product.addProgress

const ProductSelectors = {
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  itemUpdateProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  listFetchNextProgress,
  listFetchNextError,
}

export default ProductSelectors
