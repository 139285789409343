import { Severity } from '@sentry/browser'
import { AxiosError } from 'axios'

import env from 'env'

type SentryInstance = typeof import('@sentry/browser')

export const captureAxiosError = (sentry: SentryInstance | null | undefined, error: AxiosError) => {
  if (!sentry) {
    return
  }
  const isBrowser = env.BROWSER

  const resolveErrorLevel = (status: number): Severity => {
    switch (true) {
      case status < 500:
        return Severity.Warning
      default:
        return Severity.Error
    }
  }

  if (isBrowser) {
    sentry.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: error.config.url,
        method: error.config.method,
        type: 'error',
        error: {
          message: error.message,
          name: error.name,
        },
      },
    })
  }

  sentry.withScope((scope) => {
    const httpStatus = error.response?.status || 0
    const stringHttpStatus = String(httpStatus || 'unknown')
    const apiHandle = error.config.url as string
    const level = resolveErrorLevel(httpStatus)
    scope.setTag('kind', 'apiRequest')
    scope.setTag('apiHandle', apiHandle)
    scope.setTag('httpStatus', stringHttpStatus)
    scope.setTag('ssr', String(!isBrowser))
    scope.setLevel(level)
    scope.setExtras({
      httpStatus: stringHttpStatus,
      apiHandle,
      method: error.config.method,
      query: error.config.params,
      data: error.config.data,
      response: JSON.stringify(error?.response?.data),
    })

    // TODO
    // eslint-disable-next-line no-param-reassign
    error.name = isBrowser ? 'Client API request error' : 'SSR API Request error'
    // eslint-disable-next-line no-param-reassign
    error.message = `${error.config.method} ${apiHandle} ${error.message}`
    sentry.captureException(error, {
      fingerprint: [apiHandle],
    })
  })
}
