import env from 'env'
import { getGeocoderUrl } from 'helpers/geolocation'
import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { ListResponse } from 'types/api'

import {
  UserAddress,
  UserAddressDTO,
  UserAddressListRequestFilter,
  UserAddressListRequestSorting,
} from './types'

export const getList =
  (apiClient: ApiService) =>
  async (
    filter: UserAddressListRequestFilter,
    sorting: UserAddressListRequestSorting,
    page: number,
    pageSize: number,
  ) =>
    apiClient.get<ListResponse<UserAddress>>(endpoints.userAddress(), {
      ...filter,
      ...sorting,
      page,
      page_size: pageSize,
    })

export const getItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.get<UserAddress>(endpoints.userAddress(id))

export const addItem = (apiClient: ApiService) => async (dto: UserAddressDTO) =>
  apiClient.post<UserAddress>(endpoints.userAddress(), dto)

export const updateItem = (apiClient: ApiService) => async (id: string, dto: Partial<UserAddressDTO>) =>
  apiClient.put<UserAddress>(endpoints.userAddress(id), dto)

export const removeItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.delete<UserAddress>(endpoints.userAddress(id))

export const getRegionByCoords = (apiClient: ApiService) => async (coords: { lat: number; lng: number }) => {
  const res: any = await apiClient.get(getGeocoderUrl(env.REACT_APP_YA_MAPS_API_KEY, coords))

  const region =
    res?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject?.metaDataProperty?.GeocoderMetaData
      ?.AddressDetails?.Country?.AdministrativeArea?.AdministrativeAreaName
  return region || null
}
