import { useSelector } from 'react-redux'

import { ResourceHook } from 'modules/types'

import UserBidSelectors from './selectors'
import { UserBid } from './types'

export const useUserBidListManaged: ResourceHook<UserBid[]> = () => {
  const progress = useSelector(UserBidSelectors.listFetchProgress)
  const list = useSelector(UserBidSelectors.list)
  return [progress, list]
}

export const useUserBidItemManaged: ResourceHook<UserBid> = (id: string) => {
  const meta = useSelector((state) => UserBidSelectors.meta(state, id))
  const item = useSelector((state) => UserBidSelectors.item(state, id))
  return [meta.fetchProgress, item]
}
