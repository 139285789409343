import React from 'react'
import { useParams } from 'react-router-dom'

import { SectionBody, SectionContainer, SectionTitle, useHistoryPush } from '@agro-club/agroclub-shared'

import { useDealItemManaged } from 'modules/domain/deal/hooks'
import ProfileRoutes from 'views/pages/Profile/routes'

import * as S from '../styled'

const ProfileDealsItem: React.FC = () => {
  const params = useParams<{ id: string }>()
  const [progress, item] = useDealItemManaged(params.id)
  const push = useHistoryPush()
  const goBack = () => push({ route: ProfileRoutes.ProfileDeals })

  return (
    <SectionContainer noDivider>
      <S.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>{item?.product.title}</SectionTitle>
      </S.HiddenOnMediaBlock>
      <SectionBody>
        <div>Deal item page stub</div>
        <div>Progress: {progress}</div>
        <div>Deal id: {item?.id}</div>
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileDealsItem
