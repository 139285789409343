import React from 'react'

import { Input } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import { useServerValidation } from 'hooks/useServerValidation'
import { ErrorInputWrapper } from 'views/ui/ErrorInputWrapper/ErrorInputWrapper'
import { InputProps } from 'views/ui/types'

type Props = {
  formikInstance: FormikContextType<any>
  fieldName: string
  errorFields: Record<string, string[]> | undefined
  width?: number
} & InputProps

export const InputWithErrorsState: React.FC<Props> = ({
  width,
  errorFields,
  fieldName,
  formikInstance,
  ...rest
}) => {
  const [isInvalid, errorText] = useServerValidation(errorFields, fieldName, formikInstance)

  return (
    <ErrorInputWrapper width={width} invalid={isInvalid} errorText={errorText}>
      <Input {...formikInstance.getFieldProps(fieldName)} invalid={isInvalid} {...rest} />
    </ErrorInputWrapper>
  )
}
