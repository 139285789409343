import React from 'react'

import { useDidMount } from '@agro-club/agroclub-shared'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { AnalyticsEvent } from 'modules/types'
import { BidAddForm } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAddForm'
import { BidAddPage } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAddPage'

export const BidAdd: React.FC = () => {
  const { track } = useAnalyticsSSR()

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'create_offer_screen' })
  })

  return (
    <BidAddPage>
      <BidAddForm />
    </BidAddPage>
  )
}
