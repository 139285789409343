import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProfileRoutes from 'views/pages/Profile/routes'

import ProfileDealsItem from './ProfileDealsItem'
import ProfileDealsList from './ProfileDealsList'

const ProfileDealsPage: React.FC = () => (
  <Switch>
    <Route path={ProfileRoutes.ProfileDeals} exact>
      <ProfileDealsList />
    </Route>
    <Route path={ProfileRoutes.ProfileDealsItem}>
      <ProfileDealsItem />
    </Route>
  </Switch>
)

export default ProfileDealsPage
