import styled, { StyledProps, css } from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'copyright ... ... contacts'
    'policies ... ... contacts';

  height: 80px;
  grid-template-columns: max-content auto max-content max-content;
  max-width: ${(props) => props.theme.size.maxContentWidth}px;
  margin: 0 auto;

  ${(props) => props.theme.media.mobile`
    grid-template-areas:
      'copyright contacts'
      'policies policies';

    height: 140px;
    padding: ${(props) => props.theme.padding.mobile.content}px;
    grid-template-columns: auto auto;
  `}
`

export const Link = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.primary600};

  a {
    color: ${(props) => props.theme.color.primary600};
  }

  & + & {
    margin-left: 12px;
  }
`

export const Text = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  margin-bottom: 4px;

  a {
    color: ${(props) => props.theme.color.onPrimaryDark};
  }

  ${(props) => props.theme.media.mobile`
    font-size: 16px;
  `}
`

export const Apps = styled.div`
  display: flex;
  margin-left: 12px;
  align-items: center;
`

export const AppBadge = styled.a`
  margin-left: 4px;

  img {
    display: block;
  }
`

export const AppsText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};

  ${(props) => props.theme.media.mobile`
    margin-top: 4px;
    text-align: center;
  `}
`

type AreaProps = StyledProps<{ area: 'copyright' | 'policies' | 'contacts' | 'apps' | 'apps-text' }>
export const Area = styled.div`
  grid-area: ${(props: AreaProps) => props.area};
  ${(props: AreaProps) => {
    switch (props.area) {
      case 'apps':
        return css`
          align-self: center;
          justify-self: end;
        `
      case 'contacts':
        return css`
          align-self: center;
          justify-self: center;
        `
      case 'apps-text':
        return css`
          align-self: center;
          justify-self: end;
        `
      case 'copyright':
        return css`
          align-self: end;
        `
    }
  }}

  ${(props) => props.theme.media.mobile`
    ${(props: AreaProps) => {
      switch (props.area) {
        case 'apps':
          return css`
            align-self: center;
            justify-self: end;
          `
        case 'contacts':
          return css`
            align-self: center;
            justify-self: end;
          `
        case 'copyright':
          return css`
            align-self: end;

            ${Text} {
              font-size: 14px;
            }
          `
        case 'apps-text':
          return css`
            align-self: center;
            justify-self: end;
          `
        case 'policies':
          return css`
            display: flex;
            justify-content: space-between;
            margin-top: 8px;

            ${Link} a {
              color: ${props.theme.color.secondary200};
            }
          `
      }
    }}
  `}
`
