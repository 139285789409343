import { matchPath } from 'react-router'
/* eslint-disable no-continue */
import { call, getContext, race, select, take } from 'redux-saga/effects'

import { AnalyticsInstance } from 'analytics'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import qs from 'query-string'

import env from 'env'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import { ApiService } from 'service/api/interface'

type ArgumentsType<T> = T extends (api: ApiService) => (...args: infer V) => Promise<unknown> ? V : never
export function* apiCall<T extends (api: ApiService) => (...args: any[]) => Promise<unknown>>(
  manager: T,
  ...args: ArgumentsType<T>
) {
  const apiService = yield getContext('apiService')
  return yield call(manager(apiService), ...args)
}

export class CancelToken {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _subscriber = () => {}

  cancel() {
    this._subscriber()
  }
}

export function* getApiClient() {
  const apiService: ApiService = yield getContext('apiService')
  return apiService
}

export function* apiCallCancellable<T extends (api: ApiService) => (...args: any[]) => Promise<unknown>>(
  manager: T,
  cancelToken: CancelToken,
  ...args: ArgumentsType<T>
) {
  const apiService: ApiService = yield getContext('apiService')
  const req = manager(apiService)(...args)
  // eslint-disable-next-line no-param-reassign
  cancelToken._subscriber = () => apiService.cancelRequest(req)
  return yield call(() => req)
}

export function* getTokenService() {
  return yield getContext('tokenService')
}

export function* getAnalyticsInstance(): Generator<AnalyticsInstance> {
  return yield getContext('analyticsInstance') as unknown as AnalyticsInstance
}

export function makeRouteWatcher<
  // routes
  R extends { [route: string]: string },
>(
  routesMap: R,
  callbackGen: (result: {
    params: { [key: string]: string }
    query: { [key: string]: string | string[] | null }
    isFirstRender: boolean
    silent: boolean
    match: R[keyof R]
  }) => any,
) {
  const routes = Object.values(routesMap)
    .map((r) => r.split('/'))
    .sort((a, z) => z.length - a.length)
    .map((r) => r.join('/'))

  return function* routeWatcherGen() {
    while (1) {
      const action: LocationChangeAction<{ silent: boolean }> = yield take(LOCATION_CHANGE)
      const match = matchPath(action.payload.location.pathname, routes)

      if (!match || !match.isExact) {
        continue
      }

      if (action.payload.isFirstRendering && env.BROWSER) {
        continue
      }

      if (!action.payload.isFirstRendering && !env.BROWSER) {
        continue
      }

      const initProgress: Progress = yield select(AuthSelectors.initProgress)
      if (!env.BROWSER && ['IDLE', 'WORK'].includes(initProgress)) {
        yield race({
          success: take(AuthActions.initRequestSucceed.type),
          failed: take(AuthActions.initRequestFailed.type),
        })
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield call(callbackGen, {
        params: match.params,
        query: qs.parse(action.payload.location.search),
        match: match.path as R[keyof R],
        isFirstRender: action.payload.isFirstRendering,
        silent: action.payload.location.state?.silent ?? false,
      })
    }
  }
}
