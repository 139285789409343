import React from 'react'

import styled, { css } from 'styled-components'

const LabeledContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelStyled = styled.div`
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
  margin-bottom: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const LabeledContainer: React.FC<{ label: string; className?: string }> = ({
  children,
  label,
  className,
}) => (
  <LabeledContainerStyled className={className}>
    <LabelStyled>{label}</LabelStyled>
    {children}
  </LabeledContainerStyled>
)

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`

export const Section = styled.div`
  display: grid;
  grid-template-columns: max-content 454px;
  grid-gap: 16px 40px;

  ${(props) => props.theme.media.mobile`
    grid-template-columns: auto;
    grid-gap: 0;
    margin-top: -8px;
  `}
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  ${(props) => props.theme.media.mobile`
    margin-top: 8px;
  `}
`

export const Cell = styled.div`
  ${(props) => props.theme.media.mobile`
    margin-top: 4px;
  `}
`

const remark = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

export const BottomRemark = styled.div`
  ${remark}
`

export const PriceRemark = styled.span`
  ${remark};
  flex: 1;
  margin-left: 16px;
`

export const InputWrapper = styled.div`
  width: 118px;
`

export const PriceWrapper = styled.div`
  display: flex;
`

export const LabelHelpText = styled.div`
  ${remark}
`
