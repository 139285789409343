import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { IconUser, useMatchMedia } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import isLangSelect from 'helpers/isLangSelect'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthSelectors from 'modules/domain/auth/selectors'
import { queries } from 'theme/theme'
import { SaleButton } from 'views/components/Header/SaleButton'
import { I18nLangSelect } from 'views/components/I18nLangSelect/I18nLangSelect'
import Link from 'views/components/Link/Link'
import { SignOutButton } from 'views/components/SignOutButton/SignOutButton'
import { Routes } from 'views/pages/routes'
import { StyledIconButton } from 'views/ui/styled'

import HeaderEvents from './events'
import * as Styled from './styles'

const ProfileButton = () => {
  const { t } = useTranslation('header')
  const profile = useSelector(AuthSelectors.profile)
  const userName = [profile?.profile?.first_name, profile?.profile?.last_name].filter(Boolean).join(' ')
  const { track } = useAnalyticsSSR()
  return (
    <StyledIconButton
      aria-label={t('profileLinkLabel')}
      onClick={() => track(HeaderEvents.UpperlineClick, { type: 'profile' })}
      to={Routes.Profile}
      Icon={IconUser}
      hideTextOnMobile
    >
      {userName}
    </StyledIconButton>
  )
}

const SignInButton = () => {
  const { t } = useTranslation(['common', 'header'])
  const { track } = useAnalyticsSSR()
  const location = useLocation()

  const locationWithState = {
    pathname: Routes.SignIn,
    state: {
      from: location.pathname,
    },
  }

  return (
    <StyledIconButton
      aria-label={t('header:signInLinkLabel')}
      onClick={() => track(HeaderEvents.UpperlineClick, { type: 'signin' })}
      to={locationWithState}
      Icon={IconUser}
      hideTextOnMobile
    >
      {t('signIn')}
    </StyledIconButton>
  )
}

const Buttons = () => {
  const isAuthorized = useSelector(AuthSelectors.isAuthenticated)
  const isMobile = useMatchMedia(queries.mobile)

  return (
    <Styled.ButtonsWrapper>
      {isLangSelect() && <I18nLangSelect />}
      {!isMobile && <SaleButton />}
      {isAuthorized ? (
        <>
          <ProfileButton />
          <SignOutButton />
        </>
      ) : (
        <SignInButton />
      )}
    </Styled.ButtonsWrapper>
  )
}

const StyledLink = styled(Link)`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  transition: color 0.2s ease-out;
  &:hover {
    color: ${({ theme }) => theme.color.primary500};
  }
`

const LinksWrapper = styled.div`
  padding-left: 24px;
  grid-gap: 24px;
`

const Header: React.FC = () => {
  // const isMobile = useMatchMedia(queries.mobile)
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation(['common', 'header'])
  return (
    <Styled.Wrapper>
      <Link
        to="/"
        aria-label={t('header:mainPageLinkLabel')}
        onClick={() => track(HeaderEvents.UpperlineClick, { type: 'home' })}
      >
        <Styled.Logo />
      </Link>
      <LinksWrapper>
        <StyledLink to="/" onClick={() => track(HeaderEvents.UpperlineClick, { type: 'harvest' })}>
          {t('MainPage:h2')}
        </StyledLink>
      </LinksWrapper>
      <Buttons />
    </Styled.Wrapper>
  )
}

export default Header
