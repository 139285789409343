import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as LogoRuIcon } from 'assets/images/logo-ru.svg'
import { PriceType } from 'views/pages/BidOffer/BidOffer'
import * as S from 'views/pages/BidOffer/styled'

export const PriceCard: React.FC<{
  onClick: (priceType: PriceType) => void
  selected: boolean
  priceType: PriceType
  price: string
  quality: string
  paymentTerms: string
}> = ({ onClick, selected, price, priceType, quality, paymentTerms }) => {
  const { t } = useTranslation('BidOffer')
  const priceTypeText = t(`priceTypeTitle_${priceType}`) || ''
  const priceDescriptionText = t(`priceTypeDescription_${priceType}`)
  return (
    <S.PriceCardWrapper selected={selected} onClick={() => onClick(priceType)}>
      <S.CheckBadge checked={selected} />
      <S.PriceCardPrice>
        {price} — {priceTypeText}
      </S.PriceCardPrice>
      <S.PriceDescription>{priceDescriptionText}</S.PriceDescription>
      <S.PriceCardQualityText>{quality}</S.PriceCardQualityText>
      <S.PriceCardPaymentTerms>{paymentTerms}</S.PriceCardPaymentTerms>
      {priceType === 'exw' ? (
        <S.PriceCardCompanyLogoWrapper>
          <LogoRuIcon />
        </S.PriceCardCompanyLogoWrapper>
      ) : null}
    </S.PriceCardWrapper>
  )
}
