import React from 'react'

import { helpersColor } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import CardLink from 'views/ui/CardLink/CardLink'
import CardLinkSkeleton from 'views/ui/CardLinkSkeleton/CardLinkSkeleton'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

import { ReactComponent as CanolaIcon } from './icons/canola.svg'
import { ReactComponent as CornIcon } from './icons/corn.svg'
import { ReactComponent as CottonIcon } from './icons/cotton.svg'
import { ReactComponent as OatsIcon } from './icons/oats.svg'
import { ReactComponent as RiceIcon } from './icons/rice.svg'
import { ReactComponent as RyeIcon } from './icons/rye.svg'
import { ReactComponent as SoybeanIcon } from './icons/soybean.svg'
import { ReactComponent as SunflowerIcon } from './icons/sunflower.svg'
import { ReactComponent as WheatIcon } from './icons/wheat.svg'

const Wrapper = styled(CardLink)`
  justify-content: space-between;
  flex-direction: row;
  padding: 0;
`

const InfoContainer = styled.div<{ withIcon: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.withIcon ? 'flex-start' : 'center')};
  min-width: 0;
  min-height: 0;
  padding: 18px 16px;
  > * {
    text-align: ${(props) => (props.withIcon ? 'left' : 'center')};
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`
const SubTitle = styled.div`
  margin-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  > svg {
    fill: ${(props) => helpersColor.alphaColor(props.theme.color.accentNotify, 0.2)};
  }
`

const iconMap: Record<string, React.ReactNode> = {
  canola: <CanolaIcon />,
  cotton: <CottonIcon />,
  oats: <OatsIcon />,
  rye: <RyeIcon />,
  rice: <RiceIcon />,
  corn: <CornIcon />,
  soybean: <SoybeanIcon />,
  sunflower: <SunflowerIcon />,
  wheat: <WheatIcon />,
  wheat_5: <WheatIcon />,
  wheat_4: <WheatIcon />,
  wheat_3: <WheatIcon />,
}

export const CategoryCard: React.FC<{
  title: string
  subTitle?: string
  kind?: string
  to: string
}> = ({ title, subTitle, kind, to }) => {
  const { track } = useAnalyticsSSR()
  const icon = kind && iconMap[kind] ? <IconContainer>{iconMap[kind]}</IconContainer> : null
  return (
    <Wrapper $minHeight={0} to={to} onClick={() => track('harvest_product_tap', { name: title })}>
      <InfoContainer withIcon={!!icon}>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </InfoContainer>
      {icon}
    </Wrapper>
  )
}

export const CategoryCardSkeleton: React.FC = () => (
  <CardLinkSkeleton $minHeight={88}>
    <SkeletonText $height={22} $width={100} />
    <SkeletonText $height={18} $width={100} style={{ marginTop: '6px' }} />
  </CardLinkSkeleton>
)
