import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'

import { Product, ProductDTO } from './types'

export const getList = (apiClient: ApiService) => async () => apiClient.get<Product[]>(endpoints.product())

export const getItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.get<Product>(endpoints.product(id))

export const addItem = (apiClient: ApiService) => async (dto: ProductDTO) =>
  apiClient.post<Product>(endpoints.product(), dto)

export const updateItem = (apiClient: ApiService) => async (id: string, dto: Partial<ProductDTO>) =>
  apiClient.put<Product>(endpoints.product(id), dto)

export const removeItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.delete<Product>(endpoints.product(id))
