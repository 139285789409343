import React from 'react'

import styled from 'styled-components'

type ContainerProps = {
  $minHeight?: number
  $border?: boolean
}

const CardLinkSkeletonWrapper = styled.div<ContainerProps>`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  background-color: ${(props) => props.theme.color.onPrimaryLight};
  min-height: ${(props) => `${props.$minHeight ?? 128}px`};
  padding: 12px 16px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  transition: border-color 0.1s ease-in, background 0.1s ease-in;
  border: 1px solid ${(props) => (props.$border ? props.theme.color.onSurfaceLowEmphasys : 'transparent')};
`

const SkeletonOverlay = styled.div`
  position: absolute;
  padding: 2px;
  left: 14px;
  right: 14px;
  top: 10px;
  bottom: 10px;
  backdrop-filter: blur(2px);
`

const CardLinkSkeleton: React.FC<ContainerProps> = ({ children, ...containerProps }) => (
  <CardLinkSkeletonWrapper {...containerProps}>
    {children}
    <SkeletonOverlay />
  </CardLinkSkeletonWrapper>
)

export default CardLinkSkeleton
