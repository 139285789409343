import { RouterState } from 'connected-react-router'

import { AuthState } from 'modules/domain/auth/types'

import { BidState } from './domain/bid/types'
import { CollectionState } from './domain/collection/types'
import { CompanyState } from './domain/company/types'
import { DealState } from './domain/deal/types'
import { ProductState } from './domain/product/types'
import { UploadManagerState } from './domain/uploadManager/types'
import { UserAddressState } from './domain/userAddress/types'
import { UserBidState } from './domain/userBid/types'

export type AppGlobalState = {
  router: RouterState<object>
  auth: AuthState
  bid: BidState
  product: ProductState
  collection: CollectionState
  deal: DealState
  userAddress: UserAddressState
  uploadManager: UploadManagerState
  userBid: UserBidState
  company: CompanyState
}

export type ActionPayload<T extends (...args: any[]) => { payload: any }> = Pick<
  ReturnType<T>,
  'payload'
>['payload']

export enum Progress {
  IDLE = 'IDLE',
  WORK = 'WORK',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum AnalyticsEvent {
  Page = 'Page',
  Action = 'Action',
}

export type ResourceHook<T = any> = (...args: any[]) => readonly [Progress, T | undefined]
