import { all, call, fork, put, race, select, take, takeLatest } from 'redux-saga/effects'

import { LOCATION_CHANGE, LocationChangeAction, push, replace } from 'connected-react-router'

import env from 'env'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { apiCall, getAnalyticsInstance, getTokenService } from 'modules/sagaEffects'
import { RequestError } from 'service/api/errors'
import { TokenService } from 'service/token/interface'
import { Routes } from 'views/pages/routes'
import SignUpRoutes from 'views/pages/SignUp/routes'

import AuthActions from './duck'
import * as managers from './managers'
import AuthSelectors from './selectors'
import { Countries, TokenRequestFailedPayload, TokensPair, UserProfile } from './types'

function* fetchUserAddressList() {
  try {
    yield put(UserAddressActions.listRequested({}))
    yield race({
      success: take(UserAddressActions.listRequestSucceed.type),
      error: take(UserAddressActions.listRequestFailed.type),
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export const getLocation = function* () {
  const pathname: string = yield select(AuthSelectors.pathname)
  const search: string = yield select(AuthSelectors.search)
  const state = yield select(AuthSelectors.locationState)
  let fromPath: string | null = null
  if (state) {
    const fromState = state.from || null
    fromPath = fromState === Routes.SignIn ? Routes.Main : fromState || null
  }

  if (pathname === Routes.SignIn) {
    return {
      pathname: `${Routes.Main}${search}`,
      from: fromPath,
    }
  }

  return {
    pathname: `${pathname}${search}`,
    from: fromPath,
    search,
  }
}

export const initSequence = function* () {
  const { pathname, from } = yield call(getLocation)
  const tokenService: TokenService = yield getTokenService()
  try {
    const token = tokenService.getAccessToken()
    const analyticsInstance = yield getAnalyticsInstance()

    if (token) {
      const response: UserProfile = yield apiCall(managers.fetchCurrentUser)
      if (response.is_active) {
        if (env.SEGMENT_WRITE_KEY && env.BROWSER) {
          analyticsInstance.identify(response.id, {
            ...response,
            build: env.BUILD_VERSION,
            country: Countries.RU,
          })
          analyticsInstance.track('signin_success')
        }
        yield call(fetchUserAddressList)
        yield put(AuthActions.initRequestSucceed(response))
        yield put(replace(from || pathname))
        return
      }
    }
    tokenService.clearToken()
    yield put(AuthActions.initRequestFailed())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    tokenService.clearToken()
    yield put(AuthActions.initRequestFailed())
    yield put(replace(Routes.SignIn, { from: pathname }))
  }
}

export const verifySmsCodeSaga = function* ({
  payload: [phone, code],
}: ReturnType<typeof AuthActions.smsCodeVerificationRequested>) {
  try {
    const tokensPair: TokensPair = yield apiCall(managers.checkSmsCode, phone, code)
    const tokenService: TokenService = yield getTokenService()
    const analyticsInstance = yield getAnalyticsInstance()
    tokenService.saveAccessToken(tokensPair.accessToken)
    tokenService.saveRefreshToken(tokensPair.refreshToken)
    const userProfile: UserProfile = yield apiCall(managers.fetchCurrentUser)
    if (userProfile.is_active) {
      yield call(fetchUserAddressList)
      yield put(AuthActions.smsCodeVerificationsSucceed(tokensPair, userProfile))

      if (env.SEGMENT_WRITE_KEY && env.BROWSER) {
        analyticsInstance.identify(userProfile.id, {
          ...userProfile,
          build: env.BUILD_VERSION,
          country: Countries.RU,
        })
      }

      if (userProfile.signup_step !== 'complete') {
        return yield put(replace(SignUpRoutes.SignUp))
      }

      if (env.SENTRY_DSN && env.BROWSER) {
        analyticsInstance.track('signin_success')
      }

      return yield put(replace('/'))
    }
    throw new Error('TODO: inactive user error text')
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    yield put(AuthActions.smsCodeVerificationFailed(err as TokenRequestFailedPayload))
  }
}

export const resetPasswordSaga = function* (_: ReturnType<typeof AuthActions.resetPasswordRequested>) {
  yield put(push(Routes.Reset))
}

export const updateTokensSaga = function* ({
  payload: [token, _profile],
}: ReturnType<typeof AuthActions.smsCodeVerificationsSucceed>) {
  const tokenService: TokenService = yield getTokenService()
  tokenService.saveAccessToken(token.accessToken)
  tokenService.saveRefreshToken(token.refreshToken)
}

export const phoneSubmitSaga = function* ({
  payload: [phone],
}: ReturnType<typeof AuthActions.phoneSubmitted>) {
  try {
    yield apiCall(managers.requestSmsCode, phone)
    yield put(AuthActions.submitSucceed())
  } catch (err) {
    yield put(AuthActions.submitFailed('TODO add error text'))
  }
}

export const emailSubmitSaga = function* (_action: ReturnType<typeof AuthActions.emailSubmitted>) {
  // try {
  //
  // } catch (err){
  //
  // }
}

export const signOutSaga = function* (_action: ReturnType<typeof AuthActions.signOutRequested>) {
  const tokenService: TokenService = yield getTokenService()
  tokenService.clearToken()
  yield put(AuthActions.initRequested())
}

export const userRegisterSaga = function* ({
  payload,
}: ReturnType<typeof AuthActions.userRegisterRequested>) {
  try {
    const profile: UserProfile = yield apiCall(managers.updateCurrentUser, payload)
    const analyticsInstance = yield getAnalyticsInstance()

    if (env.SENTRY_DSN && env.BROWSER) {
      analyticsInstance.track('signup_success')
    }
    yield put(AuthActions.userRegisterSucceed(profile))
  } catch (err) {
    yield put(AuthActions.userRegisterFailed((err as any).type))
  }
}

export const profileInfoUpdateSaga = function* ({
  payload,
}: ReturnType<typeof AuthActions.profileInfoUpdateRequested>) {
  try {
    yield apiCall(managers.updateUserInfo, payload)
    const profile: UserProfile = yield apiCall(managers.fetchCurrentUser)
    yield put(AuthActions.profileInfoUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.profileInfoUpdateFailed())
  }
}

export const profileCompanyUpdateSaga = function* ({
  payload,
}: ReturnType<typeof AuthActions.profileCompanyUpdateRequested>) {
  try {
    yield apiCall(managers.updateUserCompanyInfo, payload)
    const profile: UserProfile = yield apiCall(managers.fetchCurrentUser)
    yield put(AuthActions.profileCompanyUpdateSucceed(profile))
  } catch (err) {
    const { type, detail, errors } = RequestError.parseError(err)
    yield put(AuthActions.profileCompanyUpdateFailed(type, detail, errors))
  }
}

export const profileAddressUpdateSaga = function* ({
  payload,
}: ReturnType<typeof AuthActions.profileAddressUpdateRequested>) {
  try {
    yield apiCall(managers.updateUserAddress, payload)
    const profile: UserProfile = yield apiCall(managers.fetchCurrentUser)
    yield call(fetchUserAddressList)
    yield put(AuthActions.profileAddressUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.profileInfoUpdateFailed())
  }
}

const routerWatcher = function* () {
  while (1) {
    const action: LocationChangeAction = yield take(LOCATION_CHANGE)
    if (action.payload.isFirstRendering && !env.BROWSER) {
      yield put(AuthActions.initRequested())
    }
  }
}

export const currentProfileUpdateSaga = function* ({
  payload,
}: ReturnType<typeof AuthActions.currentProfileUpdateRequested>) {
  try {
    yield apiCall(managers.updateCurrentUserProfile, payload)
    const profile: UserProfile = yield apiCall(managers.fetchCurrentUser)
    yield put(AuthActions.currentProfileUpdateSucceed(profile))
  } catch (err) {
    yield put(AuthActions.currentProfileUpdateFailed())
  }
}

const AuthSaga = function* () {
  yield all([
    takeLatest(AuthActions.initRequested.type, initSequence),
    takeLatest(AuthActions.phoneSubmitted.type, phoneSubmitSaga),
    takeLatest(AuthActions.emailSubmitted.type, emailSubmitSaga),
    takeLatest(AuthActions.smsCodeVerificationRequested.type, verifySmsCodeSaga),
    takeLatest(AuthActions.smsCodeVerificationsSucceed.type, updateTokensSaga),
    takeLatest(AuthActions.resetPasswordRequested.type, resetPasswordSaga),
    takeLatest(AuthActions.signOutRequested.type, signOutSaga),
    takeLatest(AuthActions.userRegisterRequested.type, userRegisterSaga),
    takeLatest(AuthActions.profileInfoUpdateRequested.type, profileInfoUpdateSaga),
    takeLatest(AuthActions.profileCompanyUpdateRequested.type, profileCompanyUpdateSaga),
    takeLatest(AuthActions.profileAddressUpdateRequested.type, profileAddressUpdateSaga),
    takeLatest(AuthActions.currentProfileUpdateRequested.type, currentProfileUpdateSaga),
    fork(routerWatcher),
  ])
}

export default AuthSaga
