import React from 'react'

import useFormatNumber from 'hooks/useFormatNumber'
import { CropParameter } from 'modules/domain/types'
import { ParamGroup, ParamPair } from 'views/ui/ParamGroup/ParamGroup'

const CropParameterGroup: React.FC<{ parameters: CropParameter[]; title: string; className?: string }> = ({
  parameters,
  title,
  ...rest
}) => {
  const formatNumber = useFormatNumber()
  return (
    <ParamGroup title={title} {...rest}>
      {parameters.map((item) => {
        const value = [item.condition_label, formatNumber(item.parameter_value)].filter(Boolean).join(' ')
        return <ParamPair key={item.id} title={item.parameter.name} value={value} />
      })}
    </ParamGroup>
  )
}

export default CropParameterGroup
