import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FormField, FormikHook, Input, Chip as UIChip, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthSelectors from 'modules/domain/auth/selectors'
import { PrepaymentType } from 'modules/domain/auth/types'
import { CompanyDadata } from 'modules/domain/collection/types'
import { AnalyticsEvent } from 'modules/types'
import { CompanySelectDadata } from 'views/components/CompanySelectDadata/CompanySelectDadata'
import { InputWithErrorsState } from 'views/ui/InputWithErrorsState/InputWithErrorsState'

const Chip = styled(UIChip)`
  margin-right: 8px;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
`

const InnName = styled.div`
  display: grid;
  grid-gap: 16px;
`

const CompanyInfoContainer = styled.div`
  margin-top: 24px;
`

const CompanyInfoWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
`

const CompanyName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`

const INNWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const INN = styled.span`
  margin-right: 8px;
`

const CompanyText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceHighEmphasys};
`

const CompanyInfo: React.FC<{ company: CompanyDadata }> = ({ company }) => {
  const { t } = useTranslation('CompanyForm')
  return (
    <CompanyInfoWrapper>
      <CompanyName>{company.name_full}</CompanyName>
      <INNWrapper>
        <INN>{t('innLabel')}</INN>
        <CompanyText>{company.inn}</CompanyText>
      </INNWrapper>
      <FormField
        label={t('billingAddressLabel')}
        render={() => <CompanyText>{company.address_full}</CompanyText>}
      />
    </CompanyInfoWrapper>
  )
}

const FormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const FormGroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.secondary300};
`
const FormGroupBody = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 28px;
`

const FormGroup: React.FC<{ title?: string }> = ({ title, children }) => (
  <FormGroupWrapper>
    {title && <FormGroupTitle>{title}</FormGroupTitle>}
    <FormGroupBody>{children}</FormGroupBody>
  </FormGroupWrapper>
)

const CompanyDetailsWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 24px;
  justify-items: flex-start;
`
const ChipWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export type CompanyDetailsFormProps = {
  vat?: boolean
  prepayment?: PrepaymentType
  position: string
  inn: string
  companyName: string
  delay?: number
  state_reg_number?: string
}

const CompanyForm: React.FC<{
  focusOnMount?: boolean
  useFormik: FormikHook
  initialValues?: Partial<CompanyDetailsFormProps>
}> = ({
  focusOnMount = false,
  useFormik,
  initialValues: {
    companyName = '',
    inn = '',
    delay = 0,
    prepayment = 'unknown',
    vat = true,
    position = '',
    state_reg_number,
  } = {},
}) => {
  const { t } = useTranslation('CompanyForm')
  const { track } = useAnalyticsSSR()
  const [selectedCompany, setSelectedCompany] = useState<CompanyDadata | null>()

  const errorFields = useSelector(AuthSelectors.profileCompanyUpdateErrorFields)

  useDidMount(() => track(AnalyticsEvent.Page, { name: 'signup_company_screen' }))

  const formik = useFormik<CompanyDetailsFormProps>({
    initialValues: {
      companyName,
      inn,
      delay,
      prepayment: env.REACT_APP_SHOW_PREPAYMENT ? prepayment : undefined,
      vat: env.REACT_APP_SHOW_VAT ? vat : undefined,
      position,
      state_reg_number,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  })

  const handleCompanyChange = (company: CompanyDadata | null) => {
    setSelectedCompany(company)
    formik.setFieldValue('inn', company?.inn || '')
    formik.setFieldValue('companyName', company?.name_short || '')
  }

  const renderCompanyForm = () => (
    <CompanyDetailsWrapper>
      {env.REACT_APP_SHOW_VAT && (
        <FormGroup>
          <FormField
            label={t('taxLabel')}
            render={() => (
              <ChipWrapper>
                <Chip
                  color="blue"
                  size="small"
                  active={formik.values.vat}
                  onClick={() => formik.setFieldValue('vat', true)}
                >
                  {t('billingAddressOptionYes')}
                </Chip>
                <Chip
                  color="blue"
                  size="small"
                  active={!formik.values.vat}
                  onClick={() => formik.setFieldValue('vat', false)}
                >
                  {t('billingAddressOptionNo')}
                </Chip>
              </ChipWrapper>
            )}
          />
        </FormGroup>
      )}

      <FormGroup title={t('companyRulesLabel')}>
        {env.REACT_APP_SHOW_PREPAYMENT && (
          <FormField
            label={t('prepayLabel')}
            render={() => (
              <ChipWrapper>
                <Chip
                  color="blue"
                  size="small"
                  active={formik.values.prepayment === 'unknown'}
                  onClick={() => formik.setFieldValue('prepayment', 'unknown')}
                >
                  {t('prepayOptionNotSpecified')}
                </Chip>
                <Chip
                  color="blue"
                  size="small"
                  active={formik.values.prepayment === 'yes'}
                  onClick={() => formik.setFieldValue('prepayment', 'yes')}
                >
                  {t('prepayOptionYes')}
                </Chip>
                <Chip
                  color="blue"
                  size="small"
                  active={formik.values.prepayment === 'no'}
                  onClick={() => formik.setFieldValue('prepayment', 'no')}
                >
                  {t('prepayOptionNo')}
                </Chip>
              </ChipWrapper>
            )}
          />
        )}
        <Input
          {...formik.getFieldProps('delay')}
          type="number"
          label={t('delayLabel')}
          placeholder={t('delayPlaceholder')}
        />
      </FormGroup>
    </CompanyDetailsWrapper>
  )

  const showCompanyInputs = env.REACT_APP_COMPANY_NAME_INPUT

  return (
    <Wrapper>
      {showCompanyInputs ? (
        <InnName>
          <InputWithErrorsState
            fieldName="inn"
            label={t('innLabel')}
            errorFields={errorFields}
            formikInstance={formik}
          />
          {env.REACT_APP_SHOW_STATE_REG_NUMBER && (
            <InputWithErrorsState
              fieldName="state_reg_number"
              label={t('state_reg_number')}
              errorFields={errorFields}
              formikInstance={formik}
            />
          )}
          <InputWithErrorsState
            fieldName="companyName"
            label={t('companyNameLabel')}
            errorFields={errorFields}
            formikInstance={formik}
          />
        </InnName>
      ) : (
        <CompanySelectDadata onCompanyChange={handleCompanyChange} autoFocus={focusOnMount} />
      )}

      {selectedCompany ? (
        <CompanyInfoContainer>
          <CompanyInfo company={selectedCompany} />
        </CompanyInfoContainer>
      ) : null}
      {selectedCompany || showCompanyInputs ? renderCompanyForm() : null}
    </Wrapper>
  )
}

export default CompanyForm
