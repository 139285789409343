import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'
import styled from 'styled-components'

import { Product } from 'modules/domain/collection/types'
import { UserBidParameterCondition } from 'modules/domain/userBid/types'
import { EditableFields } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAddForm'
import { CropsParam } from 'views/pages/Profile/ProfileBidsPage/BidAdd/CropsParam'

export const AddParamsButton = styled(Button)`
  padding: 0;
`

const Section = styled.div`
  margin-top: 16px;
  display: grid;
  grid-gap: 16px;
`

type Props = {
  product: Product
  formik: FormikContextType<EditableFields>
}

export const Params: React.FC<Props> = (props) => {
  const { product, formik } = props
  const formicParams = formik.values.parameters
  const mainParam = formik.values.mainParameter.parameter
  const { t } = useTranslation(['bid'])

  return (
    <Section>
      {formicParams.map((p, i) => {
        const usedParams = formicParams.slice(0, i).map((param) => param.parameter)
        const availableParams = product.parameters.filter(
          (param) => param.id !== mainParam && !usedParams.includes(param.id),
        )

        /* eslint-disable react/no-array-index-key */
        return (
          <CropsParam
            onChange={(index, values) => formik.setFieldValue(`parameters[${index}]`, values)}
            index={i}
            onRemove={(index) =>
              formik.setFieldValue(
                `parameters`,
                formicParams.filter((_, i) => i !== index),
              )
            }
            key={i}
            parameters={availableParams}
            parameter={p}
          />
        )
        /* eslint-enable react/no-array-index-key */
      })}
      {formicParams.length < product.parameters.length - 1 ? (
        <div>
          <AddParamsButton
            onClick={() =>
              formik.setFieldValue(`parameters`, [
                ...formicParams,
                {
                  parameter: undefined,
                  condition: Object.keys(UserBidParameterCondition)[1],
                  parameter_value: undefined,
                },
              ])
            }
            intent="minor-action"
          >
            {t('form.addParam')}
          </AddParamsButton>
        </div>
      ) : null}
    </Section>
  )
}
