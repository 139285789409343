import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  SectionBody,
  SectionContainer,
  SectionTitle,
  useAction,
  useHistoryPush,
} from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useOfferVolumeText } from 'hooks/useOfferVolumeText'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import { BidType } from 'modules/domain/bid/types'
import UserBidActions from 'modules/domain/userBid/duck'
import { useUserBidItemManaged } from 'modules/domain/userBid/hooks'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import { AppGlobalState, Progress } from 'modules/types'
import BidCard from 'views/components/BidCard/BidCard'
import { BidOfferPriceCards } from 'views/components/BidOfferPriceCards/BidOfferPriceCards'
import { BidOfferSuccess } from 'views/components/BidOfferSuccess/BidOfferSuccess'
import CropParameterGroup from 'views/components/CropParameterGroup/CropParameterGroup'
import { PriceType } from 'views/pages/BidOffer/BidOffer'
import * as S from 'views/pages/BidOffer/styled'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

const BidOfferWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto 324px;
  align-items: flex-start;
  padding-bottom: 24px;
  ${(props) => props.theme.media.smallTablet`
    grid-template-columns: auto;
  `}
`

type EditableFields = {
  quantity?: number
  priceType: PriceType
}

export const ProfileBidOfferPage: React.FC = () => {
  const { t } = useTranslation()
  const { id: bidId, dealId } = useParams<{ id: string; dealId: string }>()
  const [, bid] = useUserBidItemManaged(bidId)
  const deal = useSelector((state: AppGlobalState) => UserBidSelectors.bidDealByIds(state, bidId, dealId))
  const profileType = useSelector(AuthSelectors.profileType)
  const headerText = useOfferVolumeText(bid?.bid_type)
  const oppositeUserTypeText = t(
    `common:${profileType === ProfileType.seller ? ProfileType.customer : ProfileType.seller}`,
  )
  const push = useHistoryPush()
  const formatNumber = useFormatNumber()

  useHelmet({ title: headerText })

  const progress = useSelector((state) => UserBidSelectors.profileBidOfferSendProgress(state, bidId, dealId))
  const submitOffer = useAction(UserBidActions.sendBidOfferRequested)

  const validationSchema = useMemo(
    () =>
      Yup.object({
        quantity: Yup.number().required(),
      }),
    [],
  )

  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      quantity: deal?.quantity,
      priceType: profileType === ProfileType.customer ? 'cpt' : 'exw',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: useCallback(() => {
      if (!formik.isValid) {
        return
      }

      submitOffer(bidId, dealId, {
        quantity: formik.values.quantity as number,
        can_deliver:
          (profileType === ProfileType.customer && formik.values.priceType === 'exw') ||
          (profileType === ProfileType.seller && formik.values.priceType === 'cpt'),
      })
    }, [profileType, bidId, dealId]),
  })

  if (!bid || !deal) {
    return null
  }

  const oppositeBid = bid.bid_type === BidType.sale ? deal.purchase_bid : deal.sale_bid
  const oppositeBidType = bid.bid_type === BidType.sale ? BidType.purchase : BidType.sale

  const handleChangePrice = (priceType: PriceType) => {
    formik.setFieldValue('priceType', priceType)
  }

  const goBack = () => {
    push({ route: ProfileRoutes.ProfileBidItem, params: { id: bidId } })
  }

  if (progress === Progress.SUCCESS) {
    return (
      <BidOfferWrapper>
        <BidOfferSuccess>
          <Styled.HiddenOnMediaBlock hideMobile>
            <SectionTitle onBack={goBack} />
          </Styled.HiddenOnMediaBlock>
        </BidOfferSuccess>
      </BidOfferWrapper>
    )
  }

  return (
    <BidOfferWrapper>
      <SectionContainer noDivider>
        <Styled.HiddenOnMediaBlock hideMobile>
          <SectionTitle onBack={goBack}>{headerText}</SectionTitle>
        </Styled.HiddenOnMediaBlock>
        <SectionBody noGrid paddedOnMobile>
          <S.Table>
            <S.Row>
              <S.TitleCell>{t('BidOffer:productLabel')}</S.TitleCell>
              <S.ValueCell>{deal.product.title}</S.ValueCell>
            </S.Row>
            <S.Row>
              <S.TitleCell style={{ alignSelf: 'center' }}>{t('BidOffer:volumeLabel')}</S.TitleCell>
              <S.ValueCell>
                <S.VolumeInputContainer>
                  <NumberInput
                    invalid={formik.touched.quantity && !!formik.errors.quantity}
                    isInteger
                    onChange={(value) => formik.setFieldValue('BidOffer:quantity', value)}
                    id="bid-qty"
                    value={formik.values.quantity}
                  />
                  <S.QtyUnit>{t('BidOffer:qtyUnit')}</S.QtyUnit>
                </S.VolumeInputContainer>
              </S.ValueCell>
            </S.Row>
            <S.Row>
              <S.TitleCell>{t('BidOffer:sendAddressLabel')}</S.TitleCell>
              <S.ValueCell>{bid?.address?.address}</S.ValueCell>
            </S.Row>
            <S.Row>
              <S.PriceTitleCell>{t('BidOffer:priceLabel')}</S.PriceTitleCell>
              <S.ValueCell>
                <BidOfferPriceCards
                  price={{ price_cpt: deal.price_cpt, price_exw: deal.price_exw }}
                  handleChangePrice={handleChangePrice}
                  priceType={formik.values.priceType}
                  priceProgress={Progress.SUCCESS}
                />
              </S.ValueCell>
            </S.Row>
            <S.Row>
              <S.TitleCell>{t('BidOffer:distanceLabel')}</S.TitleCell>
              <S.ValueCell>
                <S.AddressText>
                  <S.DistanceText>
                    {deal?.distance ?? (formatNumber(deal.distance) || '-')} {t('common:km')}
                  </S.DistanceText>
                </S.AddressText>
              </S.ValueCell>
            </S.Row>
            <S.GroupContainer>
              <CropParameterGroup
                title={t('BidOffer:cropParametersGroupTitle')}
                parameters={bid.parameters}
              />
            </S.GroupContainer>
          </S.Table>
          <S.SubmitButtonContainer>
            <S.SubmitButton
              progress={progress}
              onClick={formik.submitForm}
              intent="primary"
              filled
              size="big"
            >
              {headerText}
            </S.SubmitButton>
            <S.SubmitHintText>
              {t('BidOffer:submitHintText_1')}
              <br />
              {t('BidOffer:submitHintText_2', {
                userType: oppositeUserTypeText,
              })}
            </S.SubmitHintText>
          </S.SubmitButtonContainer>
        </SectionBody>
      </SectionContainer>
      <BidCard
        title={t(`BidOffer:bidCardTitle.${oppositeBidType}`)}
        bid={{
          ...oppositeBid,
          product: { ...deal.product },
          quantity: deal.quantity,
          price_cpt: deal.price_cpt,
          price_exw: deal.price_exw,
        }}
      />
    </BidOfferWrapper>
  )
}
