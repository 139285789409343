import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { IconLinkOpener, SectionBody, SectionContainer } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import useFormatNumber from 'hooks/useFormatNumber'
import { Bid } from 'modules/domain/bid/types'
import CropParameterGroup from 'views/components/CropParameterGroup/CropParameterGroup'
import Link from 'views/components/Link/Link'
import BidItemRoutes from 'views/pages/BidItem/routes'
import { ParamGroup, ParamPair } from 'views/ui/ParamGroup/ParamGroup'

const H2 = styled.h2`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

const OpenerLink = styled(Link)`
  display: flex;
  margin-left: 8px;
`

const LinkIcon = styled(IconLinkOpener)`
  width: 24px;
  height: 24px;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ID = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const ParamGroupContainer = styled.div`
  margin-top: 24px;
`

type BidTypeForCard = Omit<
  Bid,
  'created' | 'modified' | 'address' | 'bid_type' | 'company' | 'status' | 'distance' | 'id'
> & {
  id?: string
}

const BidCard: React.FC<{ title: string; bid: BidTypeForCard }> = ({ title, bid }) => {
  const { t } = useTranslation('BidCard')
  const formatNumber = useFormatNumber()
  return (
    <SectionContainer noDivider>
      <SectionBody noGrid paddedOnMobile>
        <H2>
          {title}
          {!!bid.id && (
            <OpenerLink to={generatePath(BidItemRoutes.BidItem, { id: bid.id })}>
              <LinkIcon />
            </OpenerLink>
          )}
        </H2>
        <BodyWrapper>
          {!!bid.id && <ID>{t('idTitle', { id: bid.id })}</ID>}
          <ParamGroupContainer>
            <ParamGroup>
              <ParamPair title={t('productTitle')} value={bid.product.title} />
              <ParamPair title={t('volumeTitle')} value={formatNumber(bid.quantity)} />
              <ParamPair title={t('priceExwTitle')} value={formatNumber(bid.price_exw, true)} />
              <ParamPair title={t('priceCptTitle')} value={formatNumber(bid.price_cpt, true)} />
            </ParamGroup>
          </ParamGroupContainer>
          <ParamGroupContainer>
            <CropParameterGroup title={t('cropParametersTitle')} parameters={bid.parameters} />
          </ParamGroupContainer>
        </BodyWrapper>
      </SectionBody>
    </SectionContainer>
  )
}

export default BidCard
