import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { HelmetContext } from 'hooks/useHelmet'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Progress } from 'modules/types'
import PageWrapper from 'views/components/PageWrapper/PageWrapper'
import SpinnerLayout from 'views/layouts/SpinnerLayout/SpinnerLayout'
import BidItemPage from 'views/pages/BidItem/BidItem'
import BidOfferPage from 'views/pages/BidOffer/BidOfferPage'
import BidsPage from 'views/pages/Bids/BidsPage'
import MainPage from 'views/pages/Main/MainPage'
import ProfilePage from 'views/pages/Profile/ProfilePage'
import { Routes } from 'views/pages/routes'
import SignUpPage from 'views/pages/SignUp/SignUpPage'

import CookiesPolicy from './components/CookiesPolicy/CookiesPolicy'
import SignInPage from './pages/SignIn/SignInPage'

const RootComponent: React.FC = () => {
  const authProgress = useSelector(AuthSelectors.initProgress)
  const { helmet } = useContext(HelmetContext)
  const { t } = useTranslation()

  if (authProgress === Progress.WORK || authProgress === Progress.IDLE) {
    return <SpinnerLayout />
  }

  return (
    <>
      <Helmet defaultTitle={t('meta:title')}>
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
      </Helmet>
      <PageWrapper>
        <Switch>
          <Route path={Routes.Main} exact>
            <MainPage />
          </Route>
          <Route path={Routes.SignIn}>
            <SignInPage />
          </Route>
          <Route path={Routes.Profile}>
            <ProfilePage />
          </Route>

          <Route path={Routes.Bids}>
            <BidsPage />
          </Route>
          <Route path={Routes.BidItemOffer}>
            <BidOfferPage />
          </Route>
          <Route path={Routes.BidItem}>
            <BidItemPage />
          </Route>
          <Route path={Routes.SignUp}>
            <SignUpPage />
          </Route>
          {/* TODO 404 fallback */}
          <Route path="/">
            <MainPage />
          </Route>
          <Redirect to={Routes.SignIn} />
        </Switch>
      </PageWrapper>
      <CookiesPolicy />
    </>
  )
}

export default RootComponent
