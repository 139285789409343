import React, { useMemo } from 'react'

import { Breadcrumbs as UIBreadcrumbs } from '@agro-club/agroclub-shared'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useBreadcrumbs from 'hooks/useBreadcrumbs'
import Link from 'views/components/Link/Link'

import BreadcrumbsEvents from './events'

const Breadcrumbs: React.FC<{ items?: Array<{ path?: string; title: string }> }> = ({ items = [] }) => {
  const { track } = useAnalyticsSSR()
  const breadcrumbs = useBreadcrumbs()
  const breadcrumbsWithLinks = useMemo(
    () =>
      breadcrumbs.map((item) => ({
        ...item,
        title: item.path ? <Link to={item.path}>{item.title}</Link> : item.title,
      })),
    [breadcrumbs],
  )

  const itemsWithLinks = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        title: item.path ? (
          <Link
            to={item.path}
            onClick={() => track(BreadcrumbsEvents.BreadcrumbsClick, { text: item.title })}
          >
            {item.title}
          </Link>
        ) : (
          item.title
        ),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items],
  )

  return <UIBreadcrumbs breadcrumbs={breadcrumbsWithLinks} items={itemsWithLinks} />
}

export default Breadcrumbs
