import formatSortParam from 'helpers/formatSortParam'
import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { ListResponse } from 'types/api'

import {
  Bid,
  BidDTO,
  BidListRequestFilter,
  BidListRequestSorting,
  BidPrice,
  BidResponseCreateDTO,
} from './types'

export const getList =
  (apiClient: ApiService) =>
  async (filter: BidListRequestFilter, sorting: BidListRequestSorting, page: number, pageSize: number) =>
    apiClient.get<ListResponse<Bid>>(endpoints.bid(), {
      ...filter,
      sort: formatSortParam(sorting),
      page,
      page_size: pageSize,
    })

export const getItem = (apiClient: ApiService) => async (id: string) => apiClient.get<Bid>(endpoints.bid(id))

export const getItemPrice = (apiClient: ApiService) => async (id: string, address?: number) =>
  apiClient.get<BidPrice>(endpoints.bidPrice(id), { address })

export const createBidResponse =
  (apiClient: ApiService) => async (bidId: string, dto: BidResponseCreateDTO) =>
    apiClient.post(endpoints.bidResponse(bidId), dto)

export const addItem = (apiClient: ApiService) => async (dto: BidDTO) =>
  apiClient.post<Bid>(endpoints.bid(), dto)

export const updateItem = (apiClient: ApiService) => async (id: string, dto: Partial<BidDTO>) =>
  apiClient.put<Bid>(endpoints.bid(), dto)

export const removeItem = (apiClient: ApiService) => async (_id: string) =>
  apiClient.delete<Bid>(endpoints.bid())
