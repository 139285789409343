import React from 'react'
import { useTranslation } from 'react-i18next'

import { SectionBody, SectionContainer, SectionTitle } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import useHelmet from 'hooks/useHelmet'
import LoginForm from 'views/components/LoginForm/LoginForm'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'

const Wrapper = styled.div`
  ${(props) => props.theme.media.desktop`
    max-width: 628px;
  `}
  ${(props) => props.theme.media.tablet`
    max-width: 468px;
  `}
  ${(props) => props.theme.media.mobile`
    width: 100%;
  `}
`

const SignInPage: React.FC = () => {
  const { t } = useTranslation(['auth', 'common'])
  useHelmet({ title: t('metaTitle') })

  return (
    <Article>
      <Breadcrumbs />
      <Wrapper>
        <SectionContainer noDivider>
          <SectionTitle>{t('signIn')}</SectionTitle>
          <SectionBody paddedOnMobile noGrid>
            <LoginForm />
          </SectionBody>
        </SectionContainer>
      </Wrapper>
    </Article>
  )
}

export default SignInPage
