import React from 'react'

import styled from 'styled-components'

// TODO move icon to @agro-club/agroclub-shared
import { ReactComponent as SuccessIcon } from 'views/components/BidOfferSuccess/success-icon.svg'

const SuccessDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 575px;
  margin-top: 8px;
`

const SuccessIconStyled = styled(SuccessIcon)`
  margin-bottom: 32px;
`

const H1 = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

type Props = {
  title: string
  description: string
}

export const CommonSuccess: React.FC<Props> = ({ title, description }) => (
  <>
    <SuccessIconStyled />
    <div>
      <H1>{title}</H1>
    </div>
    <SuccessDescription>{description}</SuccessDescription>
  </>
)
