import { ErrorInfo } from 'react'
import { Action, Middleware } from 'redux'

import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import env from 'env'
import { AppGlobalState } from 'modules/types'
import { captureAxiosError } from 'sentry/sentryHelpers'

import AuthActions from './modules/domain/auth/duck'

function isInitSucceedAction(action: Action): action is ReturnType<typeof AuthActions.initRequestSucceed> {
  return action.type === AuthActions.initRequestSucceed.type
}

class SentryAdapter {
  sentryInstance: typeof import('@sentry/browser') | null

  constructor() {
    this.sentryInstance = null
    this.handleAxiosError = this.handleAxiosError.bind(this)
    this.handleAxiosRequest = this.handleAxiosRequest.bind(this)
    this.handleAxiosResponse = this.handleAxiosResponse.bind(this)
  }

  async init() {
    if (env.SENTRY_DSN && env.BROWSER) {
      this.sentryInstance = await import('@sentry/browser')
      this.sentryInstance.init({ dsn: env.SENTRY_DSN })
    }
  }

  handleAxiosRequest(config: AxiosRequestConfig) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        type: 'initiated',
        url: config.url,
        params: config.params,
        data: config.data,
      },
    })
  }

  handleAxiosError(error: AxiosError) {
    captureAxiosError(this.sentryInstance, error)
  }

  handleAxiosResponse(response: AxiosResponse) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.addBreadcrumb({
      category: 'request',
      type: 'http',
      data: {
        path: response.config.url,
        method: response.config.method,
        type: 'response',
      },
    })
  }

  handleReactException(error: Error, errorInfo: ErrorInfo, storeSnapshot: AppGlobalState) {
    if (!this.sentryInstance) {
      return
    }
    this.sentryInstance.withScope((scope) => {
      scope.setExtra('ComponentStack', errorInfo.componentStack)
      scope.setExtra('ReduxStoreSnapshot', storeSnapshot)
      if (this.sentryInstance) {
        this.sentryInstance.captureException(error)
      }
    })
  }

  handleReduxAction(action: Action) {
    if (!this.sentryInstance) {
      return
    }
    if (isInitSucceedAction(action)) {
      this.sentryInstance.setUser({ id: action.payload.id, username: action.payload.phone })
    }
    this.sentryInstance.addBreadcrumb({
      category: 'redux-action',
      message: action.type,
    })
  }
}

const Singleton = new SentryAdapter()

export const middleware: Middleware = (_store) => (next) => (action) => {
  Singleton.handleReduxAction(action)
  return next(action)
}

export const Sentry = Singleton
