import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Progress } from 'modules/types'

import { CollectionState, CompanyDadata, Product } from './types'

const initialState: CollectionState = {
  products: [],
  productsFetchProgress: Progress.SUCCESS,
  companies: [],
  companiesFetchProgress: Progress.SUCCESS,
}

class CollectionReducer extends ImmerReducer<CollectionState> {
  productsRequested() {
    this.draftState.productsFetchProgress = Progress.WORK
  }

  productsRequestSucceed(products: Product[]) {
    this.draftState.products = products
    this.draftState.productsFetchProgress = Progress.SUCCESS
  }

  productsRequestFailed() {
    this.draftState.productsFetchProgress = Progress.ERROR
  }

  companiesRequested(_query: string) {
    this.draftState.companies = []
    this.draftState.companiesFetchProgress = Progress.WORK
  }

  companiesRequestSucceed(companies: CompanyDadata[]) {
    this.draftState.companies = companies
    this.draftState.companiesFetchProgress = Progress.SUCCESS
  }

  companiesRequestFailed() {
    this.draftState.companiesFetchProgress = Progress.ERROR
  }

  companiesReset() {
    this.draftState.companies = []
  }
}

export const CollectionActions = createActionCreators(CollectionReducer)
export default CollectionActions
export const reducer = createReducerFunction(CollectionReducer, initialState)
