import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState, Progress } from '../../types'
import { UserAddress } from './types'

const dict = (state: AppGlobalState) => state.userAddress.items
const ids = (state: AppGlobalState) => state.userAddress.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): UserAddress | undefined => state.userAddress.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata =>
  state.userAddress.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const selectedId = (state: AppGlobalState) => state.userAddress.selectedAddressId
const itemFetchProgress = (state: AppGlobalState) => state.userAddress.itemFetchProgress
const itemUpdateProgress = (state: AppGlobalState, id: string) =>
  state.userAddress.meta[id]?.updateProgress || Progress.IDLE
const listFetchProgress = (state: AppGlobalState) => state.userAddress.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.userAddress.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.userAddress.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.userAddress.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: UserAddress[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.userAddress.filter
const sorting = (state: AppGlobalState) => state.userAddress.sorting
const page = (state: AppGlobalState) => state.userAddress.page
const total = (state: AppGlobalState) => state.userAddress.total
const updateProgress = (state: AppGlobalState) => state.userAddress.updateProgress
const removeProgress = (state: AppGlobalState) => state.userAddress.removeProgress
const addProgress = (state: AppGlobalState) => state.userAddress.addProgress
const hasNext = (state: AppGlobalState) => state.userAddress.total > state.userAddress.ids.length
const pageSize = (state: AppGlobalState) => state.userAddress.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.userAddress.total / state.userAddress.pageSize)

const geolocationStatus = (state: AppGlobalState) => state.userAddress.geolocationStatus
const detectedCoordinates = (state: AppGlobalState) => state.userAddress.detectedCoordinates
const detectedRegionName = (state: AppGlobalState) => state.userAddress.detectedRegionName

const UserAddressSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  selectedId,
  itemFetchProgress,
  itemUpdateProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  geolocationStatus,
  detectedCoordinates,
  detectedRegionName,
}

export default UserAddressSelectors
