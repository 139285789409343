import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import styled from 'styled-components'

import AuthSelectors from 'modules/domain/auth/selectors'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import ProfileAddressPage from 'views/pages/Profile/ProfileAddressPage/ProfileAddressPage'
import ProfileBidsPage from 'views/pages/Profile/ProfileBidsPage/ProfileBidsPage'
import ProfileDealsPage from 'views/pages/Profile/ProfileDealsPage/ProfileDealsPage'
import { Routes as AllRoutes } from 'views/pages/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'

import AccountInfo from './AccountInfo/loadable'
import Menu from './Menu'
import Routes from './routes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: start;
  grid-gap: 24px;

  ${(props) => props.theme.media.smallTablet`
    grid-template-columns: auto;
  `}

  ${(props) => props.theme.media.mobile`
    grid-template-columns: auto;
    grid-gap: 0;
  `}
`

const ContentWrapper = styled.div`
  margin-bottom: 48px;
  ${(props) => props.theme.media.mobile`
    border-top: 1px solid ${(props) => props.theme.color.secondary50};
  `}
`

const ProfilePage = () => {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const location = useLocation()

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: AllRoutes.SignIn, state: { from: location.pathname } }} />
  }

  return (
    <Article>
      <Container>
        <Breadcrumbs />
        <Wrapper>
          <Menu />
          <ContentWrapper>
            <Switch>
              <Route path={Routes.ProfileBids}>
                <ProfileBidsPage />
              </Route>
              <Route path={Routes.ProfileInfo}>
                <AccountInfo />
              </Route>
              <Route path={Routes.ProfileDeals}>
                <ProfileDealsPage />
              </Route>
              <Route path={Routes.ProfileAddresses}>
                <ProfileAddressPage />
              </Route>
              <Redirect to={Routes.ProfileBids} />
            </Switch>
          </ContentWrapper>
        </Wrapper>
      </Container>
    </Article>
  )
}

export default ProfilePage
