import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Checkbox, CountryPhoneInput, Input, useDidMount } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { PERSONAL_DATA_LINK, USER_AGREEMENT_LINK } from 'modules/constants'
import { AnalyticsEvent, Progress } from 'modules/types'
import { AvailableCountries } from 'types/entities'
import { getCountryCodes, getDefaultCountryCode } from 'views/components/LoginForm/countryCodes'

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.secondary200};
  padding: 6px 0;
`

const BodyInner = styled.form`
  max-width: 420px;
  ${(props) => props.theme.media.mobile`
    max-width: 100%;
  `}
`

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  align-items: start;
`

const Terms = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.secondary200};

  a {
    color: ${(props) => props.theme.color.primary500};
  }
`

const ButtonContainer = styled.div`
  position: relative;
  margin-top: 24px;
  width: 180px;

  ${(props) => props.theme.media.mobile`
    margin-top: 20px;
    width: 100%;
  `}

  & > * {
    width: 100%;
  }
`

const ErrorText = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.color.accentDestructive};
  white-space: nowrap;
`

type AccountFormProps = {
  mode: 'phone' | 'email'
  country: AvailableCountries
  email: string
  phone?: string
}

const AccountForm: React.FC<{
  initialValues: AccountFormProps
  onSubmit(props: AccountFormProps): void
  progress?: Progress
}> = ({ initialValues, onSubmit, progress }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation(['auth', 'common', 'components'])
  const [dataPolicyAgree, setDataPolicyAgree] = useState<boolean>(false)

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'signin_phone_screen' })
  })

  const formik = useFormik<AccountFormProps>({
    initialValues,
    onSubmit,
  })

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      track('phone_next_tap')
      formik.submitForm()
    },
    [track, formik],
  )

  const setPhoneMode = () => formik.values.mode !== 'phone' && formik.setFieldValue('mode', 'phone')

  const personalData = useMemo(
    () => `
      <a id="phone_personal_data_tap" href="${PERSONAL_DATA_LINK}" target="_blank" rel="noopener noreferrer">
        ${t('components:AuthForm.personalData')}
      </a>
    `,
    [t],
  )

  const userAgreement = useMemo(
    () => `
      <a id="phone_agreement_tap" href="${USER_AGREEMENT_LINK}" target="_blank" rel="noopener noreferrer">
        ${t('components:AuthForm.userAgreement')}
      </a>
    `,
    [t],
  )

  return (
    <BodyInner onSubmit={handleSubmit} data-test-id="account-form" id="account-form">
      <Label onClick={setPhoneMode}>{t('components:AuthForm.loginByPhone')}</Label>
      {formik.values.mode === 'phone' ? (
        <CountryPhoneInput
          id="phone-input"
          name="phone-input"
          countryOptions={getCountryCodes()}
          phoneNumber={formik.values.phone}
          defaultCountryCode={getDefaultCountryCode()}
          onBlur={() => {
            formik.setTouched({ phone: true })
          }}
          onChange={(number) => {
            formik.setFieldValue('phone', number)
          }}
        />
      ) : (
        <Input {...formik.getFieldProps('email')} type="email" />
      )}
      <TermsWrapper>
        <Checkbox
          isChecked={dataPolicyAgree}
          onChange={(_value, isChecked) => {
            setDataPolicyAgree(isChecked)
          }}
        />
        <Terms
          dangerouslySetInnerHTML={{
            __html: t('components:AuthForm.terms', { personalData, userAgreement }),
          }}
          onClick={(e) => {
            const { id } = e.target as any
            id && track(id)
          }}
        />
      </TermsWrapper>
      <ButtonContainer>
        {progress === Progress.ERROR ? (
          <ErrorText data-test-id="error">{t('common:errors.unknownError')}</ErrorText>
        ) : null}
        <Button
          disabled={!dataPolicyAgree}
          id="account-form-next-button"
          intent="primary"
          filled
          type="submit"
          size="big"
          progress={progress}
        >
          {t('common:next')}
        </Button>
      </ButtonContainer>
    </BodyInner>
  )
}

export default AccountForm
