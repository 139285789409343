import React, { VFC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import { helpersUrl } from '@agro-club/agroclub-shared'

import { BidType } from 'modules/domain/bid/types'
import { useProductListManaged } from 'modules/domain/product/hooks'
import { Progress } from 'modules/types'
import { CategoryCard, CategoryCardSkeleton } from 'views/components/CategoryCard/CategoryCard'
import BidsRoutes from 'views/pages/Bids/routes'
import { CategoryGroupListWrapper } from 'views/pages/Main/styled'

const Skeletons: VFC = () => (
  <>
    {new Array(6).fill(0).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <CategoryCardSkeleton key={i} />
    ))}
  </>
)

type Props = {
  bidType: BidType
}

export const CategoryList: VFC<Props> = ({ bidType }) => {
  const [progress, products = []] = useProductListManaged()
  const { t } = useTranslation()

  const productsWithBids = useMemo(
    () => products.filter((p) => (bidType === BidType.sale ? p.sale_bids_count : p.purchase_bids_count)),
    [products, bidType],
  )

  const currentProducts = productsWithBids.length ? productsWithBids : products

  return (
    <CategoryGroupListWrapper>
      {progress === Progress.WORK ? (
        <Skeletons />
      ) : (
        currentProducts.map((item) => (
          <CategoryCard
            key={item.id}
            to={helpersUrl.stringifyUrl({
              url: generatePath(BidsRoutes.Bids, { product: item.slug }),
              query: { bid_type: bidType },
            })}
            subTitle={t('common:bidCount', {
              count: bidType === BidType.sale ? item.sale_bids_count : item.purchase_bids_count,
            })}
            title={item.title}
            kind={item.slug}
          />
        ))
      )}
    </CategoryGroupListWrapper>
  )
}
