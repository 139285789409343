import Ajv, { JSONSchemaType } from 'ajv'
import { clone } from 'ramda'

const ajv = new Ajv({ coerceTypes: true, removeAdditional: true, useDefaults: true })

type SchemaType<T extends JSONSchemaType<any>> = T extends JSONSchemaType<infer Q> ? Partial<Q> : never

export function newQueryValidator<T extends JSONSchemaType<any>, Q extends SchemaType<T>>(
  schema: T,
): (value: unknown) => { valid: boolean; query: Q } {
  const validate = ajv.compile(schema)
  return (value: unknown) => {
    const query = clone(value) as Q
    const valid = validate(query)
    return {
      query,
      valid,
    }
  }
}
