import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Map, Placemark, YMaps, YMapsApi } from 'react-yandex-maps'

import { AsyncSimpleSelect, Progress } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { Map as YMapInterface } from 'yandex-maps'

import useAddress, { Coordinates } from './useAddress'

const ItemWrapper = styled.div`
  width: 100%;
`

const InputWrapper = styled.div`
  width: 100%;
`

const MapContainer = styled.div`
  height: 240px;
`

const Coords = styled.div`
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const defaultCenter: Coordinates = [55.751574, 37.573856] // Moscow

type AddressFormProps = {
  readonly?: boolean
  invalid?: boolean
  errorText?: string
  initialCoords?: Coordinates
  initialSearch?: string
  focusOnMount?: boolean
  apiKey: string
  onChange?: (v: { coords: Coordinates | null; address: string; geoObject: any }) => void
  label?: string
  placeholder?: string
  noOptionsMessage?: () => string | null
}

type Value = { value: string; label: string }

const AddressForm: React.FC<AddressFormProps> = ({
  readonly,
  focusOnMount,
  initialCoords,
  initialSearch,
  onChange,
  invalid,
  errorText,
  apiKey,
  label,
  placeholder,
  noOptionsMessage,
}) => {
  const firstInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (firstInputRef.current && focusOnMount) {
      firstInputRef.current.focus()
    }
  }, [focusOnMount])
  const [mapInstance, setMapInstance] = useState<YMapInterface>()
  const setMapRef = useCallback((ref: YMapInterface) => {
    setMapInstance(ref)
  }, [])

  const { coords, pinCoords, zoom, progress, search, onInputChange, onMapCoordsChange, onMapZoomChange } =
    useAddress({
      initialCoords,
      initialSearch,
      onChange,
      apiKey,
    })

  const [ymapsApi, setYmapsApi] = useState<YMapsApi>()

  useEffect(() => {
    if (!mapInstance || readonly) {
      return
    }
    const clickHandler = (e) => {
      const coords: Coordinates = e.get('coords')
      onMapCoordsChange(coords)
    }

    const boundsHandler = (e) => {
      if (e.get('newZoom') !== e.get('oldZoom')) {
        onMapZoomChange(e.get('newZoom'))
      }
    }

    mapInstance.events.add('click', clickHandler)
    mapInstance.events.add('boundschange', boundsHandler)
    return () => {
      mapInstance.events.remove('click', clickHandler)
      mapInstance.events.remove('boundschange', boundsHandler)
    }
  }, [mapInstance, onMapCoordsChange, onMapZoomChange, readonly])

  const value = React.useMemo(() => (search ? { label: search, value: search } : undefined), [search])

  const mapBehaviors = useMemo(() => (readonly ? [] : ['default']), [readonly])

  return (
    <ItemWrapper>
      <InputWrapper>
        <AsyncSimpleSelect
          isDisabled={readonly}
          invalid={invalid}
          errorText={errorText}
          progress={progress === Progress.IDLE ? Progress.SUCCESS : progress}
          label={label}
          placeholder={placeholder}
          noOptionsMessage={noOptionsMessage}
          instanceId="address-select"
          value={value}
          defaultOptions={search ? [value as Value] : undefined}
          onChange={(v: unknown) => {
            const value = (v as Value)?.value
            onInputChange(value)
          }}
          loadOptions={(query, callback) => {
            ymapsApi?.suggest(query, { results: 10 }).then((res) => {
              callback(res.map((r) => ({ value: r.value, label: r.value })))
            })
          }}
        />
      </InputWrapper>
      <Coords>{coords?.join(', ')}</Coords>
      <MapContainer>
        <YMaps
          query={{
            lang: 'ru_RU',
            apikey: apiKey,
          }}
        >
          <Map
            onLoad={(ymaps) => setYmapsApi(ymaps)}
            defaultState={{ center: initialCoords || defaultCenter, zoom: 9 }}
            state={{ center: coords || initialCoords || defaultCenter, zoom, behaviors: mapBehaviors }}
            width="100%"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            instanceRef={setMapRef}
            modules={['suggest']}
          >
            {pinCoords ? (
              <Placemark geometry={pinCoords} options={{ preset: 'islands#redFactoryCircleIcon' }} />
            ) : null}
          </Map>
        </YMaps>
      </MapContainer>
    </ItemWrapper>
  )
}

export default AddressForm
