import React from 'react'

import { Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const CatinerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: stretch;
  flex-grow: 1;
  padding: 100px 0;
`

export const SpinnerLayout: React.FC = () => (
  <CatinerWrapper>
    <Spinner />
  </CatinerWrapper>
)

export default SpinnerLayout
