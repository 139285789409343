import { useCallback } from 'react'

import { isNil } from 'ramda'

import useLanguage from 'hooks/useLanguage'

export default function useFormatNumber() {
  const locale = useLanguage()

  return useCallback(
    (value?: number | string | null, isPrice = false) => {
      if (isNil(value)) {
        return ''
      }
      if (typeof value === 'string' && Number.isNaN(Number(value))) {
        return value
      }
      const options = isPrice ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : undefined
      return Number(value).toLocaleString(locale, options)
    },
    [locale],
  )
}
