import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'

import {
  Button,
  IconButton,
  IconLoader,
  IconLocation,
  IconQuestion,
  SectionBody,
  SectionContainer,
  Spinner,
  Tooltip,
  useAction,
  useOnClickOutside,
} from '@agro-club/agroclub-shared'
import styled, { keyframes } from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import AuthSelectors from 'modules/domain/auth/selectors'
import BidActions from 'modules/domain/bid/duck'
import { useBidItemManaged, useBidPriceManaged } from 'modules/domain/bid/hooks'
import { Address } from 'modules/domain/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserGeolocationStatus } from 'modules/domain/userAddress/types'
import { Progress } from 'modules/types'
import { CurrentUserAddressSelect } from 'views/components/CurrentUserAddressSelect/CurrentUserAddressSelect'
import Link from 'views/components/Link/Link'
import BidOfferRoutes from 'views/pages/BidOffer/routes'
import ProfileRoutes from 'views/pages/Profile/routes'
import SignInRoutes from 'views/pages/SignIn/routes'

const WidgetText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
`

const BidWidgetButton = styled(Button)`
  font-weight: 600;
  width: 100%;
`
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const PriceTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
const PriceValue = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
`

const PriceDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const Spacer = styled.div<{ $height: number }>`
  width: 100%;
  height: ${(props) => `${props.$height || 0}px`};
`

const BidPrice: React.FC<{ priceType: 'cpt' | 'exw'; progress?: Progress; value?: string | number }> = ({
  priceType,
  progress,
  value,
}) => {
  const { t } = useTranslation('BidWidget')
  const priceTitle = t(`priceTitle.${priceType}`)
  const priceDescription = t(`priceDescription.${priceType}`)
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  if (!progress || progress === Progress.ERROR) {
    return null
  }

  if (progress !== Progress.SUCCESS) {
    return (
      <PriceWrapper>
        <TitleContainer>
          <PriceTitle>{priceTitle}</PriceTitle>
          <PriceValue>
            <Spinner size="small" />
          </PriceValue>
        </TitleContainer>
        <PriceDescription>{priceDescription}</PriceDescription>
      </PriceWrapper>
    )
  }

  return (
    <PriceWrapper>
      <TitleContainer>
        <PriceTitle>{priceTitle}</PriceTitle>
        <PriceValue>
          {formatNumber(value, true)} {t('priceUnit', { currency })}
        </PriceValue>
      </TitleContainer>
      <PriceDescription>{priceDescription}</PriceDescription>
    </PriceWrapper>
  )
}

const UserGeolocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const UserGeolocationBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const HintTooltipInner = styled.div`
  min-width: 280px;
`

const HintIcon = styled(IconQuestion)`
  margin-left: 8px;
  cursor: pointer;
  &[data-status='declined'],
  &[data-status='error'] {
    fill: ${(props) => props.theme.color.accentNotify};
  }
`

const IconLocationStyled = styled(IconLocation)`
  height: 12px;
  width: 12px;
  margin-right: 8px;
  flex-shrink: 0;
`

const DetectedRegionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const IconLoaderStyled = styled(IconLoader)`
  height: 24px;
  width: 24px;
  animation: ${rotateAnimation} 1s linear infinite;
`

const IconButtonStyled = styled(IconButton)`
  flex-grow: 1;
`

const UserGeolocationHint: React.FC<{ status: UserGeolocationStatus }> = ({ children, status }) => {
  const contentRef = useRef(null)
  const openerRef = useRef(null)
  const initialStatus = status === 'error' || status === 'declined'
  const [visible, setVisible] = useState(initialStatus)
  useOnClickOutside(contentRef, () => setVisible(false), openerRef)
  return (
    <Tooltip
      visible={visible}
      tooltipContent={<HintTooltipInner ref={contentRef}>{children}</HintTooltipInner>}
      timeout={5000}
      position="left"
      visibleOnHover={false}
    >
      <div onClick={() => setVisible(!visible)} ref={openerRef}>
        <HintIcon data-status={status} />
      </div>
    </Tooltip>
  )
}

const UserGeolocation: React.FC = () => {
  const { t } = useTranslation('BidWidget')
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const addressList = useSelector(UserAddressSelectors.list)
  const haveAddress = addressList.length !== 0
  const status = useSelector(UserAddressSelectors.geolocationStatus)
  const detectedRegion = useSelector(UserAddressSelectors.detectedRegionName)

  const detectRegionAction = useAction(UserAddressActions.coordinatesRequested)

  const handleRequestRegionData = useCallback(() => {
    if (status !== 'work') {
      detectRegionAction()
    }
  }, [detectRegionAction, status])

  switch (status) {
    case 'work':
      return (
        <UserGeolocationWrapper>
          <UserGeolocationBody>
            <IconButtonStyled Icon={IconLoaderStyled}>{t('detectRegionInProgress')}</IconButtonStyled>
          </UserGeolocationBody>
        </UserGeolocationWrapper>
      )
    case 'declined':
      return (
        <UserGeolocationWrapper>
          <UserGeolocationBody>
            <IconButtonStyled onClick={handleRequestRegionData} Icon={IconLocation}>
              {t('detectRegionButton')}
            </IconButtonStyled>
          </UserGeolocationBody>
          <UserGeolocationHint status={status}>
            <div>{t('detectRegionPermissionRequiredText')}</div>
          </UserGeolocationHint>
        </UserGeolocationWrapper>
      )
    case 'error':
      return (
        <UserGeolocationWrapper>
          <UserGeolocationBody>
            <IconButtonStyled onClick={handleRequestRegionData} Icon={IconLocation}>
              {t('detectRegionButton')}
            </IconButtonStyled>
          </UserGeolocationBody>
          <UserGeolocationHint status={status}>
            <div>{t('detectRegionErrorText')}</div>
          </UserGeolocationHint>
        </UserGeolocationWrapper>
      )
    case 'idle':
      return (
        <UserGeolocationWrapper>
          <UserGeolocationBody>
            <IconButtonStyled onClick={handleRequestRegionData} Icon={IconLocation}>
              {t('detectRegionButton')}
            </IconButtonStyled>
          </UserGeolocationBody>
          <UserGeolocationHint status={status}>
            {t('detectRegionIdleText_1')} <Link to={SignInRoutes.SignIn}>{t('detectRegionIdle_1_link')}</Link>{' '}
            {t('detectRegionIdle_2')}
          </UserGeolocationHint>
        </UserGeolocationWrapper>
      )
  }

  let hintText: React.ReactNode = null
  if (!isAuthenticated) {
    hintText = (
      <>
        {t('detectRegionUnauthorizedText_1')}{' '}
        <Link to={SignInRoutes.SignIn}>{t('detectRegionUnauthorizedText_1_link')}</Link>{' '}
        {t('detectRegionUnauthorizedText_2')}
      </>
    )
  } else if (!haveAddress) {
    hintText = (
      <>
        {t('detectRegionEmptyAddressText_1')}{' '}
        <Link to={ProfileRoutes.ProfileAddresses}>{t('detectRegionEmptyAddressText_1_link')}</Link>
      </>
    )
  }

  return (
    <UserGeolocationWrapper>
      <UserGeolocationBody>
        <DetectedRegionWrapper>
          <IconLocationStyled /> {detectedRegion}
        </DetectedRegionWrapper>
      </UserGeolocationBody>
      <UserGeolocationHint status={status}>{hintText}</UserGeolocationHint>
    </UserGeolocationWrapper>
  )
}

const BidWidgetAddress: React.FC<{
  onAddressChange?: (addr: Address) => void
}> = ({ onAddressChange }) => {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const addressList = useSelector(UserAddressSelectors.list)

  if (isAuthenticated && addressList.length) {
    return <CurrentUserAddressSelect onChange={onAddressChange} />
  }

  return <UserGeolocation />
}

const BidWidgetAuthenticated: React.FC<{ bidId?: string }> = ({ bidId }) => {
  const { t } = useTranslation('BidWidget')
  const { track } = useAnalyticsSSR()
  const fetchPriceAction = useAction(BidActions.itemPriceRequested)
  const addressId = useSelector(UserAddressSelectors.selectedId)
  const [progress, price] = useBidPriceManaged(bidId, addressId)
  const [, item] = useBidItemManaged(bidId)

  const handleAddressChange = (addr: Address) => {
    fetchPriceAction(bidId, addr.id)
  }

  const renderCpt = (value?: number) => {
    if (progress === Progress.ERROR) {
      return null
    }
    return (
      <>
        <Spacer $height={16} />
        <BidPrice priceType="cpt" value={value} progress={progress} />
      </>
    )
  }

  const renderExw = (value?: number) => {
    if (progress === Progress.ERROR) {
      return null
    }
    return (
      <>
        <BidPrice priceType="exw" value={value} progress={progress} />
        <Spacer $height={8} />
      </>
    )
  }

  return (
    <SectionContainer noDivider>
      <SectionBody noGrid paddedOnMobile>
        {renderExw(price?.price_exw)}
        <BidWidgetAddress onAddressChange={handleAddressChange} />
        {renderCpt(price?.price_cpt)}
        <Spacer $height={24} />
        <Link
          to={generatePath(BidOfferRoutes.BidItemOffer, bidId ? { id: bidId } : undefined)}
          onClick={() =>
            track('harvest_prebid_volume_tap', { product_name: item?.product?.title, id: bidId })
          }
        >
          <BidWidgetButton intent="primary" filled size="big">
            {t('offerButton')}
          </BidWidgetButton>
        </Link>
      </SectionBody>
    </SectionContainer>
  )
}

export const BidWidget: React.FC<{ bidId?: string }> = ({ bidId }) => {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const { t } = useTranslation('BidWidget')

  if (!isAuthenticated) {
    return (
      <SectionContainer noDivider>
        <SectionBody noGrid paddedOnMobile>
          <WidgetText>{t('unauthenticatedDescription')}</WidgetText>
          <Link to={generatePath(SignInRoutes.SignIn)}>
            <BidWidgetButton intent="primary" filled size="big">
              {t('unauthenticatedButtonText')}
            </BidWidgetButton>
          </Link>
        </SectionBody>
      </SectionContainer>
    )
  }

  return <BidWidgetAuthenticated bidId={bidId} />
}
