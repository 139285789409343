import { helpersColor } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import Link from 'views/components/Link/Link'

const CardLink = styled(Link)<{ $minHeight?: number; $border?: boolean }>`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  background-color: ${(props) => props.theme.color.onPrimaryLight};
  min-height: ${(props) => `${props.$minHeight ?? 128}px`};
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.1s ease-in, background 0.1s ease-in;
  border: 1px solid ${(props) => (props.$border ? props.theme.color.onSurfaceLowEmphasys : 'transparent')};

  &:hover {
    background: ${(props) => helpersColor.alphaColor(props.theme.color.primary600, 0.04)};
    border: 1px solid ${(props) => helpersColor.alphaColor(props.theme.color.primary600, 0.48)};
  }
`

export default CardLink
