import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthSelectors from 'modules/domain/auth/selectors'
import Link from 'views/components/Link/Link'

const Wrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-gap: 24px;
  justify-items: flex-start;
`

const MessageText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`

const SignUpFinalStep: React.FC = () => {
  const { t } = useTranslation('SignUpFinalStep')
  const { track } = useAnalyticsSSR()
  const profile = useSelector(AuthSelectors.profile)

  useDidMount(() => {
    track('signup_success_screen')
    track('signup_success')
  })

  return (
    <Wrapper>
      <MessageText>
        <div>{t('messageText1', { firstName: profile?.profile?.first_name || '' })}</div>
        <div>{t('messageText2')}</div>
      </MessageText>
      <Link to="/">
        <Button intent="primary" filled size="big" onClick={() => track('signup_start_tap')}>
          {t('buttonText')}
        </Button>
      </Link>
    </Wrapper>
  )
}

export default SignUpFinalStep
