import React from 'react'
import { Route, Switch } from 'react-router-dom'

import BidOffer from 'views/pages/BidOffer/BidOffer'
import { Success } from 'views/pages/BidOffer/Success'
import { Routes } from 'views/pages/routes'

const BidOfferPage: React.FC = () => (
  <Switch>
    <Route path={Routes.BidItemOffer} exact>
      <BidOffer />
    </Route>
    <Route path={Routes.BidItemOfferSuccess} exact>
      <Success />
    </Route>
  </Switch>
)

export default BidOfferPage
