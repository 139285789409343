import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { ListResponse } from 'types/api'

import { Deal, DealDTO, DealListRequestFilter, DealListRequestSorting } from './types'

export const getList =
  (apiClient: ApiService) =>
  async (filter: DealListRequestFilter, sorting: DealListRequestSorting, page: number, pageSize: number) =>
    apiClient.get<ListResponse<Deal>>(endpoints.deal(), {
      ...filter,
      ...sorting,
      page,
      page_size: pageSize,
    })

export const getItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.get<Deal>(endpoints.deal(id))

export const addItem = (apiClient: ApiService) => async (dto: DealDTO) =>
  apiClient.post<Deal>(endpoints.deal(), dto)

export const updateItem = (apiClient: ApiService) => async (id: string, dto: Partial<DealDTO>) =>
  apiClient.put<Deal>(endpoints.deal(id), dto)

export const removeItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.delete<Deal>(endpoints.deal(id))
