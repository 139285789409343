import React, { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, StepForm, StepFormStep, useAction, useFormManager } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { noop } from 'helpers/noop'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useHelmet from 'hooks/useHelmet'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import AboutForm, { AboutFormProps } from 'views/components/AboutForm/AboutForm'
import CompanyForm, { CompanyDetailsFormProps } from 'views/components/CompanyForm/CompanyForm'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import SignUpAddressForm, { AddressFormProps } from 'views/pages/SignUp/SignUpAddressForm'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'

import SignUpFinalStep from './SignUpFinalStep'

const Wrapper = styled.div``
const ButtonContainer = styled.div`
  margin-top: 24px;
`

const SignUpPage = () => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('SignUpPage')
  const step = useSelector(AuthSelectors.signUpStep)
  useHelmet({ title: t('metaTitle') })

  const aboutFormManager = useFormManager<{ main: AboutFormProps }>()
  const updateProfileInfoAction = useAction(AuthActions.profileInfoUpdateRequested)
  const profileInfoProgress = useSelector(AuthSelectors.profileInfoUpdateProgress)

  const updateCompanyInfoAction = useAction(AuthActions.profileCompanyUpdateRequested)
  const companyInfoProgress = useSelector(AuthSelectors.profileCompanyUpdateProgress)

  const updateAddressAction = useAction(AuthActions.profileAddressUpdateRequested)
  const addressProgress = useSelector(AuthSelectors.profileAddressUpdateProgress)

  const addressFormManager = useFormManager<{ main: AddressFormProps }>()

  useNotificationProgress(profileInfoProgress)
  useNotificationProgress(companyInfoProgress)
  useNotificationProgress(addressProgress)

  const submitAddressForm = async (e: FormEvent) => {
    e.preventDefault()
    const [valid, form] = await addressFormManager.submitAll()
    if (!valid) {
      return
    }

    track('signup_address_next')

    const { address, latitude, longitude, geo_object } = form.main

    updateAddressAction({
      address,
      latitude,
      longitude,
      geo_object,
      is_main: true,
    })
  }

  const submitAboutForm = async (e: FormEvent) => {
    e.preventDefault()
    const [valid, form] = await aboutFormManager.submitAll()
    if (!valid) {
      return
    }

    track('signup_role_choose_tap')

    updateProfileInfoAction({
      first_name: form.main.firstName,
      last_name: form.main.lastName,
      patronymic_name: form.main.middleName,
      profile_type: form.main.userType,
    })
  }
  const companyFormManager = useFormManager<{ main: CompanyDetailsFormProps }>()

  const submitCompanyForm = async (e: FormEvent) => {
    e.preventDefault()
    const [valid, form] = await companyFormManager.submitAll()
    if (!valid) {
      return
    }

    track('signup_company_next')

    updateCompanyInfoAction({
      inn: form.main.inn,
      prepayment: form.main.prepayment,
      vat: form.main.vat,
      name: form.main.companyName,
      payment_delay_days: form.main.delay || 0,
      state_reg_number: form.main.state_reg_number,
    })
  }

  return (
    <Article>
      <Breadcrumbs />
      <Wrapper>
        <StepForm
          step={step}
          onTabClick={noop}
          completed={step === 'complete'}
          headerSize="small"
          lockOnLastStep
          completeContent={<SignUpFinalStep />}
        >
          <StepFormStep id="profile" title={t('aboutStepTitle')} checkIcon={false}>
            {/* eslint-disable react/jsx-no-bind */}
            <AboutForm focusOnMount useFormik={aboutFormManager.bind('main')} />
            {/* eslint-enable react/jsx-no-bind */}
            <ButtonContainer>
              <Button
                intent="primary"
                size="big"
                filled
                progress={profileInfoProgress}
                onClick={submitAboutForm}
              >
                {t('common:proceed')}
              </Button>
            </ButtonContainer>
          </StepFormStep>
          <StepFormStep id="company" title={t('companyStepTitle')} checkIcon={false}>
            {/* eslint-disable react/jsx-no-bind */}
            <CompanyForm focusOnMount useFormik={companyFormManager.bind('main')} />
            {/* eslint-enable react/jsx-no-bind */}
            <ButtonContainer>
              <Button
                intent="primary"
                size="big"
                filled
                progress={companyInfoProgress}
                onClick={submitCompanyForm}
              >
                {t('common:proceed')}
              </Button>
            </ButtonContainer>
          </StepFormStep>
          <StepFormStep id="address" title={t('addressStepTitle')} checkIcon={false}>
            {/* eslint-disable react/jsx-no-bind */}
            <SignUpAddressForm useFormik={addressFormManager.bind('main')} />
            {/* eslint-enable react/jsx-no-bind */}
            <ButtonContainer>
              <Button
                progress={addressProgress}
                intent="primary"
                size="big"
                filled
                onClick={submitAddressForm}
              >
                {t('common:proceed')}
              </Button>
            </ButtonContainer>
          </StepFormStep>
        </StepForm>
      </Wrapper>
    </Article>
  )
}

export default SignUpPage
