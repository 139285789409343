import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  Checkbox,
  PopoverWithBtns,
  SectionBody,
  SectionContainer,
  SectionTitle,
  Spinner,
  useAction,
  useHistoryPush,
} from '@agro-club/agroclub-shared'

import env from 'env'
import useHelmet from 'hooks/useHelmet'
import usePrevious from 'hooks/usePrevious'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { useUserAddressItemManaged } from 'modules/domain/userAddress/hooks'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { Progress } from 'modules/types'
import AddressForm from 'views/components/AddressForm/AddressForm'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'
import { DeleteIconButton } from 'views/ui/DeleteIconButton/DeleteIconButton'

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const ProfileAddressItem: React.FC = () => {
  const params = useParams<{ id: string }>()
  const { t } = useTranslation('ProfileAddressItem')
  const [, item] = useUserAddressItemManaged(params.id)
  const push = useHistoryPush()
  const removeRequested = useAction(UserAddressActions.removeRequested)
  const removeProgress = useSelector(UserAddressSelectors.removeProgress)
  const prevProgress = usePrevious(removeProgress)

  useHelmet({ title: t('title') })

  const goBack = () => push({ route: ProfileRoutes.ProfileAddresses })

  const remove = () => {
    removeRequested(params.id)
  }

  useEffect(() => {
    if (removeProgress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      goBack()
    }
  }, [removeProgress, prevProgress])

  if (!item) {
    return null
  }

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>
          {t('title')}
          <PopoverWithBtns heading={t('dialog')} text="" onSubmit={remove} position="below">
            <DeleteIconButton />
          </PopoverWithBtns>
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody>
        {!item ? (
          <Spinner />
        ) : (
          <>
            <Checkbox disabled isChecked={item.is_main} onChange={noop} label={t('isMain')} />
            <AddressForm
              readonly
              initialSearch={item.address}
              initialCoords={[item.latitude, item.longitude]}
              placeholder={t('AddressForm:addressPlaceholder')}
              noOptionsMessage={() => t('AddressForm:noOptionMessage')}
              apiKey={env.REACT_APP_YA_MAPS_API_KEY}
              focusOnMount
            />
          </>
        )}
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileAddressItem
