import { AvailableLanguages } from 'types/entities'

const getEnv = (key: keyof typeof window.__env) => {
  if (process.browser) {
    const envElement = window.__env[key]
    return envElement
  }
  const v = process.env[key]
  return v?.replace(/["']*([^"']*)["']*/, '$1') ?? v
}

const API_BASE_URL = getEnv('API_BASE_URL') || ''
const REDUX_LOGGER = getEnv('REDUX_LOGGER')
const BASE_ROUTER_PATH = getEnv('BASE_ROUTER_PATH') || '/'
const REDUX_LOGGER_SERVER_VERBOSE = getEnv('REDUX_LOGGER_SERVER_VERBOSE') === 'true'
const SENTRY_DSN = getEnv('SENTRY_DSN') || null
const DEVELOPMENT = getEnv('NODE_ENV') === 'development'
const PUBLIC_URL = getEnv('PUBLIC_URL') || ''
const SEGMENT_WRITE_KEY = getEnv('SEGMENT_WRITE_KEY')
const REACT_APP_YA_MAPS_API_KEY = getEnv('REACT_APP_YA_MAPS_API_KEY') || ''
const BROWSER = typeof process === 'undefined' || process.browser
const BUILD_VERSION = getEnv('BUILD_VERSION')
const REACT_APP_COMPANY_NAME_INPUT = process.browser
  ? getEnv('REACT_APP_COMPANY_NAME_INPUT')
  : getEnv('REACT_APP_COMPANY_NAME_INPUT') === 'true'
const SHOW_MOBILE_BANNER = process.browser
  ? getEnv('SHOW_MOBILE_BANNER')
  : getEnv('SHOW_MOBILE_BANNER') === 'true'
const REACT_APP_LOCALE = getEnv('REACT_APP_LOCALE') || AvailableLanguages.ru
const REACT_APP_CURRENCY_SYMBOL = getEnv('REACT_APP_CURRENCY_SYMBOL') || '₽'
const REACT_APP_SHOW_PATRONYMIC_NAME = process.browser
  ? getEnv('REACT_APP_SHOW_PATRONYMIC_NAME')
  : getEnv('REACT_APP_SHOW_PATRONYMIC_NAME') === 'true'
const REACT_APP_SHOW_VAT = process.browser
  ? getEnv('REACT_APP_SHOW_VAT')
  : getEnv('REACT_APP_SHOW_VAT') === 'true'
const REACT_APP_SHOW_PREPAYMENT = process.browser
  ? getEnv('REACT_APP_SHOW_PREPAYMENT')
  : getEnv('REACT_APP_SHOW_PREPAYMENT') === 'true'
const REACT_APP_SHOW_STATE_REG_NUMBER = process.browser
  ? getEnv('REACT_APP_SHOW_STATE_REG_NUMBER')
  : getEnv('REACT_APP_SHOW_STATE_REG_NUMBER') === 'true'

export default {
  API_BASE_URL,
  REDUX_LOGGER,
  REDUX_LOGGER_SERVER_VERBOSE,
  BASE_ROUTER_PATH,
  SENTRY_DSN,
  DEVELOPMENT,
  PUBLIC_URL,
  SEGMENT_WRITE_KEY,
  BROWSER,
  REACT_APP_YA_MAPS_API_KEY,
  BUILD_VERSION,
  REACT_APP_COMPANY_NAME_INPUT,
  REACT_APP_LOCALE,
  SHOW_MOBILE_BANNER,
  REACT_APP_CURRENCY_SYMBOL,
  REACT_APP_SHOW_PATRONYMIC_NAME,
  REACT_APP_SHOW_VAT,
  REACT_APP_SHOW_PREPAYMENT,
  REACT_APP_SHOW_STATE_REG_NUMBER,
}
