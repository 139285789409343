import { useEffect, useState } from 'react'

import env from 'env'
import useUserAgentDevice from 'hooks/useUserAgentDevice'
import { queries } from 'theme/theme'
import { DeviceType } from 'types/entities'

let useMatchMedia_: (media: string) => boolean

if (env.BROWSER) {
  useMatchMedia_ = function useMatchMedia(media: string): boolean {
    const [isMatches, setIsMatches] = useState(window.matchMedia(media).matches)
    useEffect(() => {
      let timeout: number
      const handleWindowResize = () => {
        clearTimeout(timeout)
        timeout = window.setTimeout(() => {
          setIsMatches(window.matchMedia(media).matches)
        }, 200)
      }
      window.addEventListener('resize', handleWindowResize)
      return () => {
        clearTimeout(timeout)
        window.removeEventListener('resize', handleWindowResize)
      }
    }, [media])
    return isMatches
  }
} else {
  useMatchMedia_ = function useMatchMedia(query: string): boolean {
    const { device } = useUserAgentDevice()
    if ((query === queries.tablet || query === queries.smallTablet) && device === DeviceType.Tablet) {
      return true
    }
    if (query === queries.mobile && device === 'mobile') {
      return true
    }
    if ((query === queries.desktop || query === queries.smallDesktop) && device === DeviceType.Desktop) {
      return true
    }
    return false
  }
}

const useMatchMedia = useMatchMedia_
export default useMatchMedia
