import { Dict } from 'types/generics'

export function arrToDict<T extends { id: string }>(arr: T[]): Dict<T> {
  const result: Dict<T> = {}
  for (const item of arr) {
    result[item.id] = item
  }
  return result
}

export function dictToArr<T extends { id: string }>(dict: { [index: string]: T }): T[] {
  return Object.values(dict)
}

export function getIds<T extends { id: string }>(arr: T[]): string[] {
  return arr.map((item) => item.id)
}
