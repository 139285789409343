import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMountWithProgress } from '@agro-club/agroclub-shared'

import { Progress, ResourceHook } from 'modules/types'

import BidActions from './duck'
import BidSelectors from './selectors'
import { Bid } from './types'

export const useBidsListManaged: ResourceHook<Bid[]> = () => {
  const progress = useSelector(BidSelectors.listFetchProgress)
  const list = useSelector(BidSelectors.list)
  return [progress, list]
}

export const useBidItemManaged: ResourceHook<Bid> = (id: string) => {
  const meta = useSelector((state) => BidSelectors.meta(state, id))
  const item = useSelector((state) => BidSelectors.item(state, id))
  return [meta.fetchProgress, item]
}

export const useBidPriceManaged = (id: string | undefined, addressId: string | null) => {
  if (!id) return [Progress.IDLE, null] as const

  const meta = useSelector((state) => BidSelectors.itemPriceMeta(state, id, addressId))
  const item = useSelector((state) => BidSelectors.itemPrice(state, id, addressId))

  return [meta.fetchProgress, item] as const
}

export const useBidItem: ResourceHook<Bid> = (id: string) => {
  const meta = useSelector((state) => BidSelectors.meta(state, id))
  const item = useSelector((state) => BidSelectors.item(state, id))
  const fetchAction = useAction(BidActions.itemRequested, id)

  const progress = useDidMountWithProgress(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, meta.fetchProgress)

  return [progress, item]
}

export const useBidItemById: ResourceHook<Bid> = (id: string) => {
  const meta = useSelector((state) => BidSelectors.meta(state, id))
  const item = useSelector((state) => BidSelectors.item(state, id))
  const fetchAction = useAction(BidActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
