import React, { VFC, useMemo } from 'react'

import { SimpleSelect, SimpleSelectProps } from '@agro-club/agroclub-shared'
import { CountryCode } from 'libphonenumber-js'

import { FlagStyled, OptionStyled } from 'views/ui/LanguageSelect/styled'

type Props = {
  codes: string[]
  value: CountryCode
} & Omit<SimpleSelectProps, 'options'>

export const LanguageSelect: VFC<Props> = ({ codes, value, ...rest }) => {
  const options = useMemo(
    () =>
      codes.map((code) => ({
        id: code,
        title: (
          <OptionStyled>
            <FlagStyled code={code as CountryCode} />
          </OptionStyled>
        ),
      })),
    [codes],
  )

  return <SimpleSelect options={options} value={value} {...rest} />
}
