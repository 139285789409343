import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { useTable } from 'react-table'

import {
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHead,
  TableHeadRow,
  TableNoData,
  TableSkeletonRow,
  useAction,
  usePersistentScroll,
} from '@agro-club/agroclub-shared'
import { pick } from 'ramda'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import BidActions from 'modules/domain/bid/duck'
import BidSelectors from 'modules/domain/bid/selectors'
import { Bid, BidListSortFields } from 'modules/domain/bid/types'
import { Progress } from 'modules/types'
import BidItemRoutes from 'views/pages/BidItem/routes'
import { useColumns, useIsPriceHidden } from 'views/pages/Bids/hooks'
import { ClearButton, Table, TableHeadCell } from 'views/pages/Bids/styled'

const Wrapper = styled.div`
  ${(props) => props.theme.media.tablet`
    display: none;
  `}
  ${(props) => props.theme.media.mobile`
    display: none;
  `}
`

export const BidsTable: React.FC<{
  items: Bid[]
  progress: Progress
  onFilterReset?(): void
}> = ({ items, progress, onFilterReset }) => {
  const { t } = useTranslation('BidsTable')
  const { track } = useAnalyticsSSR()
  const history = useHistory()
  const location = useLocation()

  const updateSorting = useAction(BidActions.sortingUpdated)
  const requestList = useAction(BidActions.listRequested)

  const isFilterApplied = useSelector(BidSelectors.isFilterApplied)
  const page = useSelector(BidSelectors.page)
  const pageSize = useSelector(BidSelectors.pageSize)
  const pages = useSelector(BidSelectors.pages)
  const total = useSelector(BidSelectors.total)
  const sorting = useSelector(BidSelectors.sorting)
  const { isCptHidden, isExwHidden } = useIsPriceHidden()

  const visibleColumns = useColumns()

  const allColumns = useMemo(() => [...visibleColumns], [visibleColumns])

  const { columns, rows, prepareRow } = useTable<Bid>({
    columns: allColumns,
    data: items,
    initialState: { hiddenColumns: [] },
  })

  const fetchNextItems = useCallback(
    (num) => {
      requestList({ page: num })
    },
    [requestList],
  )

  const { scrollRef } = usePersistentScroll('bidsList')

  useEffect(() => {
    if (isCptHidden && isExwHidden) {
      return
    }

    if (!sorting?.sort_field) {
      updateSorting({
        sort_field: isCptHidden ? 'price_exw' : 'price_cpt',
      })
    }
  }, [isCptHidden, isExwHidden])

  const renderRows = () =>
    rows.map((row, position) => {
      prepareRow(row)
      const { key, ...props } = row.getRowProps()
      const item = row.original
      return (
        <TableBodyRow
          key={key}
          {...props}
          selected={row.isSelected}
          onClick={() => {
            track('harvest_bids_bid_tap', { name: item.product.title, id: item.id, position })
            history.push({
              pathname: generatePath(BidItemRoutes.BidItem, { id: row.original.id.toString() }),
              state: { from: { ...pick(['pathname', 'search'], location) } },
            })
          }}
        >
          {row.cells.map((cell) => {
            const { key, ...props } = cell.getCellProps()
            if (cell.column.hidden) {
              return null
            }
            return (
              <TableBodyCell {...cell.column} key={key} {...props}>
                {cell.render('Cell')}
              </TableBodyCell>
            )
          })}
        </TableBodyRow>
      )
    })
  const renderColumns = () =>
    columns.map((column) => (
      <TableHeadCell
        {...column}
        key={column.getHeaderProps().key}
        id={column.id as BidListSortFields}
        sortField={sorting.sort_field}
        sortDesc={sorting.sort_reversed}
        onChange={updateSorting}
        transparent
      >
        {column.render('Header')}
      </TableHeadCell>
    ))

  const renderSkeletonRows = () =>
    // eslint-disable-next-line react/no-array-index-key
    new Array(10).fill(0).map((_, i) => <TableSkeletonRow key={i} colSpan={visibleColumns.length} />)

  const renderList = () => {
    if (progress === Progress.WORK) {
      return renderSkeletonRows()
    }
    return progress === Progress.SUCCESS ? renderRows() : null
  }

  return (
    <Wrapper>
      <Table
        total={total}
        pages={pages}
        pageSize={pageSize}
        currentPage={page}
        onSetPage={fetchNextItems}
        ref={scrollRef}
      >
        <TableHead>
          <TableHeadRow>{renderColumns()}</TableHeadRow>
        </TableHead>
        <TableBody>
          {renderList()}
          <TableNoData
            loading={<div />}
            progress={progress}
            isEmpty={!rows.length}
            colSpan={visibleColumns.length}
          >
            <div>{isFilterApplied ? t('nothingFoundByFilterError') : t('nothingFoundError')}</div>
            {isFilterApplied && progress !== Progress.WORK ? (
              <ClearButton onClick={onFilterReset} intent="clear">
                {t('resetFilterButtonText')}
              </ClearButton>
            ) : null}
          </TableNoData>
        </TableBody>
      </Table>
    </Wrapper>
  )
}
