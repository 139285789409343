import React from 'react'

import { Typography } from '@agro-club/agroclub-shared'
import styled, { StyledProps } from 'styled-components'

const Error = styled.div`
  ${Typography.caption1Default};
  color: ${({ theme }) => theme.color.accentDestruct800};
  margin-top: 4px;
`

const InputWrapper = styled.div<StyledProps<{ $width?: number }>>`
  overflow: visible;
  width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
`

export type Props = {
  width?: number
  invalid?: boolean
  errorText?: string
}

export const ErrorInputWrapper: React.FC<Props> = (props) => {
  const { invalid, errorText, width } = props
  return (
    <div>
      <InputWrapper $width={width}>{props.children}</InputWrapper>
      {invalid && !!errorText && <Error>{errorText}</Error>}
    </div>
  )
}
