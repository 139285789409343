import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import {
  UserAddress,
  UserAddressDTO,
  UserAddressListRequestFilter,
  UserAddressListRequestSorting,
  UserAddressState,
  UserGeolocationStatus,
} from './types'

const initialState: UserAddressState = {
  items: {},
  meta: {},
  ids: [],
  selectedAddressId: null,
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  filter: {},
  sorting: {},
  page: 1,
  total: 0,
  pageSize: 10000,

  detectedCoordinates: null,
  detectedRegionName: null,
  geolocationStatus: 'idle',
}

class UserAddressReducer extends ImmerReducer<UserAddressState> {
  itemSelected(id: string | null) {
    this.draftState.selectedAddressId = id
  }

  listRequested(params: {
    filter?: UserAddressListRequestFilter
    sorting?: UserAddressListRequestSorting
    page?: number
  }) {
    this.draftState.listFetchProgress = Progress.WORK
    this.draftState.filter = params.filter || this.draftState.filter
    this.draftState.sorting = params.sorting || this.draftState.sorting
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: UserAddress[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = arrToDict(list)
    this.draftState.meta = arrToDict(
      list.map((item) => ({
        id: item.id,
        fetchProgress: Progress.SUCCESS,
        fetchError: null,
        removeProgress: Progress.IDLE,
        removeError: null,
        updateProgress: Progress.IDLE,
        updateError: null,
      })),
    )
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page
  }

  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: UserAddress) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addRequested({ dto }: { dto: Partial<UserAddressDTO> }) {
    this.draftState.addProgress = Progress.WORK
  }

  addSucceed(item: UserAddress) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateRequested(id: string, item: Partial<UserAddressDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
  }

  updateSucceed(item: UserAddress) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }

  updateFailed(id: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
  }

  filterUpdated(filter: UserAddressListRequestFilter) {
    this.draftState.filter = filter
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: UserAddressListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingHasBeenReset() {
    this.draftState.sorting = {}
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: UserAddress[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex((item) => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    this.draftState.meta[id].removeProgress = Progress.ERROR
  }

  geolocationStatusUpdated(status: UserGeolocationStatus) {
    this.draftState.geolocationStatus = status
  }

  coordinatesRequested() {
    this.draftState.geolocationStatus = 'work'
  }

  coordinatesRequestSucceed(data: { lat: number; lng: number; regionName: string | null }) {
    this.draftState.geolocationStatus = 'success'
    this.draftState.detectedCoordinates = {
      lat: data.lat,
      lng: data.lng,
    }
    this.draftState.detectedRegionName = data.regionName
  }

  coordinatesRequestFailed() {
    this.draftState.geolocationStatus = 'error'
  }
}

export const UserAddressActions = createActionCreators(UserAddressReducer)
export default UserAddressActions
export const reducer = createReducerFunction(UserAddressReducer, initialState)
