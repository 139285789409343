import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState, Progress } from '../../types'
import { Deal } from './types'

const dict = (state: AppGlobalState) => state.deal.items
const ids = (state: AppGlobalState) => state.deal.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Deal | undefined => state.deal.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata =>
  state.deal.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.deal.itemFetchProgress
const itemUpdateProgress = (state: AppGlobalState, id: string) =>
  state.deal.meta[id]?.updateProgress || Progress.IDLE
const listFetchProgress = (state: AppGlobalState) => state.deal.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.deal.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.deal.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.deal.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Deal[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.deal.filter
const sorting = (state: AppGlobalState) => state.deal.sorting
const page = (state: AppGlobalState) => state.deal.page
const total = (state: AppGlobalState) => state.deal.total
const updateProgress = (state: AppGlobalState) => state.deal.updateProgress
const removeProgress = (state: AppGlobalState) => state.deal.removeProgress
const addProgress = (state: AppGlobalState) => state.deal.addProgress
const hasNext = (state: AppGlobalState) => state.deal.total > state.deal.ids.length
const pageSize = (state: AppGlobalState) => state.deal.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.deal.total / state.deal.pageSize)

const DealSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  itemUpdateProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
}

export default DealSelectors
