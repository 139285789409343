import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'

import {
  Progress,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useDidMount,
  useHistoryPush,
} from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useHelmet from 'hooks/useHelmet'
import { ProfileAddress } from 'modules/domain/auth/types'
import { useUserAddressListManaged } from 'modules/domain/userAddress/hooks'
import { UserAddress } from 'modules/domain/userAddress/types'
import { AnalyticsEvent } from 'modules/types'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'
import { AddIconButton } from 'views/ui/AddIconButton/AddIconButton'
import CardLink from 'views/ui/CardLink/CardLink'
import CardLinkSkeleton from 'views/ui/CardLinkSkeleton/CardLinkSkeleton'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

import { ProfileEvents } from '../events'

const AddressCardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const AddressCardAddress = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`

const AddressCard: React.FC<{ address: ProfileAddress }> = ({ address }) => {
  const { t } = useTranslation('profile')

  const title = address.is_main ? t('addressType.main') : t('addressType.additional')
  return (
    <CardLink $border to={generatePath(ProfileRoutes.ProfileAddressItem, { id: address.id })}>
      <AddressCardTitle>{title}</AddressCardTitle>
      <AddressCardAddress>{address.address}</AddressCardAddress>
    </CardLink>
  )
}

const AddressListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 16px;
  padding: 0 8px;
`

const AddressList: React.FC<{ list: UserAddress[] }> = ({ list }) => (
  <AddressListWrapper>
    {list.map((item) => item && <AddressCard key={item.id} address={item} />)}
  </AddressListWrapper>
)

const Body = () => {
  const [progress, addresses] = useUserAddressListManaged()
  if (progress === Progress.WORK) {
    const skeletons = new Array(3).fill(null).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <CardLinkSkeleton key={`skeleton-${i}`} $border $minHeight={128}>
        <SkeletonText $height={24} $width={60} />
        <SkeletonText $height={20} $width={100} $marginTop={8} />
        <SkeletonText $height={20} $width={70} $marginTop={8} />
      </CardLinkSkeleton>
    ))
    return (
      <SectionBody>
        <AddressListWrapper>{skeletons}</AddressListWrapper>
      </SectionBody>
    )
  }

  return <SectionBody>{addresses.length ? <AddressList list={addresses} /> : null}</SectionBody>
}

const ProfileAddressList: React.FC = () => {
  const { t } = useTranslation('ProfileAddressList')
  const { track } = useAnalyticsSSR()

  useHelmet({ title: t('title') })

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'address_screen' })
  })

  const push = useHistoryPush()
  const add = () => {
    track(ProfileEvents.ProfileAddAddressTap)
    push({ route: ProfileRoutes.ProfileAddressesAdd })
  }

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle>
          {t('title')}
          <AddIconButton onClick={add} title={t('addBtnTitle')} />
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <Body />
    </SectionContainer>
  )
}

export default ProfileAddressList
