import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { IconCheck, IconCross, Progress, Typography } from '@agro-club/agroclub-shared'
import Notification from 'rc-notification'
import styled, { createGlobalStyle, useTheme } from 'styled-components'

import env from 'env'
import usePrevious from 'hooks/usePrevious'

export const GlobalTooltipStyle = createGlobalStyle`
  .rc-notification {
    position: fixed;
    z-index: 1000;
  }
  .rc-notification-notice {
    padding: 7px 20px 7px 10px;
    border-radius: 3px 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 0 solid rgba(0, 0, 0, 0);
    background: #fff;
    display: block;
    width: auto;
    line-height: 1.5;
    position: relative;
    margin: 10px 0;
  }
  .rc-notification-notice-closable {
    padding-right: 20px;
  }
  .rc-notification-notice-close {
    position: absolute;
    right: 5px;
    top: 3px;
    color: #000;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
    text-decoration: none;
  }
  .rc-notification-notice-close-x:after {
    content: '×';
  }
  .rc-notification-notice-close:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    text-decoration: none;
  }
  .rc-notification-fade-appear,
  .rc-notification-fade-enter {
    opacity: 0;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-notification-fade-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-notification-fade-appear.rc-notification-fade-appear-active,
  .rc-notification-fade-enter.rc-notification-fade-enter-active {
    animation-name: rcNotificationFadeIn;
    animation-play-state: running;
  }
  .rc-notification-fade-leave.rc-notification-fade-leave-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }
  @keyframes rcNotificationFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes rcDialogFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .rc-notification-notice {
    margin: 0 0 8px;
    padding: 12px 16px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
  }
  .rc-notification {
    top: 8px!important;
  }
`

let notification: any = null

if (env.BROWSER) {
  Notification.newInstance(
    {
      maxCount: 5,
    },
    (n) => {
      notification = n
    },
  )
}

const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NotificationText = styled.span`
  ${Typography.body2Bold};
  padding-left: 10px;
`

export const useNotificationProgress = (progress: Progress, textSuccess?: string, textError?: string) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  const contentSuccess = (
    <NotificationWrapper>
      <IconCheck width="20px" height="15px" fill={theme.color.primary600} />
      <NotificationText>{textSuccess || t('notifySuccess')}</NotificationText>
    </NotificationWrapper>
  )

  const contentError = (
    <NotificationWrapper>
      <IconCross width="20px" fill={theme.color.accentDestructive} />
      <NotificationText>{textError || t('notifyError')}</NotificationText>
    </NotificationWrapper>
  )

  const notifySuccess = () => {
    notification.notice({
      content: contentSuccess,
      duration: 2,
    })
  }

  const notifyError = () => {
    notification.notice({
      content: contentError,
      duration: 2,
    })
  }

  const prevProgress = usePrevious(progress)

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      notifySuccess()
    }

    if (progress === Progress.ERROR && prevProgress === Progress.WORK) {
      notifyError()
    }
  }, [progress])
}
