import { equals } from 'ramda'
import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState, Progress } from '../../types'
import { initialState } from './duck'
import { Bid } from './types'

const dict = (state: AppGlobalState) => state.bid.items
const ids = (state: AppGlobalState) => state.bid.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id: string): Bid | undefined => state.bid.items[id]
const meta = (state: AppGlobalState, id: string): EntityMetadata =>
  state.bid.meta[id] || {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    id,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
  }
const itemFetchProgress = (state: AppGlobalState) => state.bid.itemFetchProgress
const itemUpdateProgress = (state: AppGlobalState, id: string) =>
  state.bid.meta[id]?.updateProgress || Progress.IDLE
const listFetchProgress = (state: AppGlobalState) => state.bid.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.bid.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.bid.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.bid.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: Bid[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.bid.filter
const isFilterApplied = (state: AppGlobalState) => !equals(state.bid.filter, initialState.filter)
const sorting = (state: AppGlobalState) => state.bid.sorting
const page = (state: AppGlobalState) => state.bid.page
const total = (state: AppGlobalState) => state.bid.total
const updateProgress = (state: AppGlobalState) => state.bid.updateProgress
const removeProgress = (state: AppGlobalState) => state.bid.removeProgress
const addProgress = (state: AppGlobalState) => state.bid.addProgress
const hasNext = (state: AppGlobalState) => state.bid.total > state.bid.ids.length
const pageSize = (state: AppGlobalState) => state.bid.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.bid.total / state.bid.pageSize)

const itemPriceMeta = (state: AppGlobalState, id: string, addressId: string | null) =>
  !addressId
    ? {
        fetchError: 'bad_request',
        fetchProgress: Progress.ERROR,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }
    : state.bid.itemPriceMeta[addressId]?.[id] || {
        fetchError: null,
        fetchProgress: Progress.IDLE,
        id,
        removeError: null,
        removeProgress: Progress.IDLE,
        updateError: null,
        updateProgress: Progress.IDLE,
      }

const itemPrice = (state: AppGlobalState, id: string, addressId: string | null) =>
  !addressId ? null : state.bid.itemPrice[addressId]?.[id] || null

const allPricesMeta = (state: AppGlobalState, addressId: string | null) =>
  !addressId ? null : state.bid.itemPriceMeta[addressId] || null

const allPrices = (state: AppGlobalState, addressId: string | null) =>
  !addressId ? null : state.bid.itemPrice[addressId] || null

const additionDataFetchProgress = (state: AppGlobalState) => state.bid.additionDataFetchProgress
const isAadditionDataFetchCanceled = (state: AppGlobalState) =>
  state.bid.additionDataFetchProgress !== Progress.WORK

const bidResponseCreationProgress = (state: AppGlobalState) => state.bid.bidResponseCreationProgress

const BidSelectors = {
  filter,
  isFilterApplied,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  itemUpdateProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  itemPriceMeta,
  itemPrice,
  bidResponseCreationProgress,
  allPrices,
  allPricesMeta,
  additionDataFetchProgress,
  isAadditionDataFetchCanceled,
}

export default BidSelectors
