import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'

import {
  SimpleSelect,
  SimpleSelectProps,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
} from '@agro-club/agroclub-shared'

import { useProducts } from 'modules/domain/collection/hooks'
import { Product } from 'modules/domain/collection/types'

type Props = {
  onProductChange: (product: Product) => void
  selected?: string
  invalid?: SimpleSelectProps['invalid']
}

const customSelectStyles: StylesConfig<any, boolean> = {
  control: (...args) => ({
    ...controlStyle(args[0], args[1]),
  }),
  menu: (base) => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
  }),
  menuList: (base) => ({
    ...base,
    ...menuListStyle,
  }),
  option: (base) => ({
    ...base,
    ...optionStyle,
  }),
}

export const ProductSelect: React.FC<Props> = (props) => {
  const { onProductChange, selected, invalid } = props
  const [progress, products] = useProducts()

  const { t } = useTranslation('bid')
  const onChange = useCallback(
    (productId) => {
      const product = products?.find((p) => p.id === productId)
      product && onProductChange(product)
    },
    [products, onProductChange],
  )

  const options = useMemo(() => products?.map((p) => ({ id: p.id, title: p.title })) || [], [products])

  return (
    <SimpleSelect
      customStyles={customSelectStyles}
      placeholder={t('form.productPlaceholder')}
      invalid={invalid}
      errorText={t('form.noProductError')}
      progress={progress}
      onChange={onChange}
      options={options}
      value={selected}
    />
  )
}
