import { EventEmitter } from 'events'

import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios'
import HttpStatuses from 'http-status-codes'
import { stringify } from 'query-string'

import { refreshToken as refreshTokenPair } from 'modules/domain/auth/managers'
import { handleApiErrors } from 'modules/utils/handleApiErrors'
import { ApiService as IApiService, RequestBody, RequestOptions, UrlParams } from 'service/api/interface'
import { TokenService } from 'service/token/interface'

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const formHeaders = {
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
}

const bodyToForm = (params: RequestBody): FormData =>
  !(params instanceof FormData)
    ? Object.keys(params)
        .filter((key) => Boolean(params[key]))
        .reduce((form, key) => {
          form.append(key, params[key])
          return form
        }, new FormData())
    : params

const noop = () => undefined

class ApiService extends EventEmitter implements IApiService {
  private cancelTokenMap = new WeakMap<Promise<unknown>, () => void>()

  private readonly axios: AxiosInstance

  private lang = 'en'

  private tokenService: TokenService

  constructor({ tokenService, baseURL }: { tokenService: TokenService; baseURL: string }) {
    super()

    this.tokenService = tokenService
    this.axios = axios.create({
      baseURL,
    })
    this.axios.interceptors.request.use(
      (config) => {
        this.emit('request', config)
        return config
      },
      (error) => {
        this.emit('requestError', error)
        return Promise.reject(error)
      },
    )
    this.axios.interceptors.response.use(
      (response) => {
        this.emit('response', response)
        return response
      },
      (error) => {
        this.emit('responseError', error)
        return Promise.reject(error)
      },
    )

    let isTokenRefreshing = false
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        if (error.response?.status !== HttpStatuses.UNAUTHORIZED || isTokenRefreshing) throw error
        try {
          isTokenRefreshing = true
          const { response: errorResponse } = error
          const refreshToken = this.tokenService.getRefreshToken()
          if (!refreshToken || !errorResponse?.config) {
            throw error
          }
          try {
            const response = await refreshTokenPair(this)(refreshToken)
            this.tokenService.saveRefreshToken(response.refreshToken)
            this.tokenService.saveAccessToken(response.accessToken)

            const config = {
              ...errorResponse.config,
              headers: { ...errorResponse.config.headers, Authorization: `Token ${response.accessToken}` },
            }
            const result = await this.axios(config)
            isTokenRefreshing = false
            return result
          } catch (e) {
            if (e instanceof Error) {
              e.message = `Failed to retry request${e.message || ''}`
              this.emit('error', e)
            }

            throw e
          }
        } catch (e) {
          isTokenRefreshing = false
          throw e
        }
      },
    )
  }

  private performRequest<T>(
    method: Method,
    lang: string,
    url: string,
    params: UrlParams | null,
    _body: RequestBody | null,
    options: RequestOptions = {},
  ): Promise<T> {
    const sagaEmitter = options.sagaEmitter ? options.sagaEmitter : noop
    const cancelToken = axios.CancelToken.source()
    const body = _body ? (options.multipart ? bodyToForm(_body) : _body) : undefined
    const headers = options.multipart ? formHeaders : defaultHeaders
    const token = this.tokenService.getAccessToken()

    const authHeaders: { [key: string]: string } = {}
    if (token) {
      authHeaders.Authorization = `Bearer ${token}`
    }

    const request = this.axios({
      headers: {
        ...options.headers,
        ...headers,
        ...authHeaders,
        'Accept-Language': lang,
      },
      method,
      url,
      params,
      data: body,
      onUploadProgress: ({ total, loaded }) =>
        sagaEmitter({ type: 'progress', total, loaded, percent: Math.round((loaded * 100) / total) }),
      cancelToken: cancelToken.token,
      responseType: options.responseType || 'json',
      paramsSerializer: (params) => stringify(params),
    })
      .then((res) => {
        sagaEmitter({ type: 'success', result: res.data })
        return res.data
      })
      .catch((err) => {
        sagaEmitter({ type: 'error', error: err })
        return handleApiErrors(err)
      })

    this.cancelTokenMap.set(request, () => cancelToken.cancel())
    return request
  }

  get<T>(path: string, params?: UrlParams, options?: RequestOptions) {
    return this.performRequest<T>('get', this.lang, path, params || null, null, options)
  }

  post<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('post', this.lang, path, null, body || null, options)
  }

  put<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('put', this.lang, path, null, body || null, options)
  }

  delete<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('delete', this.lang, path, null, body || null, options)
  }

  patch<T>(path: string, body?: RequestBody, options?: RequestOptions) {
    return this.performRequest<T>('patch', this.lang, path, null, body || null, options)
  }

  cancelRequest(req: Promise<unknown>) {
    const cancelHandler = this.cancelTokenMap.get(req)
    cancelHandler && cancelHandler()
  }

  setLanguage(lang?: string) {
    this.lang = lang || 'en'
  }
}

export default ApiService
