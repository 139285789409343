import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { FormikHook, Input, SimpleSelect, useDidMount } from '@agro-club/agroclub-shared'
import * as Yup from 'yup'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { ProfileType } from 'modules/domain/auth/types'
import { AnalyticsEvent } from 'modules/types'
import {
  EmailContainer,
  EmailInput,
  InputContainer,
  InputsContainer,
  UserTypeContainer,
} from 'views/components/AboutForm/styled'
import { useFirstNameLastName } from 'views/components/AboutForm/useFirstNameLastName'

export type AboutFormProps = {
  userType: ProfileType
  firstName: string
  middleName: string
  lastName: string
  email?: string
}

const AboutForm: React.FC<{
  focusOnMount?: boolean
  useFormik: FormikHook
  initialValues?: Partial<AboutFormProps>
  onSubmit?: (values: Partial<AboutFormProps>) => void
  hideEmail?: boolean
  hideProfileType?: boolean
}> = ({ focusOnMount = false, useFormik, initialValues, onSubmit, hideEmail, hideProfileType }) => {
  const { t } = useTranslation('AboutForm')
  const { track } = useAnalyticsSSR()
  const firstInputRef = useRef<HTMLInputElement>(null)
  const { email = '', firstName = '', lastName = '', middleName = '', userType } = initialValues ?? {}

  const validationSchema = useMemo(
    () =>
      Yup.object({
        ...(!hideProfileType && { userType: Yup.string().required() }),
        firstName: Yup.string().required(t('validation:fieldRequired')),
        lastName: Yup.string().required(t('validation:fieldRequired')),
        email: Yup.string().email(t('validation:invalidEmail')).optional(),
      }),
    [t, hideProfileType],
  )

  const formik = useFormik<Partial<AboutFormProps>>({
    initialValues: {
      email,
      firstName,
      lastName,
      middleName,
      userType: !hideProfileType ? userType : undefined,
    },
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values) => {
      if (onSubmit) onSubmit(values)
    },
  })

  const firstNameLastName = useFirstNameLastName(formik, firstInputRef)

  useEffect(() => {
    if (firstInputRef.current && focusOnMount) {
      firstInputRef.current.focus()
    }
  }, [focusOnMount])

  useDidMount(() => {
    if (!initialValues) {
      // is signup screen
      track(AnalyticsEvent.Page, { name: 'signup_role_screen' })
    }
  })

  const userTypeError = formik.touched.userType && formik.errors.userType ? formik.errors.userType : null

  return (
    <div>
      {!hideProfileType && (
        <UserTypeContainer>
          <SimpleSelect
            label={t('userTypeLabel')}
            placeholder={t('userTypePlaceholder')}
            invalid={!!userTypeError}
            errorText={t('validation:fieldRequired')}
            onChange={(v) => {
              track('signup_role_choose_tap')
              formik.setFieldValue('userType', v)
            }}
            options={[
              {
                id: ProfileType.seller,
                title: t('userType.seller'),
              },
              {
                id: ProfileType.customer,
                title: t('userType.customer'),
              },
            ]}
            value={formik.values.userType}
          />
        </UserTypeContainer>
      )}
      <InputsContainer>
        {firstNameLastName}
        {env.REACT_APP_SHOW_PATRONYMIC_NAME && (
          <InputContainer>
            <Input
              {...formik.getFieldProps('middleName')}
              placeholder={t('middleNamePlaceholder')}
              label={t('middleNameLabel')}
              id="middle_name"
              invalid={formik.touched.middleName && !!formik.errors.middleName}
              errorText={formik.errors.middleName}
            />
          </InputContainer>
        )}
      </InputsContainer>
      {!hideEmail && (
        <EmailContainer>
          <EmailInput
            {...formik.getFieldProps('email')}
            type="email"
            placeholder={t('emailPlaceholder')}
            label={t('emailLabel')}
            id="email"
            invalid={formik.touched.email && !!formik.errors.email}
            errorText={formik.errors.email}
          />
        </EmailContainer>
      )}
    </div>
  )
}

export default AboutForm
