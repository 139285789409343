import React from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from '@agro-club/agroclub-shared'
import { FormikContextType } from 'formik'

import useLanguage from 'hooks/useLanguage'
import { AvailableLanguages } from 'types/entities'
import { AboutFormProps } from 'views/components/AboutForm/AboutForm'
import { InputContainer } from 'views/components/AboutForm/styled'

export const useFirstNameLastName = (
  formik: FormikContextType<Partial<AboutFormProps>>,
  firstInputRef: React.RefObject<HTMLInputElement>,
) => {
  const { t } = useTranslation('AboutForm')
  const lang = useLanguage()

  const fields = [
    <InputContainer key="lastName">
      <Input
        {...formik.getFieldProps('lastName')}
        ref={firstInputRef}
        placeholder={t('lastNamePlaceholder')}
        label={t('lastNameLabel')}
        id="last_name"
        invalid={formik.touched.lastName && !!formik.errors.lastName}
        errorText={formik.errors.lastName}
      />
    </InputContainer>,
    <InputContainer key="firstName">
      <Input
        {...formik.getFieldProps('firstName')}
        placeholder={t('firstNamePlaceholder')}
        label={t('firstNameLabel')}
        id="name"
        invalid={formik.touched.firstName && !!formik.errors.firstName}
        errorText={formik.errors.firstName}
      />
    </InputContainer>,
  ]
  return lang === AvailableLanguages.ru ? fields : fields.reverse()
}
