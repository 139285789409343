import { Reducer, combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { History } from 'history'

import { reducer as auth } from './domain/auth/duck'
import { reducer as bid } from './domain/bid/duck'
import { reducer as collection } from './domain/collection/duck'
import { reducer as company } from './domain/company/duck'
import { reducer as deal } from './domain/deal/duck'
import { reducer as product } from './domain/product/duck'
import { reducer as uploadManager } from './domain/uploadManager/duck'
import { reducer as userAddress } from './domain/userAddress/duck'
import { reducer as userBid } from './domain/userBid/duck'

const rootReducer = (history: History): Reducer =>
  // prettier-ignore
  combineReducers({
    router: connectRouter(history),
    auth,
    bid,
    product,
    collection,
    deal,
    userAddress,
    uploadManager,
    userBid,
    company,
  })

export default rootReducer
