import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { Progress } from 'modules/types'
import { arrToDict, getIds } from 'modules/utils/helpers'

import {
  Bid,
  BidDTO,
  BidListRequestFilter,
  BidListRequestSorting,
  BidPrice,
  BidResponseCreateDTO,
  BidState,
  BidType,
} from './types'

export const initialState: BidState = Object.freeze({
  items: {},
  meta: {},
  itemPrice: {},
  itemPriceMeta: {},
  ids: [],
  listFetchProgress: Progress.IDLE,
  listFetchError: null,
  listFetchNextProgress: Progress.IDLE,
  listFetchNextError: null,
  itemFetchProgress: Progress.IDLE,
  itemFetchError: null,
  addProgress: Progress.IDLE,
  addError: null,
  updateProgress: Progress.IDLE,
  updateError: null,
  removeProgress: Progress.IDLE,
  removeError: null,

  bidResponseCreationProgress: Progress.IDLE,
  additionDataFetchProgress: Progress.IDLE,

  filter: {
    bid_type: BidType.purchase,
  },

  sorting: {},
  page: 1,
  total: 0,
  pageSize: 10,
})

class BidReducer extends ImmerReducer<BidState> {
  listRequested(params: { filter?: BidListRequestFilter; sorting?: BidListRequestSorting; page?: number }) {
    this.draftState.listFetchProgress = Progress.WORK
    if (params.filter && Object.values(params.filter).length) {
      this.draftState.filter = { ...initialState.filter, ...params.filter }
    }
    if (params.sorting && Object.values(params.sorting)) {
      this.draftState.sorting = params.sorting
    }
    this.draftState.page = typeof params.page === 'undefined' ? this.draftState.page : params.page
  }

  listRequestSucceed(list: Bid[], total: number, page: number) {
    this.draftState.listFetchProgress = Progress.SUCCESS
    this.draftState.items = {
      ...this.draftState.items,
      ...arrToDict(list),
    }
    this.draftState.meta = {
      ...this.draftState.meta,
      ...arrToDict(
        list.map((item) => ({
          id: item.id,
          fetchProgress: Progress.SUCCESS,
          fetchError: null,
          removeProgress: Progress.IDLE,
          removeError: null,
          updateProgress: Progress.IDLE,
          updateError: null,
        })),
      ),
    }
    this.draftState.ids = getIds(list)
    this.draftState.total = total
    this.draftState.page = page

    this.draftState.additionDataFetchProgress = Progress.WORK
  }

  listRequestFailed() {
    this.draftState.listFetchProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemRequested(id: string) {
    this.draftState.itemFetchProgress = Progress.WORK

    const meta = {
      id,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }

    this.draftState.meta[id] = {
      ...meta,
      ...this.draftState.meta[id],
      fetchProgress: Progress.WORK,
      fetchError: null,
    }
  }

  itemRequestSucceed(item: Bid) {
    this.draftState.itemFetchProgress = Progress.SUCCESS
    this.draftState.meta[item.id].fetchProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
  }

  itemRequestFailed(id: string) {
    this.draftState.itemFetchProgress = Progress.ERROR
    this.draftState.meta[id].fetchProgress = Progress.ERROR
  }

  addRequested({ dto: _dto }: { dto: BidDTO }) {
    this.draftState.addProgress = Progress.WORK
  }

  addSucceed(item: Bid) {
    this.draftState.addProgress = Progress.SUCCESS
    this.draftState.items[item.id] = item
    this.draftState.meta[item.id] = {
      id: item.id,
      fetchProgress: Progress.SUCCESS,
      fetchError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
    }
  }

  addFailed() {
    this.draftState.addProgress = Progress.ERROR
  }

  updateRequested(id: string, _item: Partial<BidDTO>) {
    this.draftState.updateProgress = Progress.WORK
    this.draftState.meta[id].updateProgress = Progress.WORK
  }

  updateSucceed(item: Bid) {
    this.draftState.items[item.id] = item
    this.draftState.updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateProgress = Progress.SUCCESS
    this.draftState.meta[item.id].updateError = null
  }

  updateFailed(id: string) {
    this.draftState.updateProgress = Progress.ERROR
    this.draftState.meta[id].updateProgress = Progress.ERROR
  }

  filterUpdated(filter: BidListRequestFilter) {
    this.draftState.filter = { ...initialState.filter, ...filter }
    this.draftState.listFetchProgress = Progress.WORK
  }

  sortingUpdated(sorting: BidListRequestSorting) {
    this.draftState.sorting = sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterHasBeenReset() {
    this.draftState.filter = { ...initialState.filter, product: this.draftState.filter.product }
    this.draftState.listFetchProgress = Progress.WORK
  }

  filterResetWithoutRequest() {
    this.draftState.filter = { ...initialState.filter, product: this.draftState.filter.product }
  }

  sortingHasBeenReset() {
    this.draftState.sorting = initialState.sorting
    this.draftState.listFetchProgress = Progress.WORK
  }

  listRequestedNext(page: number) {
    this.draftState.page = page
    this.draftState.listFetchNextProgress = Progress.WORK
  }

  listRequestNextSucceed(list: Bid[], total: number) {
    this.draftState.listFetchNextProgress = Progress.SUCCESS
    this.draftState.total = total
    this.draftState.items = { ...this.draftState.items, ...arrToDict(list) }
    this.draftState.ids = [...this.draftState.ids, ...getIds(list)]
  }

  listRequestNextFailed() {
    this.draftState.listFetchNextProgress = Progress.ERROR
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeRequested(id: string) {
    this.draftState.removeProgress = Progress.WORK
    this.draftState.meta[id].removeProgress = Progress.WORK
  }

  removeSucceed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    delete this.draftState.items[id]
    delete this.draftState.meta[id]
    const i = this.draftState.ids.findIndex((item) => item === id)
    if (i !== -1) {
      const { ids } = this.draftState
      this.draftState.ids = [...ids.slice(0, i), ...ids.slice(i + 1)]
    }
  }

  removeFailed(id: string) {
    this.draftState.removeProgress = Progress.SUCCESS
    this.draftState.meta[id].removeProgress = Progress.ERROR
  }

  itemPriceRequested(bidId: string, addressId: string) {
    if (!this.draftState.itemPriceMeta[addressId]) {
      this.draftState.itemPriceMeta[addressId] = {}
    }

    if (!this.draftState.itemPrice[addressId]) {
      this.draftState.itemPrice[addressId] = {}
    }

    this.draftState.itemPriceMeta[addressId][bidId] = {
      id: bidId,
      fetchProgress: Progress.WORK,
      fetchError: null,
      removeProgress: Progress.IDLE,
      removeError: null,
      updateProgress: Progress.IDLE,
      updateError: null,
    }
  }

  itemPriceRequestSucceed(bidId: string, addressId: string, bidPrice: BidPrice) {
    this.draftState.itemPriceMeta[addressId][bidId].fetchProgress = Progress.SUCCESS
    this.draftState.itemPrice[addressId][bidId] = bidPrice
  }

  itemPriceRequestFailed(bidId: string, addressId: string, _error: unknown) {
    this.draftState.itemPriceMeta[addressId][bidId].fetchProgress = Progress.ERROR
  }

  bidResponseCreateRequested(_bidId: string, _dto: BidResponseCreateDTO) {
    this.draftState.bidResponseCreationProgress = Progress.WORK
  }

  bidResponseCreateSucceed() {
    this.draftState.bidResponseCreationProgress = Progress.SUCCESS
  }

  bidResponseCreateFailed() {
    this.draftState.bidResponseCreationProgress = Progress.ERROR
  }

  resetBidResponseCreationProgress() {
    this.draftState.bidResponseCreationProgress = Progress.IDLE
  }

  cancelAdditionDataFetch() {
    this.draftState.additionDataFetchProgress = Progress.IDLE
  }
}

export const BidActions = createActionCreators(BidReducer)
export default BidActions
export const reducer = createReducerFunction(BidReducer, initialState)
