import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'

import { SectionBody, SectionContainer, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useBidItemManaged } from 'modules/domain/bid/hooks'
import { AnalyticsEvent } from 'modules/types'
import CropParameterGroup from 'views/components/CropParameterGroup/CropParameterGroup'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import { BidWidget } from 'views/pages/BidItem/BidWidget'
import BidsRoutes from 'views/pages/Bids/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 278px;
  grid-gap: 24px;
  align-items: flex-start;

  ${(props) => props.theme.media.mobile`
    background-color: ${(props) => props.theme.color.onPrimaryLight};
    grid-template-columns: auto;
  `}
`

const H1 = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

const ID = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const ItemDetails = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px 40px;
`
const ItemDetailsKey = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
const ItemDetailsValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const BidItemPage: React.FC = () => {
  const { t } = useTranslation('BidItemPage')
  const { track } = useAnalyticsSSR()
  const params = useParams<{ id: string }>()
  const [, item] = useBidItemManaged(params.id)
  const location = useLocation<{ from: Location }>()
  const from = location.state?.from
  const formatNumber = useFormatNumber()

  useHelmet({ title: `${item?.product?.title} ID ${item?.id}` })

  useDidMount(() => {
    if (!item) return
    track(AnalyticsEvent.Page, {
      name: 'harvest_preview_bid_screen',
      product_name: item?.product.title,
      id: item?.id,
    })
  })

  if (!item) {
    return null
  }

  const pathToBidList = from?.pathname
    ? `${from?.pathname}${from?.search || ''}`
    : generatePath(BidsRoutes.Bids, { product: item.product.slug })

  return (
    <Article>
      <Breadcrumbs
        items={[
          {
            title: item.product.title,
            path: pathToBidList,
          },
          {
            title: t('bidId', { id: item.id }),
          },
        ]}
      />
      <Wrapper>
        <SectionContainer noDivider>
          <SectionBody paddedOnMobile>
            <div>
              <H1>{item.product.title}</H1>
              <ID>ID {item.id}</ID>
            </div>

            <ItemDetails>
              <ItemDetailsKey>{t('quantity')}</ItemDetailsKey>
              <ItemDetailsValue>
                {formatNumber(item.quantity)} {t('common:t')}
              </ItemDetailsValue>
            </ItemDetails>

            <CropParameterGroup title={t('paramsTitle')} parameters={item.parameters} />
          </SectionBody>
        </SectionContainer>
        <BidWidget bidId={params.id} />
      </Wrapper>
    </Article>
  )
}

export default BidItemPage
