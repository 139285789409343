import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useHistory, useParams } from 'react-router-dom'

import { SectionBody, SectionContainer, useAction, useFormManager } from '@agro-club/agroclub-shared'

import { noop } from 'helpers/noop'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { useOfferVolumeText } from 'hooks/useOfferVolumeText'
import usePrevious from 'hooks/usePrevious'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import BidActions from 'modules/domain/bid/duck'
import { useBidItemManaged, useBidPriceManaged } from 'modules/domain/bid/hooks'
import BidSelectors from 'modules/domain/bid/selectors'
import { Address } from 'modules/domain/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { AnalyticsEvent, Progress } from 'modules/types'
import BidCard from 'views/components/BidCard/BidCard'
import { BidOfferPriceCards } from 'views/components/BidOfferPriceCards/BidOfferPriceCards'
import { BidOfferSuccess } from 'views/components/BidOfferSuccess/BidOfferSuccess'
import CropParameterGroup from 'views/components/CropParameterGroup/CropParameterGroup'
import { CurrentUserAddressSelect } from 'views/components/CurrentUserAddressSelect/CurrentUserAddressSelect'
import Link from 'views/components/Link/Link'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import * as S from 'views/pages/BidOffer/styled'
import useBidOfferBreadcrumbs from 'views/pages/BidOffer/useBidOfferBreadcrumbs'
import { Routes } from 'views/pages/routes'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

import SignUpRoutes from '../SignUp/routes'

export type PriceType = 'cpt' | 'exw'
type MainFormProps = {
  qty: number
  priceType: PriceType
}

const BidOffer: React.FC = () => {
  const { t } = useTranslation('BidOffer')
  const { track } = useAnalyticsSSR()
  const params = useParams<{ id: string }>()
  const [, item] = useBidItemManaged(params.id)
  const isUserAuthorized = useSelector(AuthSelectors.isProfileFulfilled)
  const selectedAddressId = useSelector(UserAddressSelectors.selectedId)
  const fetchPriceAction = useAction(BidActions.itemPriceRequested)
  const profileType = useSelector(AuthSelectors.profileType)
  const formatNumber = useFormatNumber()
  const [priceProgress, price] = useBidPriceManaged(params.id, selectedAddressId)

  const bidResponseCreateAction = useAction(BidActions.bidResponseCreateRequested)

  const bidResponseCreationProgress = useSelector(BidSelectors.bidResponseCreationProgress)
  useNotificationProgress(bidResponseCreationProgress)

  const resetProgressAction = useAction(BidActions.resetBidResponseCreationProgress)
  useEffect(() => () => resetProgressAction(), [resetProgressAction])

  const headerText = useOfferVolumeText(item?.bid_type)

  useHelmet({ title: headerText })

  const oppositeUserTypeText = t(
    `common:${profileType === ProfileType.seller ? ProfileType.customer : ProfileType.seller}`,
  )

  const initialValues = useMemo<MainFormProps>(
    () => ({
      qty: 100,
      priceType: profileType === ProfileType.customer ? 'cpt' : 'exw',
    }),
    [profileType],
  )

  const handleAddressChange = (address?: Address) => {
    if (!address) {
      return
    }
    fetchPriceAction(params.id, address.id)
  }

  const handlePriceFetchRetry = () => {
    if (selectedAddressId) {
      fetchPriceAction(params.id, selectedAddressId)
    }
  }

  const formManager = useFormManager<{
    main: MainFormProps
    images: any
  }>()

  const handleSubmit = async () => {
    track('harvest_bid_volume_tap', { name: item?.product.title, id: item?.id })
    const [valid, form] = await formManager.submitAll()
    if (!valid || !selectedAddressId) {
      return
    }

    bidResponseCreateAction(params.id, {
      address: selectedAddressId,
      quantity: form.main.qty || 0,
      can_deliver:
        (profileType === ProfileType.customer && form.main.priceType === 'exw') ||
        (profileType === ProfileType.seller && form.main.priceType === 'cpt'),
    })
  }

  const formik = formManager.bind('main')<MainFormProps>({
    initialValues,
    enableReinitialize: true,
    onSubmit: noop,
  })

  const handleChangePrice = (priceType: PriceType) => {
    formik.setFieldValue('priceType', priceType)
  }

  useEffect(() => {
    if (!item) return
    track(AnalyticsEvent.Page, {
      name: 'harvest_bid_screen',
      product_name: item?.product.title,
      id: item?.id,
    })
  }, [item])

  const prevBidResponseCreationProgress = usePrevious(bidResponseCreationProgress)
  const history = useHistory()

  useEffect(() => {
    if (
      prevBidResponseCreationProgress === Progress.WORK &&
      bidResponseCreationProgress === Progress.SUCCESS
    ) {
      history.push(generatePath(Routes.BidItemOfferSuccess, { id: params.id }))
    }
  }, [bidResponseCreationProgress, history, params.id])

  const breadcrumbs = useBidOfferBreadcrumbs()

  if (!item) {
    return null
  }

  if (!isUserAuthorized) {
    return (
      <Article>
        {breadcrumbs}
        <S.Wrapper>
          <SectionContainer noDivider>
            <SectionBody noGrid paddedOnMobile>
              <div>
                <S.H1>{headerText}</S.H1>
              </div>
              <S.UnauthorizedDescription>{t('unauthorizedDescriptionText')}</S.UnauthorizedDescription>
              <S.RegisterButtonContainer>
                <Link to={generatePath(SignUpRoutes.SignUp)}>
                  <S.RegisterButton intent="primary" filled>
                    {t('registerButtonText')}
                  </S.RegisterButton>
                </Link>
              </S.RegisterButtonContainer>
            </SectionBody>
          </SectionContainer>
          <BidCard title={t(`bidCardTitle.${item.bid_type}`)} bid={item} />
        </S.Wrapper>
      </Article>
    )
  }

  if (bidResponseCreationProgress === Progress.SUCCESS) {
    return (
      <Article>
        {breadcrumbs}
        <S.Wrapper>
          <BidOfferSuccess />
        </S.Wrapper>
      </Article>
    )
  }

  return (
    <Article>
      {breadcrumbs}
      <S.Wrapper>
        <SectionContainer noDivider>
          <SectionBody noGrid paddedOnMobile>
            <S.H1>{headerText}</S.H1>
            <S.Table>
              <S.Row>
                <S.TitleCell>{t('BidOffer:productLabel')}</S.TitleCell>
                <S.ValueCell>{item.product.title}</S.ValueCell>
              </S.Row>
              <S.Row>
                <S.TitleCell style={{ alignSelf: 'center' }}>{t('volumeLabel')}</S.TitleCell>
                <S.ValueCell>
                  <S.VolumeInputContainer>
                    <NumberInput
                      isInteger
                      onChange={(value) => formik.setFieldValue('qty', value)}
                      id="bid-qty"
                      value={formik.values.qty}
                    />
                    <S.QtyUnit>{t('qtyUnit')}</S.QtyUnit>
                  </S.VolumeInputContainer>
                </S.ValueCell>
              </S.Row>
              <S.Row>
                <S.TitleCell>{t('sendAddressLabel')}</S.TitleCell>
                <S.ValueCell>
                  <CurrentUserAddressSelect onChange={handleAddressChange} />
                </S.ValueCell>
              </S.Row>
              <S.Row>
                <S.PriceTitleCell>{t('priceLabel')}</S.PriceTitleCell>
                <S.ValueCell>
                  <BidOfferPriceCards
                    price={price}
                    handleChangePrice={handleChangePrice}
                    priceType={formik.values.priceType}
                    priceProgress={priceProgress}
                    handlePriceFetchRetry={handlePriceFetchRetry}
                  />
                </S.ValueCell>
              </S.Row>
              <S.Row>
                <S.TitleCell>{t('distanceLabel')}</S.TitleCell>
                <S.ValueCell>
                  <S.AddressText>
                    <S.DistanceText>
                      {price?.distance ?? (formatNumber(price?.distance) || '-')} {t('common:km')}
                    </S.DistanceText>
                  </S.AddressText>
                </S.ValueCell>
              </S.Row>
            </S.Table>
            <S.GroupContainer>
              <CropParameterGroup title={t('cropParametersGroupTitle')} parameters={item.parameters} />
            </S.GroupContainer>
            <S.SubmitButtonContainer>
              <S.SubmitButton
                progress={bidResponseCreationProgress}
                onClick={handleSubmit}
                intent="primary"
                filled
                size="big"
                type="submit"
              >
                {headerText}
              </S.SubmitButton>
              <S.SubmitHintText>
                {t('submitHintText_1')}
                <br />
                {t('submitHintText_2', {
                  userType: oppositeUserTypeText,
                })}
              </S.SubmitHintText>
            </S.SubmitButtonContainer>
          </SectionBody>
        </SectionContainer>
        <BidCard title={t(`bidCardTitle.${item.bid_type}`)} bid={item} />
      </S.Wrapper>
    </Article>
  )
}

export default BidOffer
