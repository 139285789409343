import React from 'react'

import { Button, IconCheck } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: auto 411px;
  align-items: flex-start;
  padding-bottom: 24px;
  ${(props) => props.theme.media.smallTablet`
    grid-template-columns: auto;
  `}
`
export const H1 = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

export const UnauthorizedDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
`

export const RegisterButton = styled(Button)`
  width: 216px;
  font-weight: 600;
`

export const RegisterButtonContainer = styled.div`
  margin-top: 24px;
`

export const VolumeInputContainer = styled.div`
  width: 136px;
  display: flex;
  align-items: center;
`

export const Table = styled.div`
  display: grid;
  grid-template-columns: 80px auto;
  grid-gap: 16px 54px;
  //margin-right: 150px;
  margin-top: 24px;
  ${(props) => props.theme.media.smallTablet`
    margin-top: calc(24px - 16px);
    grid-template-columns: auto;
    grid-gap: 0;
    margin-right: 0;
  `}
`
export const Row: React.FC<{ children }> = ({ children }) => children

export const TitleCell = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${(props) => props.theme.media.smallTablet`
    margin-top: 16px;
  `}
`
export const ValueCell = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  ${(props) => props.theme.media.smallTablet`
    margin-top: 4px;
  `}
`

export const PriceTitleCell = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: stretch;
  margin-top: 12px;

  ${(props) => props.theme.media.smallTablet`
    margin-top: 16px;
  `}
`

export const AddressText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
export const DistanceText = styled.div`
  font-weight: 600;
`

export const PriceCardPrice = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

export const CheckBadgeWrapper = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  top: 8px;
  right: 8px;
  position: absolute;
  border-radius: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.checked ? props.theme.color.primary600 : props.theme.color.outlineNeutral};
  background-color: ${(props) => (props.checked ? props.theme.color.primary600 : 'transparent')};
  transition: 0.1s border-color ease-in;
`
export const CheckIconStyled = styled(IconCheck)`
  fill: ${(props) => props.theme.color.onPrimaryLight};
`

export const CheckBadge: React.FC<{ checked: boolean }> = ({ checked }) => (
  <CheckBadgeWrapper checked={checked}>
    <CheckIconStyled />
  </CheckBadgeWrapper>
)

export const PriceCardWrapper = styled.div<{ selected: boolean }>`
  position: relative;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.selected ? props.theme.color.primary600 : props.theme.color.outlineNeutral};
  background-color: ${(props) => (props.selected ? props.theme.color.primary25 : 'transparent')};
  padding: 12px 16px 20px;
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  transition: 0.1s border-color ease-in;
  &:hover {
    border-color: ${(props) => props.theme.color.primary600};
    ${CheckBadgeWrapper} {
      border-color: ${(props) => props.theme.color.primary600};
    }
  }
`

export const PriceDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

export const PriceCardQualityText = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const PriceCardPaymentTerms = styled.div`
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const PriceCardList = styled.div`
  min-height: 184px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`

export const PriceListErrorContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-rows: min-content;
  align-items: center;
  justify-items: center;
  margin-top: 12px;
`

export const PriceListErrorText = styled.div`
  color: ${(props) => props.theme.color.accentDestructive};
`

export const PriceListLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
`

export const PriceCardCompanyLogoWrapper = styled.div`
  margin-top: 10px;
  height: 24px;
`

export const SubmitButtonContainer = styled.div`
  margin-top: 52px;
`

export const SubmitHintText = styled.div`
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
`

export const SubmitButton = styled(Button)`
  font-weight: 600;
`

export const GroupContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`

export const QtyUnit = styled.div`
  margin-left: 8px;
`
