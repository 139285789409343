import React, { useContext } from 'react'

import { DeviceType } from 'types/entities'

export const UAContext = React.createContext<{ device: DeviceType; os: string | null }>({
  device: DeviceType.Desktop,
  os: null,
})

const useUserAgentDevice = () => {
  const { device, os } = useContext(UAContext)
  return { device, os }
}

export default useUserAgentDevice
