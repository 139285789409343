import React from 'react'
import { Provider } from 'react-redux'
import { Store } from 'redux'

import { ConnectedRouter as Router } from 'connected-react-router'
import { History } from 'history'
import { ThemeProvider } from 'styled-components'
import { AnalyticsProvider } from 'use-analytics'

import { HelmetContext, HelmetProps } from 'hooks/useHelmet'
import { GlobalTooltipStyle } from 'hooks/useNotificationProgress'
import analyticsInstance from 'initAnalytics'
import { Sentry } from 'sentry'
import { theme } from 'theme/theme'
import { HelmetState } from 'types/helmet'
import GlobalStyle from 'views/globalStyles'
import RootComponent from 'views/RootComponent'

class App extends React.Component<{ store: Store; history: History }, HelmetState> {
  constructor(props) {
    super(props)

    this.state = {
      helmet: {},
      updateHelmet: (props: HelmetProps) => this.setState({ helmet: props }),
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.handleReactException(error, errorInfo, this.props.store.getState())
  }

  render() {
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const ctx = { helmet: this.state.helmet, updateHelmet: this.state.updateHelmet }
    return (
      <Provider store={this.props.store}>
        <HelmetContext.Provider value={ctx}>
          <AnalyticsProvider instance={analyticsInstance}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <GlobalTooltipStyle />
              <Router history={this.props.history}>
                <RootComponent />
              </Router>
            </ThemeProvider>
          </AnalyticsProvider>
        </HelmetContext.Provider>
      </Provider>
    )
  }
}

export default App
