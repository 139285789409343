import { AddError } from 'modules/domain/types'
import { UserAddress } from 'modules/domain/userAddress/types'
import { Progress } from 'modules/types'
import { AvailableCountries } from 'types/entities'

export type UserProfile = {
  id: string
  date_joined: string
  phone: string
  email: string
  is_active: boolean
  role: string
  signup_step: 'profile' | 'company' | 'address' | 'complete'
  profile: {
    profile_type: ProfileType
    first_name: string
    patronymic_name?: string
    last_name: string
    full_name?: string
    email?: string
    company?: {
      id: number
      name: string
      inn: string
      payment_delay_days: number
      prepayment?: 'yes' | 'no'
      vat?: boolean
    }
  } | null
}

export type ProfileAddress = UserAddress

export type RegisterProfileDTO = any
export type ProfileDTO = any

export type AuthState = {
  isAuthenticated: boolean
  isProfileFulfilled: boolean
  email: string
  phone: string
  selectedCountry: AvailableCountries
  step: 'credentials' | 'sms' | 'register'
  suggestedPhone: string
  error: string
  resetPasswordError: string
  profile?: UserProfile
  submitCredentialsProgress: Progress
  smsCodeVerificationProgress: Progress
  registerProgress: Progress
  authProgress: Progress
  authError: unknown
  initProgress: Progress

  profileInfoUpdateProgress: Progress
  profileCompanyUpdateProgress: Progress
  profileCompanyUpdateError: AddError | null
  profileCompanyUpdateErrorDetail?: string
  profileCompanyUpdateErrorFields?: Record<string, string[]>
  profileAddressUpdateProgress: Progress
  currentProfileUpdateProgress: Progress
}

export type TokensPair = {
  accessToken: string
  refreshToken: string
}

export type TokenResponse = {
  access: string
  refresh: string
}
export type Credentials = {
  email: string
  password: string
}

export type TokenRequestFailedPayload = {
  message: string
}
export type ResetPasswordRequestedPayload = { email: string }
export type ResetPasswordRequestFailedPayload = { message: string }

export type PrepaymentType = 'yes' | 'no' | 'unknown'
export type ProfileCompanyInfoDTO = {
  inn: string
  name: string
  vat?: boolean
  prepayment?: PrepaymentType
  payment_delay_days: number
  state_reg_number?: string
}

export enum ProfileType {
  seller = 'seller',
  customer = 'customer',
}

export type ProfileInfoDTO = {
  profile_type: ProfileType
  first_name: string
  patronymic_name: string
  last_name: string
  email?: string
}

export type ProfileAddressDTO = {
  title?: string
  address: string
  latitude: number | null
  longitude: number | null
  is_main?: boolean
  geo_object?: any
}

export type ProfileUpdateDTO = {
  first_name: string
  patronymic_name: string
  last_name: string
}

export enum Countries {
  RU = 'ru',
}
