import { AppGlobalState } from 'modules/types'

export const getCompaniesFetchProgress = (state: AppGlobalState) => state.collection.companiesFetchProgress
export const getCompanies = (state: AppGlobalState) => state.collection.companies
export const getCompanyByInn = (state: AppGlobalState, inn) =>
  state.collection.companies.find((c) => c.inn === inn)

export const getProductsFetchProgress = (state: AppGlobalState) => state.collection.productsFetchProgress
export const getProducts = (state: AppGlobalState) => state.collection.products

const CollectionSelectors = {
  getProducts,
  getProductsFetchProgress,

  getCompanies,
  getCompaniesFetchProgress,
  getCompanyByInn,
}

export default CollectionSelectors
