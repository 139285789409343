import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Progress, useAction, useHistoryPush } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import { useProducts } from 'modules/domain/collection/hooks'
import UserBidActions from 'modules/domain/userBid/duck'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import { UserBid, UserBidParameter, UserBidParameterCondition } from 'modules/domain/userBid/types'
import { BottomRemark } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BottomRemark'
import { CropsParam } from 'views/pages/Profile/ProfileBidsPage/BidAdd/CropsParam'
import { Params } from 'views/pages/Profile/ProfileBidsPage/BidAdd/Params'
import * as S from 'views/pages/Profile/ProfileBidsPage/BidAdd/styled'
import { LabeledContainer } from 'views/pages/Profile/ProfileBidsPage/BidAdd/styled'
import ProfileRoutes from 'views/pages/Profile/routes'
import { FormSection } from 'views/pages/Profile/styled'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

export type EditableFields = {
  price?: number
  quantity?: number
  mainParameter: UserBidParameter
  parameters: UserBidParameter[]
}

type Props = {
  bid: UserBid
}

export const BidEditForm: React.FC<Props> = ({ bid }) => {
  const { t } = useTranslation('bid')
  const { track } = useAnalyticsSSR()
  const [, products] = useProducts()
  const product = products.find((p) => p.id === bid.product.id)
  const updateAction = useAction(UserBidActions.updateRequested)
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const progress = useSelector(UserBidSelectors.updateProgress)
  useNotificationProgress(progress)

  const push = useHistoryPush()
  const prevProgress = usePrevious(progress)
  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      push({ route: ProfileRoutes.ProfileBidItem, params: { id: bid.id } })
    }
  }, [prevProgress, progress, push])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        price: Yup.string().required(),
        quantity: Yup.string().required(),
      }),
    [],
  )

  const price = bid.bid_type === 'purchase' ? bid.price_cpt : bid.price_exw

  const mainParam = bid.parameters.find((p) => p.parameter.id === product?.main_parameter?.id)

  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      price,
      quantity: bid.quantity,
      parameters:
        bid.parameters
          .filter((p) => p.parameter.id !== product?.main_parameter?.id)
          .map((p) => ({
            parameter: p.parameter.id,
            condition: p.condition,
            parameter_value: p.parameter_value,
          })) || [],
      mainParameter: {
        parameter: mainParam?.parameter.id,
        condition: mainParam?.condition as keyof typeof UserBidParameterCondition,
        parameter_value: mainParam?.parameter_value,
      },
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }

      track('my_offers_offer_save', { product: product?.title, id: bid.id })

      const { quantity, price, mainParameter, parameters } = formik.values
      const dto = {
        quantity,
        price,
        parameters: [...parameters, mainParameter].filter((p) => typeof p.parameter_value !== 'undefined'),
      }
      updateAction(bid.id, dto)
    },
  })

  return (
    <>
      <S.Section>
        <S.Label>{t('form.quantity')}</S.Label>
        <S.Cell>
          <S.InputWrapper>
            <NumberInput
              isInteger
              invalid={formik.touched.quantity && !!formik.errors.quantity}
              value={formik.values.quantity}
              onChange={(v) => formik.setFieldValue('quantity', v)}
            />
          </S.InputWrapper>
        </S.Cell>
        <div>
          <S.Label>{t('form.price', { currency })}</S.Label>
          <S.LabelHelpText>{t('form.without_nds')}</S.LabelHelpText>
        </div>
        <S.PriceWrapper>
          <S.InputWrapper>
            <NumberInput
              invalid={formik.touched.price && !!formik.errors.price}
              value={formik.values.price}
              onChange={(v) => {
                formik.setFieldValue('price', v)
              }}
              max={1000}
            />
          </S.InputWrapper>
          <S.PriceRemark>{t('form.priceRemark')}</S.PriceRemark>
        </S.PriceWrapper>
      </S.Section>

      {(product?.parameters?.length || product?.main_parameter) && (
        <FormSection title={t('form.cropsParams')}>
          {product?.main_parameter && (
            <LabeledContainer label={t('form.mainParameter')}>
              <CropsParam
                index={0}
                onChange={(_, values) => formik.setFieldValue(`mainParameter`, values)}
                isMain
                parameter={formik.values.mainParameter as UserBidParameter}
                parameters={product.parameters}
                valueIsInvalid={
                  formik.touched.mainParameter?.parameter_value &&
                  !!formik.errors.mainParameter?.parameter_value
                }
              />
            </LabeledContainer>
          )}
          <Params
            {...{
              formik,
              product,
            }}
          />
        </FormSection>
      )}

      <S.ButtonsWrapper>
        <div>
          <Button
            disabled={!formik.dirty}
            filled
            intent="primary"
            onClick={formik.submitForm}
            progress={progress}
          >
            {t('common:save')}
          </Button>
        </div>
        <BottomRemark />
      </S.ButtonsWrapper>
    </>
  )
}
