import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { Routes } from 'views/pages/routes'

const useMatchedRouteWithTitle = () => {
  const { t } = useTranslation('routes')
  const isSignIn = useRouteMatch(Routes.SignIn)?.isExact
  const isSignUp = useRouteMatch(Routes.SignUp)?.isExact
  const isReset = useRouteMatch(Routes.Reset)?.isExact

  if (isSignIn) {
    return [Routes.SignIn, t('signIn')]
  }

  if (isReset) {
    return [Routes.Reset, t('reset')]
  }

  if (isSignUp) {
    return [Routes.SignUp, t('signUp')]
  }

  return null
}

const useBreadcrumbs = (): { path: string; title: string }[] => {
  const { t } = useTranslation('routes')
  const match = useMatchedRouteWithTitle()
  const isMain = useRouteMatch('/')?.isExact

  if (isMain) return []

  if (!match) {
    return [{ path: '/', title: t('home') }]
  }
  const [path, title] = match

  return [
    { path: '/', title: t('home') },
    { path, title },
  ]
}

export default useBreadcrumbs
