import { AdvancedHeadCell, Button, Table as TableDefault } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

import { BidListSortFields } from 'modules/domain/bid/types'
import { Article as ArticleDefault } from 'views/layouts/MainLayout/MainLayout'

const layoutPadding = css`
  ${(props) => props.theme.media.mobile`
    padding: 0 12px;
  `}
`

export const Article = styled(ArticleDefault)`
  display: flex;
  flex-grow: 1;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 302px auto;
  grid-gap: 24px 0;
  grid-template-areas:
    'header header'
    'filter table';

  ${(props) => props.theme.media.tablet`
    grid-gap: 24px;
  `}
  ${(props) => props.theme.media.mobile`
    min-height: calc(100% - 20px);
    grid-template-rows: max-content 1fr max-content;
    grid-template-areas:
      'header header'
      'table table'
      'filter filter';
  `}
`
export const FilterWrapper = styled.div`
  position: relative;
  grid-area: filter;
  ${(props) => props.theme.media.mobile`
    margin-top: 56px;
  `}
`
export const TableWrapper = styled.div`
  grid-area: table;
`
export const H1 = styled.h1`
  grid-area: header;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  min-height: 48px;
  ${(props) => props.theme.media.mobile`
    line-height: 36px;
    font-size: 28px;
  `}
  ${layoutPadding}
`
export const FilterFooterWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  grid-gap: 8px;
`
export const Table = styled(TableDefault)`
  min-width: 300px;
`
export const TableHeadCell = styled(AdvancedHeadCell<BidListSortFields>())`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`
export const ClearButton = styled(Button)`
  margin-top: 20px;
`
