import React from 'react'

import { BidOfferSuccess } from 'views/components/BidOfferSuccess/BidOfferSuccess'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import { Wrapper } from 'views/pages/BidOffer/styled'
import useBidOfferBreadcrumbs from 'views/pages/BidOffer/useBidOfferBreadcrumbs'

export const Success: React.FC = () => {
  const breadcrumbs = useBidOfferBreadcrumbs()

  return (
    <Article>
      {breadcrumbs}
      <Wrapper>
        <BidOfferSuccess />
      </Wrapper>
    </Article>
  )
}
