import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SectionBody, SectionContainer, SectionTitle } from '@agro-club/agroclub-shared'

import useHelmet from 'hooks/useHelmet'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'

export const BidAddPage: React.FC = (props) => {
  const history = useHistory()

  const { t } = useTranslation('bid')
  const goBack = () => {
    history.push(ProfileRoutes.ProfileBids)
  }

  useHelmet({ title: t('form.add') })

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>{t('form.add')}</SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody paddedOnMobile>{props.children}</SectionBody>
    </SectionContainer>
  )
}
