import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SectionBody, SectionContainer } from '@agro-club/agroclub-shared'

import useHelmet from 'hooks/useHelmet'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import { CommonSuccess } from 'views/components/CommonSuccess/CommonSuccess'

export const BidOfferSuccess: React.FC = (props) => {
  const { t } = useTranslation('BidOffer')

  const profileType = useSelector(AuthSelectors.profileType)
  const oppositeUserTypeText = t(
    `common:${profileType === ProfileType.seller ? ProfileType.customer : ProfileType.seller}`,
  )

  useHelmet({ title: t('pageSuccessHeader') })

  return (
    <SectionContainer noDivider>
      {props.children}
      <SectionBody noGrid paddedOnMobile>
        <CommonSuccess
          title={t('pageSuccessHeader')}
          description={t('pageSuccessDescription', {
            userType: oppositeUserTypeText,
          })}
        />
      </SectionBody>
    </SectionContainer>
  )
}
