import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormikHook, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import * as Yup from 'yup'

import env from 'env'
import { noop } from 'helpers/noop'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { AnalyticsEvent } from 'modules/types'
import AddressForm from 'views/components/AddressForm/AddressForm'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Description = styled.div`
  max-width: 640px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceHighEmphasys};
  margin-bottom: 16px;
`

export type AddressFormProps = {
  address: string
  latitude: number | null
  longitude: number | null
  geo_object?: any
}

const SignUpAddressForm: React.FC<{ useFormik: FormikHook }> = ({ useFormik }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('AddressForm')

  useDidMount(() => track(AnalyticsEvent.Page, { name: 'signup_address_screen' }))

  const validationSchema = Yup.object({
    address: Yup.string().required(),
    latitude: Yup.number().required(),
    longitude: Yup.number().required(),
  })

  const formik = useFormik<AddressFormProps>({
    initialValues: {
      address: '',
      latitude: null,
      longitude: null,
      geo_object: null,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    onSubmit: noop,
  })

  const coordinateError =
    (formik.touched.latitude && formik.errors.latitude) ||
    (formik.touched.longitude && formik.errors.longitude)

  const addressError = formik.touched.address && formik.errors.address
  const error = addressError
    ? t('errors.emptyAddressString')
    : coordinateError
    ? t('errors.invalidCoords')
    : undefined

  return (
    <Wrapper>
      <Description>{t('description')}</Description>
      <AddressForm
        placeholder={t('addressPlaceholder')}
        noOptionsMessage={() => t('noOptionMessage')}
        apiKey={env.REACT_APP_YA_MAPS_API_KEY}
        invalid={!!error}
        errorText={error}
        focusOnMount
        onChange={({ coords, address, geoObject }) => {
          formik.setValues({
            address,
            latitude: coords?.[0] || null,
            longitude: coords?.[1] || null,
            geo_object: geoObject,
          })
        }}
      />
    </Wrapper>
  )
}

export default SignUpAddressForm
