import React from 'react'

import styled from 'styled-components'

import { GroupTitle } from 'views/ui/GroupTitle/GroupTitle'

const ParamGroupWrapper = styled.div``
const ParamGroupBody = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 8px 40px;
`

export const ParamGroup: React.FC<{ title?: string; className?: string }> = ({
  title,
  children,
  ...rest
}) => (
  <ParamGroupWrapper {...rest}>
    {title ? <GroupTitle>{title}</GroupTitle> : null}
    <ParamGroupBody>{children}</ParamGroupBody>
  </ParamGroupWrapper>
)

const ParamKey = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
const ParamValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const ParamPair: React.FC<{ title: string; value?: string | number | null }> = ({ title, value }) => (
  <>
    <ParamKey>{title}</ParamKey>
    <ParamValue>{value ?? '—'}</ParamValue>
  </>
)
