import { BidProduct } from 'modules/domain/deal/types'
import { Address, CropParameter, EntityMetadata } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { Dict } from 'types/generics'

export type BidState = {
  items: Dict<Bid>
  meta: Dict<EntityMetadata>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: unknown
  listFetchNextProgress: Progress
  listFetchNextError: unknown
  itemFetchProgress: Progress
  itemFetchError: unknown
  addProgress: Progress
  addError: unknown
  updateProgress: Progress
  updateError: unknown
  removeProgress: Progress
  removeError: unknown

  filter: BidListRequestFilter
  sorting: BidListRequestSorting
  page: number
  total: number
  pageSize: number

  bidResponseCreationProgress: Progress

  // mapped by user address id
  itemPrice: Dict<Dict<BidPrice>>
  itemPriceMeta: Dict<Dict<EntityMetadata>>
  additionDataFetchProgress: Progress
}

export enum BidType {
  purchase = 'purchase',
  sale = 'sale',
}

export type Bid = {
  id: string
  address?: Address
  bid_type: BidType
  company?: {
    name: string
    logo: string
  }
  parameters: CropParameter[]
  created: string
  modified: string
  product: BidProduct
  quantity: number
  status: string
  price_cpt: number
  price_exw: number
  distance: number
}

export type BidPrice = {
  price_exw: number
  price_cpt: number
  distance?: number
}

export type BidListRequestFilter = {
  search?: string
  product?: string
  bid_type?: BidType
  distance_min?: number
  distance_max?: number
  price_exw_min?: number
  price_exw_max?: number
  price_cpt_min?: number
  price_cpt_max?: number
  quantity_min?: number
  quantity_max?: number
}

export type BidListSortFields = 'price_exw' | 'price_cpt' | 'quantity' | 'distance'

export type BidListRequestSorting = {
  sort_field?: BidListSortFields
  sort_reversed?: boolean
}

export enum ParameterCondition {
  lte = '≤',
  gte = '≥',
}

export type BidParameter = {
  parameter?: number
  condition: keyof typeof ParameterCondition
  parameter_value?: number
}

export type BidDTO = {
  id?: string
  address?: string // addressId
  product?: string // productId
  price?: number
  quantity?: number
  parameters: BidParameter[]
}

export type BidResponseCreateDTO = {
  quantity: number
  address: string | number
  can_deliver: boolean
}
