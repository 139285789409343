import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StylesConfig } from 'react-select'

import {
  SimpleSelect,
  SimpleSelectProps,
  controlStyle,
  menuListStyle,
  menuStyle,
  optionStyle,
  useAction,
} from '@agro-club/agroclub-shared'

import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { Address } from 'modules/domain/types'
import UserAddressActions from 'modules/domain/userAddress/duck'
import { useUserAddressListManaged } from 'modules/domain/userAddress/hooks'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'

export type CurrentUserAddressSelectProps = {
  onChange?: (address: Address) => void
  invalid?: SimpleSelectProps['invalid']
  menuPlacement?: SimpleSelectProps['menuPlacement']
  label?: string
  errorText?: string
  placeholder?: string
  noOptionsMessage?: SimpleSelectProps['noOptionsMessage']
  required?: boolean
}

const customSelectStyles: StylesConfig<any, boolean> = {
  control: (...args) => ({
    ...controlStyle(args[0], args[1]),
  }),
  menu: (base) => ({
    ...base,
    ...menuStyle,
    zIndex: 10,
  }),
  menuList: (base) => ({
    ...base,
    ...menuListStyle,
  }),
  option: (base) => ({
    ...base,
    ...optionStyle,
  }),
}

export const CurrentUserAddressSelect: React.FC<CurrentUserAddressSelectProps> = ({
  onChange: onChangeProps,
  invalid,
  menuPlacement,
  label,
  errorText,
  placeholder,
  noOptionsMessage,
  required,
}) => {
  const { t } = useTranslation()
  const [progress, addresses] = useUserAddressListManaged()
  useNotificationProgress(progress)

  const selectedId = useSelector(UserAddressSelectors.selectedId)
  const updateIdAction = useAction(UserAddressActions.itemSelected)
  const options = useMemo<SimpleSelectProps['options']>(
    () =>
      addresses.map((address) => ({
        id: address.id,
        title: address.address,
      })),
    [addresses],
  )

  const onChange = useCallback(
    (id: string | undefined) => {
      updateIdAction(id)
      const address = addresses.find((address) => address.id === id)
      address && onChangeProps?.(address)
    },
    [updateIdAction, onChangeProps, addresses],
  )

  return (
    <SimpleSelect
      customStyles={customSelectStyles}
      id="current-user-address-select"
      invalid={invalid}
      errorText={errorText}
      progress={progress}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      loadingMessage={t('common:loading')}
      noOptionsMessage={noOptionsMessage}
      options={options}
      value={selectedId}
      isSearchable
      menuPlacement={menuPlacement}
      required={required}
    />
  )
}
