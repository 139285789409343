/* eslint-disable no-continue */

import { eventChannel } from 'redux-saga'
import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects'

import { CancelToken } from 'modules/domain/uploadManager/constants'
import { endpoints } from 'modules/endpoints'
import { getApiClient } from 'modules/sagaEffects'
import { ApiService, EmitterToken } from 'service/api/interface'

import UploadManagerActions from './duck'
import UploadManagerSelectors from './selectors'
import { UploadResponse, UploadingFile } from './types'

export function* startUploadSaga({
  payload: [id, file, endpoint],
}: ReturnType<typeof UploadManagerActions.fileUploadStarted>) {
  let request: Promise<UploadResponse | void> | null = null
  const apiService: ApiService = yield getApiClient()
  function createUploadChannel() {
    return eventChannel<EmitterToken<UploadResponse>>((emitter) => {
      request = apiService
        .post<UploadResponse>(
          endpoint || endpoints.fileUpload(),
          {
            file_data: file,
          },
          {
            multipart: true,
            sagaEmitter: emitter,
          },
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {
          /* error already handled */
        })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    })
  }
  try {
    const uploadChannel = yield call(createUploadChannel)
    while (true) {
      const { progress, cancel } = yield race({
        progress: take(uploadChannel),
        cancel: take(UploadManagerActions.fileUploadCancelled.type),
      })

      if (progress) {
        const p = progress as EmitterToken<UploadResponse>
        switch (p.type) {
          case 'error':
            yield put(UploadManagerActions.fileUploadFailed(id))
            return
          case 'progress':
            yield put(UploadManagerActions.fileUploadProgressTicked(id, p.percent))
            continue
          case 'success':
            yield put(UploadManagerActions.fileUploadCompleted(id, p.result.url, p.result))
            return
          default:
            // eslint-disable-next-line no-console
            console.warn('panic!!')
            return
        }
      }
      if (cancel && cancel.payload === id && request) {
        apiService.cancelRequest(request)
        return
      }
    }
  } catch (err) {
    yield put(UploadManagerActions.fileUploadFailed(id))
  }
}

// returns null | string
export function* waitUntilFileLoaded(fileId: string) {
  const maybeFile: UploadingFile | undefined = yield select((state) =>
    UploadManagerSelectors.file(state, fileId),
  )

  const throwUploadError = () => {
    throw new Error('File upload error')
  }

  if (maybeFile) {
    switch (maybeFile.status) {
      case 'complete':
        return maybeFile.apiURL
      case 'error':
        throwUploadError()
    }
  }

  while (true) {
    const { success, error, cancel } = yield race({
      success: take(UploadManagerActions.fileUploadCompleted.type),
      error: take(UploadManagerActions.fileUploadFailed.type),
      cancel: take(UploadManagerActions.fileUploadCancelled.type),
    })

    if (success) {
      const s = success as ReturnType<typeof UploadManagerActions.fileUploadCompleted>
      if (s.payload[0] === fileId) {
        const [, , response] = s.payload
        return response
      }
      continue
    }

    if (error) {
      const e = error as ReturnType<typeof UploadManagerActions.fileUploadFailed>
      if (e.payload === fileId) {
        throwUploadError()
      }
      continue
    }

    if (cancel) {
      const c = cancel as ReturnType<typeof UploadManagerActions.fileUploadCancelled>
      if (c.payload === fileId) {
        return CancelToken
      }
      continue
    }
  }
}

const UploadManagerSaga = function* () {
  yield all([takeEvery(UploadManagerActions.fileUploadStarted.type, startUploadSaga)])
}

export default UploadManagerSaga
