import React from 'react'

import { Button } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const EmptyListContainer = styled.div`
  max-width: 680px;
`
const EmptyListBold = styled.div`
  max-width: 360px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
const EmptyListDescription = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
const EmptyListButton = styled(Button)`
  margin-top: 24px;
`

const EmptyList: React.FC<{
  title: string | React.ReactNode
  description: string | React.ReactNode
  actionText: string
  onClick: () => void
}> = ({ title, description, actionText, onClick }) => (
  <EmptyListContainer>
    <EmptyListBold>{title}</EmptyListBold>
    <EmptyListDescription>{description}</EmptyListDescription>
    <EmptyListButton onClick={onClick} intent="primary" filled>
      {actionText}
    </EmptyListButton>
  </EmptyListContainer>
)

export default EmptyList
