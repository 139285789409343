import { all, fork } from 'redux-saga/effects'

import AuthSaga from './domain/auth/sagas'
import BidSaga from './domain/bid/sagas'
import CollectionSaga from './domain/collection/sagas'
import CompanySaga from './domain/company/sagas'
import DealSaga from './domain/deal/sagas'
import ProductSaga from './domain/product/sagas'
import UploadManagerSaga from './domain/uploadManager/sagas'
import UserAddressSaga from './domain/userAddress/sagas'
import UserBidSaga from './domain/userBid/sagas'

const rootSaga = function* (): Generator {
  // prettier-ignore
  yield all([
    fork(AuthSaga),
    fork(BidSaga),
    fork(ProductSaga),
    fork(CollectionSaga),
    fork(DealSaga),
    fork(UserAddressSaga),
    fork(UploadManagerSaga),
    fork(UserBidSaga),
    fork(CompanySaga),
  ])
}

export default rootSaga
