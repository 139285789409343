import React from 'react'
import { LinkProps, Link as RouterLink, matchPath } from 'react-router-dom'

import env from 'env'
import { fixReturnUrl } from 'helpers/url'
import { Loadables, Routes } from 'views/pages/routes'

const routesList = Object.values(Routes)
  .map((v) => v.split('/'))
  .sort((a, z) => z.length - a.length)
  .map((v) => v.join('/'))

const Link: React.FC<LinkProps> = ({ to, ...props }) => {
  const handleHover = () => {
    const route =
      typeof to === 'string'
        ? to
        : typeof to === 'object' && typeof to.pathname === 'string'
        ? to.pathname
        : ''
    const match = matchPath(route, {
      path: routesList,
      exact: true,
      strict: false,
    })
    if (match && Loadables[match.path]) {
      Loadables[match.path].preload()
    }
  }

  if (!env.BROWSER) {
    const uri =
      typeof to === 'string'
        ? to
        : typeof to === 'object' && typeof to.pathname === 'string'
        ? to.pathname
        : undefined

    if (uri && !uri.startsWith('http')) {
      return <RouterLink to={fixReturnUrl(uri)} {...props} onMouseEnter={handleHover} />
    }
  }
  return <RouterLink to={to} {...props} onMouseEnter={handleHover} />
}

export default Link
