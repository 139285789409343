export const getGeolocationStatus = () => navigator.permissions.query({ name: 'geolocation' })

export const getCurrentPosition = ({
  highAccuracy = false,
}: { highAccuracy?: boolean } = {}): Promise<GeolocationPosition> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: highAccuracy })
  })

// eslint-disable-next-line max-len
export const geocoderUrl = `https://geocode-maps.yandex.ru/1.x/?apikey={{apikey}}&format=json&results={{results}}&geocode={{geocode}}`

export const getGeocoderUrl = (
  apiKey: string,
  geocode: { lat: number; lng: number } | string,
  options: { results?: number } = {},
) => {
  let geocodeString = ''
  if (typeof geocode === 'string') {
    geocodeString = geocode
  } else {
    geocodeString = `${geocode.lng},${geocode.lat}`
  }

  return geocoderUrl
    .replace('{{results}}', String(options.results || 1))
    .replace('{{apikey}}', apiKey)
    .replace('{{geocode}}', geocodeString)
}
