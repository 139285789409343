enum ProfileRoutes {
  Profile = '/profile',
  ProfileInfo = '/profile/account',

  ProfileBids = '/profile/bids',
  ProfileBidAdd = '/profile/bids/add',
  ProfileBidAddSuccess = '/profile/bids/add/success',
  ProfileBidItem = '/profile/bids/:id(\\d+)',
  ProfileBidEdit = '/profile/bids/:id(\\d+)/edit',
  ProfileBidOffer = '/profile/bids/:id(\\d+)/offer/:dealId(\\d+)',

  ProfileAddresses = '/profile/address',
  ProfileAddressesAdd = '/profile/address/add',
  ProfileAddressItem = '/profile/address/:id(\\d+)',

  ProfileDeals = '/profile/deals',
  ProfileDealsItem = '/profile/deals/:id',
  ProfileDealItem = '/profile/deals/:id',
}

export default ProfileRoutes
