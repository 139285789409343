import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { useParams } from 'react-router-dom'

import { Button } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import { useOfferVolumeText } from 'hooks/useOfferVolumeText'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import { BidDeal, DealSource } from 'modules/domain/userBid/types'
import Link from 'views/components/Link/Link'
import ProfileRoutes from 'views/pages/Profile/routes'

const OfferItem = styled.div`
  width: 226px;
  height: 184px;
  padding: 12px 16px 16px;
  border: ${({ theme }) => `1px solid ${theme.color.outlineNeutral}`};
  box-sizing: border-box;
  border-radius: 16px;
  display: grid;
  grid-gap: 16px;
`

const OfferTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`
const OfferSubTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.color.onSurfaceMidEmphasys};
`

const BoldPrice = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`

const ThinPrice = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`

const OfferLink = styled(Link)`
  margin-top: auto;
  display: block;
`

const ResponseBottom = styled.div`
  margin-top: auto;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
`

const OfferButton = styled(Button)`
  line-height: 16px;
`

type Props = {
  deal: BidDeal
}

export const ProfileBidOffer: React.FC<Props> = ({ deal }) => {
  const { track } = useAnalyticsSSR()
  const { id } = useParams<{ id: string }>()
  const currentUser = useSelector(AuthSelectors.profile)
  const profileType = currentUser?.profile?.profile_type
  const activeBid = useSelector((state) => UserBidSelectors.item(state, id))
  const { t } = useTranslation('ProfileBidItem')
  const formatNumber = useFormatNumber()
  const bid = activeBid?.bid_type === 'purchase' ? deal.sale_bid : deal.purchase_bid
  const exw = formatNumber(deal.price_exw, true)
  const cpt = formatNumber(deal.price_cpt, true)
  const mainParameter = bid.parameters?.length ? bid.parameters[0] : undefined
  const buttonText = useOfferVolumeText(activeBid?.bid_type)
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <OfferItem>
      <div>
        <OfferTitle>{deal.product.title}</OfferTitle>
        <OfferSubTitle>{`${deal.quantity} ${t('common:t')}${
          mainParameter
            ? ` / ${mainParameter.condition_label} ${formatNumber(mainParameter.parameter_value)}`
            : ''
        }`}</OfferSubTitle>
      </div>
      <div>
        <div>
          <BoldPrice>
            {exw} {t('common:rubKg', { currency })}
          </BoldPrice>
          <ThinPrice>EXW</ThinPrice>
        </div>
        <OfferSubTitle>
          {cpt} {t('common:rubKg', { currency })} CPT
        </OfferSubTitle>
      </div>
      {deal.source === DealSource.response && (
        <ResponseBottom>{t(`common:offeredText.${profileType}`)}</ResponseBottom>
      )}
      {deal.source === DealSource.matching && !!id && !!deal.id && (
        <OfferLink
          to={generatePath(ProfileRoutes.ProfileBidOffer, { id, dealId: deal.id })}
          onClick={() => track('offer_tap', { section: deal.product.title, id: deal.id })}
        >
          <OfferButton intent="primary" filled={profileType === ProfileType.seller}>
            {buttonText}
          </OfferButton>
        </OfferLink>
      )}
    </OfferItem>
  )
}
