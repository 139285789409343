import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'
import { ListResponse } from 'types/api'

import { Company, CompanyDTO, CompanyListRequestFilter, CompanyListRequestSorting } from './types'

export const getList =
  (apiClient: ApiService) =>
  async (
    filter: CompanyListRequestFilter,
    sorting: CompanyListRequestSorting,
    page: number,
    pageSize: number,
  ) =>
    apiClient.get<ListResponse<Company>>(endpoints.company(), {
      ...filter,
      ...sorting,
      page,
      page_size: pageSize,
    })

export const getItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.get<Company>(endpoints.company(id))

export const addItem = (apiClient: ApiService) => async (dto: CompanyDTO) =>
  apiClient.post<Company>(endpoints.company(), dto)

export const updateItem = (apiClient: ApiService) => async (id: string, dto: Partial<CompanyDTO>) =>
  apiClient.put<Company>(endpoints.company(id), dto)

export const removeItem = (apiClient: ApiService) => async (id: string) =>
  apiClient.delete<Company>(endpoints.company(id))
