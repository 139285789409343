import React from 'react'

import { IconExit, useAction } from '@agro-club/agroclub-shared'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import AuthActions from 'modules/domain/auth/duck'
import BidActions from 'modules/domain/bid/duck'
import { StyledIconButton } from 'views/ui/styled'

import HeaderEvents from '../Header/events'

export const SignOutButton: React.FC = () => {
  const { track } = useAnalyticsSSR()
  const signOutAction = useAction(AuthActions.signOutRequested)
  const resetBidFilters = useAction(BidActions.filterResetWithoutRequest)
  return (
    <StyledIconButton
      onClick={() => {
        track(HeaderEvents.UpperlineClick, { type: 'signout' })
        signOutAction()
        resetBidFilters()
      }}
      Icon={IconExit}
    />
  )
}
