import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import MainRoutes from 'views/pages/Main/routes'

import ProductActions from './duck'
import * as managers from './managers'
import { Product } from './types'

export const fetchList = function* () {
  try {
    const results: Product[] = yield call(apiCall, managers.getList)
    yield put(ProductActions.listRequestSucceed(results))
  } catch (err) {
    yield put(ProductActions.listRequestFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof ProductActions.itemRequested>) {
  try {
    const item: Product = yield call(apiCall, managers.getItem, id)
    yield put(ProductActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(ProductActions.itemRequestFailed(id))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof ProductActions.addRequested>) {
  try {
    const item: Product = yield call(apiCall, managers.addItem, dto)
    yield put(ProductActions.addSucceed(item))
  } catch (err) {
    yield put(ProductActions.addFailed())
  }
}
export const updateItem = function* ({
  payload: [id, dto],
}: ReturnType<typeof ProductActions.updateRequested>) {
  try {
    const item: Product = yield call(apiCall, managers.updateItem, id, dto)
    yield put(ProductActions.updateSucceed(item))
  } catch (err) {
    yield put(ProductActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof ProductActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(ProductActions.removeSucceed(payload))
  } catch (err) {
    yield put(ProductActions.removeFailed(payload))
  }
}

const routeWatcher = makeRouteWatcher(MainRoutes, function* ({ match, silent }) {
  if (silent) {
    return
  }
  switch (match) {
    case MainRoutes.Main:
      return yield put(ProductActions.listRequested())
  }
})

const ProductSaga = function* () {
  yield all([
    takeLatest(ProductActions.itemRequested.type, fetchItem),
    takeLatest(ProductActions.listRequested.type, fetchList),
    takeLatest(ProductActions.addRequested.type, addItem),
    takeLatest(ProductActions.updateRequested.type, updateItem),
    takeLatest(ProductActions.removeRequested.type, removeItem),
    fork(routeWatcher),
  ])
}

export default ProductSaga
