import { Flag, IconBurgerMenu, IconCross } from '@agro-club/agroclub-shared'
import styled, { StyledProps, css } from 'styled-components'

import { ReactComponent as LogoImageEn } from 'assets/images/logo-en.svg'
import { ReactComponent as LogoImage } from 'assets/images/logo.svg'
import env from 'env'
import Link from 'views/components/Link/Link'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: ${(props) => props.theme.size.maxContentWidth}px;
  margin: 0 auto;
  padding: 0;
  height: 100%;

  ${(props) => props.theme.media.mobile`
    padding: 0 8px 0 11px;
  `}
`
export const ButtonsWrapper = styled.div`
  margin-left: auto;
  flex-shrink: 0;
  justify-content: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  ${(props) => props.theme.media.desktop`
    & > button {
      height: 40px;
    }
  `}
`

export const CountBadge = styled.span`
  min-width: 20px;
  height: 20px;
  border-radius: 10px;
  background: ${(props) => props.theme.color.accentNotify};
  position: absolute;
  right: -5px;
  top: -5px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  color: ${(props) => props.theme.color.onPrimaryLight};
  flex-shrink: 0;

  ${(props) => props.theme.media.mobile`
    position: relative;
    top: auto;
    right: auto;
    margin-left: 6px;
    display: block;
  `}
`

export const LangWrapper = styled.div`
  display: flex;
  margin-left: 8px;

  ${(props) => props.theme.media.mobile`
    margin-top: 24px;
    margin-left: 32px;
  `}
`

export const Lang = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 16px;
  background: none;
  border: none;
  border-radius: 4px;

  &:focus {
    outline: none;
    box-shadow: ${(props: StyledProps<{ active: boolean }>) => props.theme.shadow.focus};
  }

  ${(props: StyledProps<{ active: boolean }>) => css`
    color: ${props.active ? props.theme.color.onPrimaryDark : props.theme.color.secondary200};
    pointer-events: ${props.active ? 'none' : 'initial'};
  `};

  ${(props) => props.theme.media.mobile`
    font-size: 24px;
    margin-left: 0;
    margin-right: 16px;

    ${(props: StyledProps<{ active: boolean }>) => css`
      opacity: ${props.active ? 1 : 0.24};
      color: ${props.theme.color.onPrimaryDark};
    `}
  `}
`

export const NavItem = styled(Link)`
  margin-left: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  display: flex;
  align-items: center;

  ${(props) => props.theme.media.smallTablet`
    margin-left: 20px;
  `}

  ${(props) => props.theme.media.mobile`
    margin-top: 24px;
    font-size: 24px;
    position: relative;
  `}
`
export const CanadaFlag = styled(Flag)`
  margin-right: 4px;
  font-size: 20px;
  line-height: 20px;

  ${(props) => props.theme.media.mobile`
    font-size: 24px;
    position: absolute;
    transform: translateX(calc(-100% - 2px));
  `}
`

export const Logo = styled(env.REACT_APP_LOCALE === 'ru' ? LogoImage : LogoImageEn)`
  display: flex;
  align-items: center;
  ${(props) => props.theme.media.smallTablet`
    height: 60px;
  `}
  ${(props) => props.theme.media.mobile`
    height: 48px;
  `}
`

export const ButtonText = styled.span`
  ${(props) => props.theme.media.mobile`
    display: none;
  `}
`

export const MobileNav = styled.div`
  order: -1;
  position: relative;
  margin-right: -15px;
`

export const NavItems = styled.div`
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  padding-top: 64px;

  ${(props: StyledProps<{ isOpen: boolean }>) => css`
    display: ${props.isOpen ? 'flex' : 'none'};
    background-color: ${props.theme.color.secondary200};
  `}
`

export const Close = styled(IconCross)`
  fill: ${(props) => props.theme.color.onPrimaryDark};
  position: absolute;
  top: 17px;
  left: 13px;
`

export const BurgerMenu = styled(IconBurgerMenu)`
  fill: ${(props) => props.theme.color.onPrimaryDark};
  cursor: pointer;
`
