import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount } from '@agro-club/agroclub-shared'

import { Progress } from 'modules/types'

import CollectionActions from './duck'
import CollectionSelectors from './selectors'
import { CompanyDadata, Product } from './types'

export const useCompaniesDadata = (query: string): [Progress, CompanyDadata[]] => {
  const progress = useSelector(CollectionSelectors.getCompaniesFetchProgress)
  const companies = useSelector(CollectionSelectors.getCompanies)
  const fetchAction = useAction(CollectionActions.companiesRequested)
  const resetAction = useAction(CollectionActions.companiesReset)

  useEffect(() => {
    if (query) {
      fetchAction(query)
    } else {
      resetAction()
    }
  }, [fetchAction, query, resetAction])

  return [progress, companies]
}

export const useProducts = (): [Progress, Product[]] => {
  const progress = useSelector(CollectionSelectors.getProductsFetchProgress)
  const products = useSelector(CollectionSelectors.getProducts)
  const fetchAction = useAction(CollectionActions.productsRequested)

  useDidMount(fetchAction)

  return [progress, products]
}
