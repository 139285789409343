import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import {
  IconPencil,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useHistoryPush,
} from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useDateFormat from 'hooks/useDateFormat'
import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useUserBidItemManaged } from 'modules/domain/userBid/hooks'
import CropParameterGroup from 'views/components/CropParameterGroup/CropParameterGroup'
import { ProfileBidOffers } from 'views/pages/Profile/ProfileBidsPage/ProfileBidOffers'
import { SectionSubTitle } from 'views/pages/Profile/ProfileBidsPage/styled'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'
import { ParamGroup, ParamPair } from 'views/ui/ParamGroup/ParamGroup'
import { StyledIconButton } from 'views/ui/styled'

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -24px;
`

const MetaItemWrapper = styled.div`
  margin: 0 24px;
`

const MetaItemTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const MetaItemValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const Meta: React.FC<{ id: string; created: string; updated: string }> = ({ id, created, updated }) => {
  const { t } = useTranslation('ProfileBidItem')
  const dateFormat = useDateFormat()
  return (
    <MetaWrapper>
      <MetaItemWrapper>
        <MetaItemTitle>{t('metaTitleId')}</MetaItemTitle>
        <MetaItemValue>{id}</MetaItemValue>
      </MetaItemWrapper>
      <MetaItemWrapper>
        <MetaItemTitle>{t('metaTitleCreatedAt')}</MetaItemTitle>
        <MetaItemValue>{dateFormat(created)}</MetaItemValue>
      </MetaItemWrapper>
      <MetaItemWrapper>
        <MetaItemTitle>{t('metaTitleUpdatedAt')}</MetaItemTitle>
        <MetaItemValue>{dateFormat(updated)}</MetaItemValue>
      </MetaItemWrapper>
    </MetaWrapper>
  )
}

const ProfileBidItem: React.FC = () => {
  const { t } = useTranslation('ProfileBidItem')
  const { track } = useAnalyticsSSR()
  const push = useHistoryPush()
  const goBack = () => push({ route: ProfileRoutes.ProfileBids })
  const { id } = useParams<{ id: string }>()
  const [, item] = useUserBidItemManaged(id)
  const formatNumber = useFormatNumber()

  useHelmet({ title: item?.product?.title })

  if (!item) {
    return null
  }

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>
          {item.product.title}
          <StyledIconButton
            Icon={IconPencil}
            onClick={() => {
              track('my_offers_offer_edit', { product: item.product.title, id: item.id })
              push({ route: ProfileRoutes.ProfileBidEdit, params: { id } })
            }}
          >
            {t('editButtonText')}
          </StyledIconButton>
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>

      <SectionBody>
        <ProfileBidOffers {...{ item }} />
        <SectionSubTitle>{t('bidDetail')}</SectionSubTitle>
        <ParamGroup>
          <ParamPair title={t('volumeLabel')} value={formatNumber(item.quantity)} />
          <ParamPair title={t('priceExwLabel')} value={formatNumber(item.price_exw, true)} />
          <ParamPair title={t('addressLabel')} value={item?.address?.address || ''} />
        </ParamGroup>
        <CropParameterGroup parameters={item.parameters} title={t('cropParamsTitle')} />
        <Meta id={item.id} updated={item.modified} created={item.created} />
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileBidItem
