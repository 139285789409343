import React from 'react'

import { IconBin } from '@agro-club/agroclub-shared'

import { StyledIconButton } from './styled'

type Props = {
  onClick?: () => void
}

export const DeleteIconButton: React.FC<Props> = ({ onClick }) => (
  <StyledIconButton onClick={() => onClick && onClick()} Icon={IconBin} iconColor="#FF3B30" />
)
