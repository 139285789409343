import { Input } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px -8px -8px;
`
export const EmailContainer = styled.div`
  margin-top: 18px;
`

export const EmailInput = styled(Input)`
  width: 260px;
`

export const InputContainer = styled.div`
  width: 180px;
  margin: 8px;
`

export const UserTypeContainer = styled.div`
  width: 260px;
`
