import React from 'react'
import { useSelector } from 'react-redux'

import { useAction } from '@agro-club/agroclub-shared'

import AuthActions from 'modules/domain/auth/duck'
import AuthSelectors from 'modules/domain/auth/selectors'
import { AvailableCountries } from 'types/entities'
import AccountForm from 'views/components/LoginForm/AccountForm'

import SmsCodeVerificationForm, { SmsCodeVerificationFormProps } from './SmsCodeVerificationForm'

type AccountFormProps = {
  mode: 'phone' | 'email'
  email: string
  phone?: string
  country?: AvailableCountries
}

const LoginForm: React.FC = () => {
  const email = useSelector(AuthSelectors.email)
  const phone = useSelector(AuthSelectors.phone)
  const step = useSelector(AuthSelectors.step)
  const selectedCountry = useSelector(AuthSelectors.selectedCountry)

  const phoneSubmitProgress = useSelector(AuthSelectors.submitCredentialsProgress)

  const suggestedPhone = useSelector(AuthSelectors.suggestedPhone)
  const submitEmailAction = useAction(AuthActions.emailSubmitted)
  const submitPhoneAction = useAction(AuthActions.phoneSubmitted)
  const submitSmsCodeAction = useAction(AuthActions.smsCodeVerificationRequested)

  const handleCredentialsSubmit = (props: AccountFormProps) => {
    if (props.mode === 'email') {
      submitEmailAction(props.email)
    } else if (props.mode === 'phone' && props.phone && props.country) {
      submitPhoneAction(props.phone, props.country)
    }
  }
  const handleSmsVerificationSubmit = (props: SmsCodeVerificationFormProps) => {
    submitSmsCodeAction(phone, props.code)
  }

  let stepContent
  switch (step) {
    case 'credentials':
      stepContent = (
        <AccountForm
          initialValues={{ email, phone, mode: 'phone', country: selectedCountry }}
          onSubmit={handleCredentialsSubmit}
          progress={phoneSubmitProgress}
        />
      )
      break
    case 'sms':
      stepContent = (
        <SmsCodeVerificationForm
          onSubmit={handleSmsVerificationSubmit}
          phone={suggestedPhone}
          country={selectedCountry}
        />
      )
      break
    case 'register':
      return null
  }
  return stepContent
}

export default LoginForm
