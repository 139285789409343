import { Segment } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

const FlatSegment = styled(Segment)`
  border-color: ${(props) => props.theme.color.primary600};
  height: 34px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`
export default FlatSegment
