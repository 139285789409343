import React from 'react'

import { IconButtonProps, IconButton as UIIconButton } from '@agro-club/agroclub-shared'

import Link from '../../components/Link/Link'

type Props = {
  to?: string | { pathname: string; state: { [key: string]: any } }
}

const IconButton: React.FC<Props & IconButtonProps> = ({ to, ...rest }) => {
  if (to) {
    return (
      <Link to={to}>
        <UIIconButton {...rest} button={false} />
      </Link>
    )
  }
  return <UIIconButton {...rest} />
}

export default IconButton
