import { createSelector } from 'reselect'

import { EntityMetadata } from 'modules/domain/types'

import { AppGlobalState, Progress } from '../../types'
import { UserBid } from './types'

const dict = (state: AppGlobalState) => state.userBid.items
const ids = (state: AppGlobalState) => state.userBid.ids
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const item = (state: AppGlobalState, id?: string): UserBid | undefined =>
  id ? state.userBid.items[id] : undefined
const meta = (state: AppGlobalState, id?: string): EntityMetadata => {
  const tmpl = {
    fetchError: null,
    fetchProgress: Progress.IDLE,
    removeError: null,
    removeProgress: Progress.IDLE,
    updateError: null,
    updateProgress: Progress.IDLE,
    id,
  }

  return id ? state.userBid.meta[id] ?? tmpl : tmpl
}

const itemFetchProgress = (state: AppGlobalState) => state.userBid.itemFetchProgress
const itemUpdateProgress = (state: AppGlobalState, id: string) =>
  state.userBid.meta[id]?.updateProgress || Progress.IDLE
const listFetchProgress = (state: AppGlobalState) => state.userBid.listFetchProgress
const listFetchError = (state: AppGlobalState) => state.userBid.listFetchError
const listFetchNextProgress = (state: AppGlobalState) => state.userBid.listFetchNextProgress
const listFetchNextError = (state: AppGlobalState) => state.userBid.listFetchNextError
const list = createSelector(dict, ids, listFetchProgress, (dict, ids, progress) => {
  if (progress === Progress.WORK) {
    return []
  }
  const result: UserBid[] = []
  for (const id of ids) {
    result.push(dict[id])
  }
  return result
})

const filter = (state: AppGlobalState) => state.userBid.filter
const sorting = (state: AppGlobalState) => state.userBid.sorting
const page = (state: AppGlobalState) => state.userBid.page
const total = (state: AppGlobalState) => state.userBid.total
const updateProgress = (state: AppGlobalState) => state.userBid.updateProgress
const removeProgress = (state: AppGlobalState) => state.userBid.removeProgress
const addProgress = (state: AppGlobalState) => state.userBid.addProgress
const hasNext = (state: AppGlobalState) => state.userBid.total > state.userBid.ids.length
const pageSize = (state: AppGlobalState) => state.userBid.pageSize
const pages = (state: AppGlobalState) => Math.ceil(state.userBid.total / state.userBid.pageSize)

const bidDeals = (state: AppGlobalState, id: string) => state.userBid.bidDeals?.[id]?.deals
const bidDealsFetchProgress = (state: AppGlobalState, id: string) =>
  state.userBid.bidDeals?.[id]?.fetchProgress || Progress.IDLE
const bidDealByIds = (state: AppGlobalState, bidId?: string, dealId?: string) =>
  bidId && dealId
    ? state.userBid.bidDeals?.[bidId]?.deals?.find((deal) => String(deal.id) === String(dealId))
    : undefined

const profileBidOfferSendProgress = (state: AppGlobalState, bidId?: string, dealId?: string) =>
  bidId && dealId
    ? state.userBid.bidDeals?.[bidId]?.offer?.[dealId]?.sendProgress || Progress.IDLE
    : Progress.IDLE

const UserBidSelectors = {
  filter,
  sorting,
  page,
  total,
  updateProgress,
  removeProgress,
  addProgress,
  item,
  meta,
  itemFetchProgress,
  itemUpdateProgress,
  listFetchProgress,
  listFetchError,
  list,
  dict,
  ids,
  hasNext,
  pageSize,
  listFetchNextProgress,
  listFetchNextError,
  pages,
  bidDeals,
  bidDealsFetchProgress,
  bidDealByIds,
  profileBidOfferSendProgress,
}

export default UserBidSelectors
