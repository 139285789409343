import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  #drawer {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    overflow-y: auto;
    &[data-open='true']{
      display: flex;
    }
  }

  a {
    color: ${(props) => props.theme.color.primary600};
  }

`

export default GlobalStyle
