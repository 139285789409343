import React from 'react'
import ReactDOM from 'react-dom'

import { loadableReady } from '@loadable/component'
import { createBrowserHistory } from 'history'

import App from 'App'
import env from 'env'
import i18n from 'i18n'
import analyticsInstance from 'initAnalytics'
import { configureStore } from 'modules/configureStore'
import rootSaga from 'modules/rootSaga'
import { Sentry } from 'sentry'
import ApiService from 'service/api/api'
import TokenService from 'service/token/browser'
import * as serviceWorker from 'serviceWorker'

const main = async () => {
  const tokenService = new TokenService()
  tokenService.init(window.__cookies)

  const apiService = new ApiService({
    tokenService,
    baseURL: env.API_BASE_URL,
  })
  const { lang } = document.documentElement
  apiService.setLanguage(lang)
  apiService.on('response', Sentry.handleAxiosResponse)
  apiService.on('responseError', Sentry.handleAxiosError)
  apiService.on('request', Sentry.handleAxiosRequest)
  apiService.on('requestError', Sentry.handleAxiosError)

  if (window.Cypress) {
    window.__apiService = apiService
    window.__tokenService = tokenService
  }

  const history = createBrowserHistory({ basename: env.BASE_ROUTER_PATH })

  i18n.on('languageChanged', (lang) => {
    document.documentElement.lang = lang
    const { pathname, search } = history.location
    apiService.setLanguage(lang)
    // reload route to get data with different accept-language header
    history.push({ pathname, search })
  })

  const [store, sagaMiddleware] = await configureStore({
    history,
    initialState: { ...(window.__initial_state__ || {}), ...(window.__cy_state_override__ || {}) },
    apiService,
    tokenService,
    analyticsInstance,
  })
  sagaMiddleware.run(rootSaga)

  await Sentry.init()
  ReactDOM.hydrate(<App store={store} history={history} />, document.getElementById('root'))
}

loadableReady(() => {
  main()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
