import { initReactI18next } from 'react-i18next'

import { i18nComposeWithUIComponents } from '@agro-club/agroclub-shared'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import env from 'env'
import en from 'translations/en.json'
import pt_BR from 'translations/pt_BR.json'
import ru from 'translations/ru.json'
import { AvailableLanguages } from 'types/entities'

const locale = env.REACT_APP_LOCALE

const resources = i18nComposeWithUIComponents({
  ...(locale === AvailableLanguages.pt_BR
    ? {
        [AvailableLanguages.pt_BR]: pt_BR,
        [AvailableLanguages.en]: en,
      }
    : {
        [AvailableLanguages.ru]: ru,
      }),
})

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: locale,
    supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
  })
  .catch((e) => {
    throw e
  })

export default i18n
