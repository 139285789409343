import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { SectionBody, SectionContainer, SectionTitle, useHistoryPush } from '@agro-club/agroclub-shared'

import useHelmet from 'hooks/useHelmet'
import { useUserBidItemManaged } from 'modules/domain/userBid/hooks'
import { BidEditForm } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidEditForm'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'

export const BidEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation('bid')
  const [, bid] = useUserBidItemManaged(id)
  const push = useHistoryPush()
  const goBack = () => push({ route: ProfileRoutes.ProfileBidItem, params: { id } })

  useHelmet({ title: `${t('form.edit')} ${bid?.product?.title}` })

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>
          {t('form.edit')} {bid?.product?.title}
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody paddedOnMobile>{bid && <BidEditForm bid={bid} />}</SectionBody>
    </SectionContainer>
  )
}
