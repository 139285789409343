import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import AuthSelectors from 'modules/domain/auth/selectors'
import { UserProfile } from 'modules/domain/auth/types'
import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { ListResponse } from 'types/api'
import ProfileRoutes from 'views/pages/Profile/routes'

import UserBidActions from './duck'
import * as managers from './managers'
import UserBidSelectors from './selectors'
import { BidDeal, UserBid, UserBidFilterStatus } from './types'

export const fetchList = function* () {
  try {
    let currentPage = yield select(UserBidSelectors.page)
    // const filter = yield select(UserBidSelectors.filter)
    const filter = {
      status: UserBidFilterStatus.Published,
    }
    const sorting = yield select(UserBidSelectors.sorting)
    const pageSize = yield select(UserBidSelectors.pageSize)

    let response: ListResponse<UserBid> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, current, count } = response
    yield put(UserBidActions.listRequestSucceed(results, count, current))

    // yield call(updateLocationQuery, UserBidRoutes.List, { page: currentPage })
  } catch (err) {
    yield put(UserBidActions.listRequestFailed())
  }
}

export const fetchListNext = function* () {
  try {
    const page = yield select(UserBidSelectors.page)
    const filter = yield select(UserBidSelectors.filter)
    const sorting = yield select(UserBidSelectors.sorting)
    const pageSize = yield select(UserBidSelectors.pageSize)
    const { results, count }: ListResponse<UserBid> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(UserBidActions.listRequestNextSucceed(results, count))
  } catch (err) {
    yield put(UserBidActions.listRequestNextFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof UserBidActions.itemRequested>) {
  try {
    const item: UserBid = yield call(apiCall, managers.getItem, id)
    yield put(UserBidActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(UserBidActions.itemRequestFailed(id))
  }
}

export const fetchBidDeals = function* ({ payload: id }: ReturnType<typeof UserBidActions.itemRequested>) {
  try {
    yield put(UserBidActions.bidDealRequested(id))
    const items: BidDeal[] = yield call(apiCall, managers.getUserBidDeals, id)
    yield put(UserBidActions.bidDealRequestSucceed(id, items))
  } catch (err) {
    yield put(UserBidActions.bidDealRequestFailed(id))
  }
}

export const addItem = function* ({ payload: { dto } }: ReturnType<typeof UserBidActions.addRequested>) {
  try {
    const companyId: UserProfile = yield select(AuthSelectors.userCompanyId)
    const item: UserBid = yield call(apiCall, managers.addItem, { ...dto, company: companyId })
    yield put(UserBidActions.addSucceed(item))
  } catch (err) {
    yield put(UserBidActions.addFailed())
  }
}
export const updateItem = function* ({
  payload: [id, dto],
}: ReturnType<typeof UserBidActions.updateRequested>) {
  try {
    yield call(apiCall, managers.updateItem, id, dto)
    const item: UserBid = yield call(apiCall, managers.getItem, id)
    yield put(UserBidActions.updateSucceed(item))
  } catch (err) {
    yield put(UserBidActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof UserBidActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(UserBidActions.removeSucceed(payload))
  } catch (err) {
    yield put(UserBidActions.removeFailed(payload))
  }
}

const routeWatcher = makeRouteWatcher(ProfileRoutes, function* ({ silent, match, params }) {
  if (silent) {
    return
  }

  switch (match) {
    case ProfileRoutes.ProfileBids: {
      return yield put(UserBidActions.listRequested({}))
    }
    case ProfileRoutes.ProfileBidOffer:
    case ProfileRoutes.ProfileBidEdit:
    case ProfileRoutes.ProfileBidItem: {
      return yield put(UserBidActions.itemRequested(params.id))
    }
  }
})

export const sendProfileBidOffer = function* ({
  payload: [bidId, dealId, dto],
}: ReturnType<typeof UserBidActions.sendBidOfferRequested>) {
  try {
    yield call(apiCall, managers.sendProfileBidOffer, dealId, dto)
    yield put(UserBidActions.sendBidOfferSuccess(bidId, dealId))
  } catch (err) {
    yield put(UserBidActions.sendBidOfferError(bidId, dealId))
  }
}

const UserBidSaga = function* () {
  yield all([
    takeLatest(UserBidActions.itemRequested.type, fetchItem),
    takeLatest(UserBidActions.itemRequested.type, fetchBidDeals),
    takeLatest(UserBidActions.listRequested.type, fetchList),
    takeLatest(UserBidActions.filterUpdated.type, fetchList),
    takeLatest(UserBidActions.sortingUpdated.type, fetchList),
    takeLatest(UserBidActions.filterHasBeenReset.type, fetchList),
    takeLatest(UserBidActions.sortingHasBeenReset.type, fetchList),

    takeLatest(UserBidActions.listRequestedNext.type, fetchListNext),

    takeLatest(UserBidActions.addRequested.type, addItem),
    takeLatest(UserBidActions.updateRequested.type, updateItem),
    takeLatest(UserBidActions.removeRequested.type, removeItem),

    takeLatest(UserBidActions.sendBidOfferRequested.type, sendProfileBidOffer),

    fork(routeWatcher),
  ])
}

export default UserBidSaga
