import React, { VFC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import useLanguage from 'hooks/useLanguage'
import { AvailableLanguages } from 'types/entities'
import { LanguageSelect } from 'views/ui/LanguageSelect/LanguageSelect'

const LOCALES_COUNTRY = {
  [AvailableLanguages.pt_BR]: 'BR',
  [AvailableLanguages.en]: 'US',
}

export const I18nLangSelect: VFC = () => {
  const locale = useLanguage()
  const { i18n } = useTranslation()

  const changeLanguage = useCallback(
    (code) => {
      const nextLocale = Object.keys(LOCALES_COUNTRY).find((key) => LOCALES_COUNTRY[key] === code)
      i18n.changeLanguage(nextLocale).catch((e) => {
        console.error(e)
      })
    },
    [i18n],
  )

  return (
    <LanguageSelect
      value={LOCALES_COUNTRY[locale]}
      codes={Object.values(LOCALES_COUNTRY)}
      onChange={changeLanguage}
    />
  )
}
