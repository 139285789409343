import React, { useCallback, useState } from 'react'

import { useScrollTrigger } from '@agro-club/agroclub-shared'
import styled, { css } from 'styled-components'

import useMatchMedia from 'hooks/useMatchMedia'
import { queries, theme } from 'theme/theme'

const paddings = css`
  padding-left: 80px;
  padding-right: 80px;

  ${(props) => props.theme.media.smallDesktop`
    padding-left: 40px;
    padding-right: 40px;
  `}
  ${(props) => props.theme.media.tablet`
    padding-left: 32px;
    padding-right: 32px;
  `}
  ${(props) => props.theme.media.mobile`
    padding-left: 0;
    padding-right: 0;
  `}
`

const RootStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.backgroundBase};

  @supports (-webkit-touch-callout: none) {
    /* hack for Safari */
    min-height: -webkit-fill-available;
  }
`

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  background: ${(props) => props.theme.color.onPrimaryLight};
  height: ${(props) => props.theme.size.headerHeight}px;
  transition: 0.2s box-shadow;

  ${paddings}

  ${(props) => props.theme.media.mobile`
    height: ${(props) => props.theme.size.mobileHeaderHeight}px;
  `}

  [data-scrolled='true'] & {
    box-shadow: 0 0 30px -10px ${(props) => props.theme.color.secondary200};
  }
`

const ContentStyled = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &::before {
    content: '';
    display: block;
    height: ${(props) => props.theme.size.headerHeight}px;
    ${(props) => props.theme.media.mobile`
      height: ${(props) => props.theme.size.mobileHeaderHeight}px;
    `}
  }
`

const ArticleStyled = styled.article``
const ArticleInner = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: calc(${(props) => props.theme.size.maxContentWidth}px + 160px);
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
  ${paddings};
  ${(props) => props.theme.media.smallDesktop`
     max-width: calc(${(props) => props.theme.size.maxContentWidth}px + 80px);
  `}
  ${(props) => props.theme.media.tablet`
    margin-bottom: 20px;
  `}
  ${(props) => props.theme.media.mobile`
    &::before {
      height: ${(props) => props.theme.size.mobileHeaderHeight}px;
    }
  `}
`

export const Article: React.FC<{ className?: string }> = ({ className, children }) => (
  <ArticleStyled className={className}>
    <ArticleInner>{children}</ArticleInner>
  </ArticleStyled>
)

const FooterStyled = styled.footer`
  flex-shrink: 0;
  background: ${(props) => props.theme.color.backgroundSecondary};
  ${paddings}
`

export const Root: React.FC = ({ children }) => {
  const isMobile = useMatchMedia(queries.mobile)
  const [isScrolled, setIsScrolled] = useState(false)
  const handleScrollDown = useCallback(() => setIsScrolled(false), [])
  const handleScrollUp = useCallback(() => setIsScrolled(true), [])
  const delta = (isMobile ? theme.size.mobileHeaderHeight : theme.size.headerHeight) * 0.3
  const { anchor } = useScrollTrigger(handleScrollDown, handleScrollUp, delta)
  return (
    <RootStyled data-scrolled={isScrolled}>
      {anchor}
      {children}
    </RootStyled>
  )
}

export const Header: React.FC = ({ children }) => <HeaderStyled id="page-header">{children}</HeaderStyled>

export const Main: React.FC = ({ children }) => <ContentStyled id="page-main">{children}</ContentStyled>

export const Footer: React.FC = ({ children }) => <FooterStyled id="page-footer">{children}</FooterStyled>
