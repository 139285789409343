import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, Progress, useAction, useHistoryPush } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import { Product } from 'modules/domain/collection/types'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import UserBidActions from 'modules/domain/userBid/duck'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import { UserBidParameter, UserBidParameterCondition } from 'modules/domain/userBid/types'
import { CurrentUserAddressSelect } from 'views/components/CurrentUserAddressSelect/CurrentUserAddressSelect'
import { BottomRemark } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BottomRemark'
import { CropsParam } from 'views/pages/Profile/ProfileBidsPage/BidAdd/CropsParam'
import { Params } from 'views/pages/Profile/ProfileBidsPage/BidAdd/Params'
import { ProductSelect } from 'views/pages/Profile/ProfileBidsPage/BidAdd/ProductSelect'
import { LabeledContainer } from 'views/pages/Profile/ProfileBidsPage/BidAdd/styled'
import ProfileRoutes from 'views/pages/Profile/routes'
import { FormSection } from 'views/pages/Profile/styled'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

import * as S from './styled'

export type EditableFields = {
  product?: string
  price?: number
  quantity?: number
  address?: string
  mainParameter: UserBidParameter
  parameters: UserBidParameter[]
}

export const BidAddForm: React.FC = () => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('bid')
  const [product, setProduct] = useState<Product>()
  const createAction = useAction(UserBidActions.addRequested)
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const progress = useSelector(UserBidSelectors.addProgress)
  useNotificationProgress(progress)

  const selectedAddressId = useSelector(UserAddressSelectors.selectedId)
  const [initialAddressId] = useState(selectedAddressId || undefined) // 💩

  const push = useHistoryPush()
  const prevProgress = usePrevious(progress)

  const profileType = useSelector(AuthSelectors.profileType)
  const isCustomer = profileType === ProfileType.customer

  useEffect(() => {
    if (progress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      push({ route: ProfileRoutes.ProfileBidAddSuccess })
    }
  }, [prevProgress, progress, push])

  const validationSchema = useMemo(
    () =>
      Yup.object({
        address: Yup.string().required(),
        product: Yup.string().required(),
        price: Yup.string().required(),
        quantity: Yup.string().required(),
      }),
    [],
  )

  const formik = useFormik<EditableFields>({
    validationSchema,
    initialValues: {
      address: initialAddressId,
      product: undefined,
      price: undefined,
      quantity: undefined,
      parameters: [],
      mainParameter: {
        parameter: undefined,
        condition: Object.keys(UserBidParameterCondition)[1] as keyof typeof UserBidParameterCondition,
        parameter_value: undefined,
      },
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { quantity, price, mainParameter, product, parameters, address } = formik.values
      const dto = {
        quantity,
        price,
        product,
        address,
        parameters: [...parameters, mainParameter].filter((p) => typeof p.parameter_value !== 'undefined'),
      }
      createAction({ dto })
    },
  })

  const productSelectError = formik.touched.product && formik.errors.product ? formik.errors.product : null
  const addressSelectError = formik.touched.address && formik.errors.address ? formik.errors.address : null

  const onSaveBtnClick = () => {
    track('offer_publish_tap')
    formik.submitForm()
  }

  return (
    <>
      <S.Section>
        <S.Label>{t('form.product')}</S.Label>
        <S.Cell>
          <ProductSelect
            invalid={!!productSelectError}
            selected={formik.values.product}
            onProductChange={(selectedProduct) => {
              formik.setValues({
                ...formik.values,
                product: selectedProduct.id,
                parameters: selectedProduct.parameters
                  .filter((p) => p.id !== selectedProduct?.main_parameter?.id)
                  .map((p) => ({
                    parameter: p.id,
                    condition: Object.keys(
                      UserBidParameterCondition,
                    )[1] as keyof typeof UserBidParameterCondition,
                    parameter_value: undefined,
                  })),
                mainParameter: {
                  parameter: selectedProduct?.main_parameter?.id,
                  condition: Object.keys(
                    UserBidParameterCondition,
                  )[1] as keyof typeof UserBidParameterCondition,
                  parameter_value: undefined,
                },
              })
              setProduct(selectedProduct)
            }}
          />
        </S.Cell>
        <S.Label>{t('form.quantity')}</S.Label>
        <S.Cell>
          <S.InputWrapper>
            <NumberInput
              isInteger
              invalid={formik.touched.quantity && !!formik.errors.quantity}
              value={formik.values.quantity}
              onChange={(v) => formik.setFieldValue('quantity', v)}
            />
          </S.InputWrapper>
        </S.Cell>
        <div>
          <S.Label>{t(isCustomer ? 'form.price_customer' : 'form.price', { currency })}</S.Label>
          <S.LabelHelpText>{t('form.without_nds')}</S.LabelHelpText>
        </div>
        <S.PriceWrapper>
          <S.InputWrapper>
            <NumberInput
              invalid={formik.touched.price && !!formik.errors.price}
              value={formik.values.price}
              onChange={(v) => {
                formik.setFieldValue('price', v)
              }}
              max={1000}
            />
          </S.InputWrapper>
          <S.PriceRemark>{t(isCustomer ? 'form.priceRemark_customer' : 'form.priceRemark')}</S.PriceRemark>
        </S.PriceWrapper>
        <S.Label>{t('form.userAddress')}</S.Label>
        <S.Cell>
          <CurrentUserAddressSelect
            placeholder={t('form.userAddressPlaceholder')}
            noOptionsMessage={() => t('form.noAddressMessage')}
            errorText={t('form.userAddressError')}
            invalid={!!addressSelectError}
            onChange={(address) => {
              formik.setFieldValue('address', address.id)
              formik.setFieldTouched('address')
            }}
          />
        </S.Cell>
      </S.Section>

      {(product?.parameters?.length || product?.main_parameter) && (
        <FormSection title={t('form.cropsParams')}>
          {product?.main_parameter && (
            <LabeledContainer label={t('form.mainParameter')}>
              <CropsParam
                index={0}
                onChange={(_, values) => formik.setFieldValue(`mainParameter`, values)}
                isMain
                parameter={formik.values.mainParameter as UserBidParameter}
                parameters={product.parameters}
                valueIsInvalid={
                  formik.touched.mainParameter?.parameter_value &&
                  !!formik.errors.mainParameter?.parameter_value
                }
              />
            </LabeledContainer>
          )}
          <Params
            {...{
              formik,
              product,
            }}
          />
        </FormSection>
      )}

      <S.ButtonsWrapper>
        <div>
          <Button
            disabled={!formik.dirty}
            filled
            intent="primary"
            onClick={onSaveBtnClick}
            progress={progress}
          >
            {t('common:save')}
          </Button>
        </div>
        <BottomRemark />
      </S.ButtonsWrapper>
    </>
  )
}
