import { originalSourcePlugin } from '@analytics/original-source-plugin'
import segmentPlugin from '@analytics/segment'
import Analytics, { AnalyticsPlugin } from 'analytics'

import env from 'env'

const plugins: AnalyticsPlugin[] = env.SEGMENT_WRITE_KEY
  ? [
      originalSourcePlugin(),
      segmentPlugin({
        writeKey: env.SEGMENT_WRITE_KEY,
        disableAnonymousTraffic: false,
      }),
    ]
  : []

const analyticsInstance = Analytics({
  app: 'Enterprise Market',
  version: env.BUILD_VERSION ?? undefined,
  plugins,
})

export default analyticsInstance
