import React from 'react'

import { Input } from '@agro-club/agroclub-shared'
import { CSSProperties } from 'styled-components'

type InputProps = {
  label?: string
  onChange: (value?: string | number) => void
  value?: string | number
  inputStyles?: CSSProperties
  invalid?: boolean
  errorText?: string
  limit?: number
  passwordVisible?: boolean
  onPasswordVisibleChange?: (value: boolean) => void
  required?: boolean
  optional?: boolean
  inputSize?: 'thin' | 'small' | 'medium' | 'big'
  name?: string
}

type AdditionalProps = {
  isInteger?: boolean
  max?: number
}

export type NumberInputProps = InputProps &
  AdditionalProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value' | 'max'>

const integerRegExp = /^[0-9]+$/

const handleIntegerValidationOnChange = (v, onChange, max?: number) => {
  if (v === '') {
    onChange(v)
  } else if (integerRegExp.test(v)) {
    const value = parseInt(v, 10)
    onChange(max !== undefined ? Math.min(value, max) : value)
  }
}

const floatRegExp = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/

const handleFloatValidationOnChange = (v, onChange, max?: number) => {
  let value = v.replace(/,+/g, '.')
  if (value === '' || value.endsWith('.') || (value.includes('.') && value.endsWith('0'))) {
    onChange(value)
  } else if (floatRegExp.test(value)) {
    value = parseFloat(value)
    onChange(max !== undefined ? Math.min(value, max) : value)
  }
}

export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { onChange, max, isInteger, ...rest } = props

  return isInteger ? (
    <Input onChange={(_e, v) => handleIntegerValidationOnChange(v, onChange, max)} {...rest} />
  ) : (
    <Input onChange={(_e, v) => handleFloatValidationOnChange(v, onChange, max)} {...rest} />
  )
}
