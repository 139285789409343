import React, { useContext, useEffect } from 'react'

import env from 'env'

export type HelmetProps = {
  title?: string
  description?: string
  ogImage?: string
}

export const HelmetContext = React.createContext<{
  helmet: HelmetProps
  updateHelmet: (helmet: HelmetProps) => void
}>({
  helmet: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateHelmet: () => {},
})

let _useHelmet
if (env.BROWSER) {
  _useHelmet = ({ title, description, ogImage }: HelmetProps) => {
    const { updateHelmet } = useContext(HelmetContext)
    useEffect(() => {
      updateHelmet({ title, description, ogImage })
    }, [title, description, ogImage, updateHelmet])
  }
} else {
  _useHelmet = ({ title, description, ogImage }: HelmetProps) => {
    const { updateHelmet } = useContext(HelmetContext)
    updateHelmet({ title, description, ogImage })
  }
}

const useHelmet = _useHelmet
export default useHelmet
