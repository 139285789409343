import { all, call, delay, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects'

import { CancelToken, apiCall, apiCallCancellable } from 'modules/sagaEffects'

import CollectionActions from './duck'
import * as managers from './managers'

export const fetchCompaniesSaga = function* ({
  type: actionType,
  payload: query,
}: ReturnType<typeof CollectionActions.companiesRequested>) {
  try {
    const { needToDebounce } = yield race({
      timeout: delay(200),
      needToDebounce: take(actionType),
    })

    if (needToDebounce) {
      return
    }

    const cancelToken = new CancelToken()
    const pendingReq = apiCallCancellable(managers.fetchCompanies, cancelToken, query)
    const { response, cancel } = yield race({
      response: call(() => pendingReq),
      cancel: take(actionType),
    })
    if (cancel) {
      cancelToken.cancel()
      return
    }
    yield put(CollectionActions.companiesRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.companiesRequestFailed())
  }
}

export const fetchProductsSaga = function* () {
  try {
    const response = yield call(apiCall, managers.fetchProducts)
    yield put(CollectionActions.productsRequestSucceed(response))
  } catch (err) {
    yield put(CollectionActions.productsRequestFailed())
  }
}

const CollectionSaga = function* () {
  yield all([
    takeLatest(CollectionActions.productsRequested.type, fetchProductsSaga),
    takeEvery(CollectionActions.companiesRequested.type, fetchCompaniesSaga),
  ])
}

export default CollectionSaga
