import React from 'react'

import { Segment, SimpleSelect } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { ProductParameter } from 'modules/domain/collection/types'
import { UserBidParameter, UserBidParameterCondition } from 'modules/domain/userBid/types'
import { CrossButton } from 'views/ui/CrossButton/CrossButton'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 79px 96px 20px;
  grid-gap: 8px;
  align-items: center;
`

type Props = {
  parameters: ProductParameter[]
  parameter: UserBidParameter
  isMain?: boolean
  onRemove?: (index?: number) => void
  index?: number
  onChange: (index: number | undefined, values: UserBidParameter) => void
  valueIsInvalid?: boolean
}

export const CropsParam: React.FC<Props> = (props) => {
  const { parameter, parameters, isMain, onRemove, index, onChange, valueIsInvalid } = props

  return (
    <Wrapper>
      <SimpleSelect
        isDisabled={isMain}
        options={parameters.map((p) => ({ id: p.id, title: p.name }))}
        value={parameter.parameter}
        onChange={(v: unknown) => onChange && onChange(index, { ...parameter, parameter: v as string })}
      />
      <Segment
        items={Object.entries(UserBidParameterCondition).map(([key, value]) => ({
          id: key,
          title: value,
        }))}
        value={parameter.condition}
        onChange={(v: string) =>
          onChange && onChange(index, { ...parameter, condition: v as UserBidParameter['condition'] })
        }
      />
      <NumberInput
        invalid={valueIsInvalid}
        onChange={(v) => {
          onChange &&
            onChange(index, { ...parameter, parameter_value: v as UserBidParameter['parameter_value'] })
        }}
        value={parameter.parameter_value}
      />
      {!isMain && <CrossButton onClick={() => onRemove && onRemove(index)} />}
    </Wrapper>
  )
}
