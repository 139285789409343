import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import UserBidSelectors from 'modules/domain/userBid/selectors'
import { UserBid } from 'modules/domain/userBid/types'
import { AppGlobalState } from 'modules/types'
import { ProfileBidOffer } from 'views/pages/Profile/ProfileBidsPage/ProfileBidOffer'
import { NumberBadge, SectionSubTitle } from 'views/pages/Profile/ProfileBidsPage/styled'

const SectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div:not(:last-child) {
    margin-right: 10px;
  }
  margin-bottom: 4px;
`

const Note = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
`

const Offers = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, 226px);
  grid-auto-flow: column;
  grid-auto-columns: 226px;
  overflow-x: auto;
  max-width: 710px;
  padding: 24px 0 16px;
`

type Props = {
  item: UserBid
}

export const ProfileBidOffers: React.FC<Props> = (props) => {
  const { item } = props
  const { t } = useTranslation('ProfileBidItem')
  const deals = useSelector((state: AppGlobalState) => UserBidSelectors.bidDeals(state, item.id))
  const profileType = useSelector(AuthSelectors.profileType)
  const count = deals?.length || 0

  if (!count) {
    return null
  }

  return (
    <div>
      <SectionTitleWrapper>
        <SectionSubTitle>{t(`bidOffers_${profileType}`)}</SectionSubTitle>
        <NumberBadge>{count}</NumberBadge>
      </SectionTitleWrapper>
      <Note>
        {t('bidOffersNote', {
          profileType:
            profileType === ProfileType.seller ? t('bidOffersNoteCustomer') : t('bidOffersNoteSeller'),
        })}
      </Note>

      <Offers>
        {deals?.map((deal) => (
          <ProfileBidOffer key={deal.id} deal={deal} />
        ))}
      </Offers>
    </div>
  )
}
