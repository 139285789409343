import { LoadableComponent } from '@loadable/component'

import BidItemRoutes from 'views/pages/BidItem/routes'
import BidOfferRoutes from 'views/pages/BidOffer/routes'

import BidsRoutes from './Bids/routes'
import MainRoutes from './Main/routes'
import ProfileRoutes from './Profile/routes'
import SignInRoutes from './SignIn/routes'
import SignUpRoutes from './SignUp/routes'

export const Routes = {
  ...MainRoutes,
  ...SignUpRoutes,
  ...SignInRoutes,
  ...ProfileRoutes,
  ...BidsRoutes,
  ...BidItemRoutes,
  ...BidOfferRoutes,
}

export const Loadables: Record<string, LoadableComponent<any>> = {}
