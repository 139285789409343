import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useParams } from 'react-router-dom'

import { useOfferVolumeText } from 'hooks/useOfferVolumeText'
import { useBidItemManaged } from 'modules/domain/bid/hooks'
import { Routes } from 'views/pages/routes'
import Breadcrumbs from 'views/ui/Breadcrumbs/Breadcrumbs'

const useBidOfferBreadcrumbs = () => {
  const { t } = useTranslation('BidOffer')
  const params = useParams<{ id: string }>()
  const [, item] = useBidItemManaged(params.id)
  const headerText = useOfferVolumeText(item?.bid_type)
  return (
    <Breadcrumbs
      items={[
        {
          title: item?.product?.title || '',
          path: item?.product?.slug
            ? generatePath(Routes.Bids, { product: item?.product?.slug || '' })
            : undefined,
        },
        {
          title: t('bidId', { id: item?.id }),
          path: item?.id ? generatePath(Routes.BidItem, { id: item?.id }) : undefined,
        },
        {
          title: headerText,
        },
      ]}
    />
  )
}

export default useBidOfferBreadcrumbs
