import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import env from 'env'
import useBidTypeByProfile from 'hooks/useBidTypeByProfile'
import useFormatNumber from 'hooks/useFormatNumber'
import AuthSelectors from 'modules/domain/auth/selectors'
import { Bid, BidType } from 'modules/domain/bid/types'
import { useIsPriceHidden } from 'views/pages/Bids/hooks'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

const AdditionDataPrice = styled.div<{ current: boolean }>`
  font-size: 14px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  font-weight: ${(props) => (props.current ? 'bold' : '500')};
  line-height: ${(props) => (props.current ? '24px' : '20px')};
`

const AdditionDataDistance = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

export const PriceDataSkeleton = () => (
  <>
    <SkeletonText $width={65} $height={24} />
    <SkeletonText $width={65} $height={20} />
    <SkeletonText $width={60} $height={20} />
  </>
)

export const PriceData: React.FC<{ item: Bid }> = ({ item }) => {
  const { t } = useTranslation('BidsMosaic')
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const { isCptHidden, isExwHidden } = useIsPriceHidden()
  const formatNumber = useFormatNumber()
  const priceExw = item.price_exw
  const priceCpt = item.price_cpt
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  const defaultBidType = useBidTypeByProfile()

  return (
    <>
      {!isExwHidden && (
        <AdditionDataPrice current>
          {defaultBidType === BidType.purchase ? '~' : ''}
          {formatNumber(priceExw, true) || '-'} {t('prices.exw', { currency })}
        </AdditionDataPrice>
      )}
      {!isCptHidden && (
        <AdditionDataPrice current>
          {defaultBidType === BidType.sale ? '~' : ''}
          {formatNumber(priceCpt, true) || '-'} {t('prices.cpt', { currency })}
        </AdditionDataPrice>
      )}
      <AdditionDataDistance>
        {t('quantity')} : {formatNumber(item.quantity) || '-'} {t('common:t')}
      </AdditionDataDistance>

      {isAuthenticated && (
        <AdditionDataDistance>
          {t('distance')} : {formatNumber(item.distance) || '-'} {t('common:km')}
        </AdditionDataDistance>
      )}
    </>
  )
}
