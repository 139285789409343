import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { UploadManagerState, UploadingFile } from 'modules/domain/uploadManager/types'

const initialState: UploadManagerState = {
  files: {},
}

class UploadManagerReducer extends ImmerReducer<UploadManagerState> {
  private updateFile(id: string, cb: (file: UploadingFile) => void) {
    if (this.draftState.files[id]) {
      cb(this.draftState.files[id])
    } else {
      console.warn(`Upload manager: Unknown file id ${id}!`)
    }
  }

  // eslint-disable-next-line immer-reducer/no-optional-or-default-value-params
  fileUploadStarted(id: string, file: File, _endpoint?: string) {
    this.draftState.files[id] = {
      file,
      id,
      percent: 0,
      status: 'uploading',
      retryAttempts: 0,
      responseData: null,
    }
  }

  fileUploadProgressTicked(id: string, percent: number) {
    this.updateFile(id, (file) => ({
      ...file,
      percent,
    }))
  }

  fileUploadCompleted(id: string, apiURL: string, responseData: unknown) {
    this.updateFile(id, (file) => ({
      ...file,
      percent: 100,
      status: 'complete',
      apiURL,
      responseData,
    }))
  }

  fileUploadCancelled(id: string) {
    delete this.draftState.files[id]
  }

  fileUploadFailed(id: string) {
    this.updateFile(id, (file) => ({
      ...file,
      status: 'error',
    }))
  }
}

export const UploadManagerActions = createActionCreators(UploadManagerReducer)
export default UploadManagerActions
export const reducer = createReducerFunction(UploadManagerReducer, initialState)
