import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import useHelmet from 'hooks/useHelmet'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import { CommonSuccess } from 'views/components/CommonSuccess/CommonSuccess'
import { BidAddPage } from 'views/pages/Profile/ProfileBidsPage/BidAdd/BidAddPage'

export const BidAddSuccess: React.FC = () => {
  const profileType = useSelector(AuthSelectors.profileType)
  const { t } = useTranslation('bid')

  useHelmet({ title: t('form.successTitle') })

  return (
    <BidAddPage>
      <CommonSuccess
        title={t('form.successTitle')}
        description={t('form.successDescription', {
          profileType:
            profileType === ProfileType.seller
              ? t('form.bottomRemarkCustomer')
              : t('form.bottomRemarkSeller'),
        })}
      />
    </BidAddPage>
  )
}
