import { endpoints } from 'modules/endpoints'
import { ApiService } from 'service/api/interface'

import {
  ProfileAddressDTO,
  ProfileCompanyInfoDTO,
  ProfileDTO,
  ProfileInfoDTO,
  ProfileUpdateDTO,
  TokenResponse,
  TokensPair,
  UserProfile,
} from './types'

export const requestSmsCode = (apiClient: ApiService) => (phone: string) =>
  apiClient.post(endpoints.requestSmsCode(), { phone })

export const checkSmsCode =
  (apiClient: ApiService) =>
  async (phone: string, code: string): Promise<TokensPair> => {
    const res = await apiClient.post<TokenResponse>(endpoints.phoneLogin(), { phone, code })
    return { accessToken: res.access, refreshToken: res.refresh }
  }

export const refreshToken =
  (apiClient: ApiService) =>
  async (token: string): Promise<TokensPair> => {
    const { access, refresh } = await apiClient.post<TokenResponse>(endpoints.refreshToken(), { token })
    return {
      accessToken: access,
      refreshToken: refresh,
    }
  }

export const fetchCurrentUser = (apiClient: ApiService) => () =>
  apiClient.get<UserProfile>(endpoints.profile())

export const updateCurrentUser = (apiClient: ApiService) => (profile: Partial<ProfileDTO>) =>
  apiClient.put<UserProfile>(endpoints.profile(), profile)

export const updateCurrentUserProfile = (apiClient: ApiService) => (profile: ProfileUpdateDTO) =>
  apiClient.put<ProfileUpdateDTO>(endpoints.updateProfile(), profile)

export const updateUserInfo = (apiClient: ApiService) => (dto: Partial<ProfileInfoDTO>) =>
  apiClient.post<UserProfile>(endpoints.signUpProfile(), dto)

export const updateUserCompanyInfo = (apiClient: ApiService) => (dto: Partial<ProfileCompanyInfoDTO>) =>
  apiClient.post<UserProfile>(endpoints.signUpCompany(), dto)

export const updateUserAddress = (apiClient: ApiService) => (dto: Partial<ProfileAddressDTO>) =>
  apiClient.post<UserProfile>(endpoints.signUpAddress(), dto)
