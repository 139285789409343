import { useSelector } from 'react-redux'

import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import { BidType } from 'modules/domain/bid/types'

const useBidTypeByProfile = () => {
  const profileType = useSelector(AuthSelectors.profileType)
  const isAuthorized = useSelector(AuthSelectors.isAuthenticated)
  if (!profileType || !isAuthorized) {
    return null
  }
  return profileType === ProfileType.customer ? BidType.sale : BidType.purchase
}

export default useBidTypeByProfile
