import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  Button,
  Checkbox,
  Progress,
  SectionBody,
  SectionContainer,
  SectionTitle,
  useAction,
  useHistoryPush,
} from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import env from 'env'
import useHelmet from 'hooks/useHelmet'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import usePrevious from 'hooks/usePrevious'
import UserAddressActions from 'modules/domain/userAddress/duck'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { UserAddressDTO } from 'modules/domain/userAddress/types'
import AddressForm from 'views/components/AddressForm/AddressForm'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'

const ProfileAddressAdd: React.FC = () => {
  const { t } = useTranslation('ProfileAddressItem')
  const push = useHistoryPush()
  const goBack = () => push({ route: ProfileRoutes.ProfileAddresses })

  useHelmet({ title: t('titleAdd') })

  const addAction = useAction(UserAddressActions.addRequested)
  const addProgress = useSelector(UserAddressSelectors.addProgress)
  useNotificationProgress(addProgress)

  const prevProgress = usePrevious(addProgress)
  useEffect(() => {
    if (addProgress === Progress.SUCCESS && prevProgress === Progress.WORK) {
      goBack()
    }
  }, [addProgress])

  const validationSchema = Yup.object({
    address: Yup.string().required(),
    latitude: Yup.number().required(),
    longitude: Yup.number().required(),
  })

  const formik = useFormik<UserAddressDTO>({
    initialValues: {
      is_main: false,
      address: '',
      latitude: null,
      longitude: null,
      geo_object: null,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: false,
    onSubmit: () => {
      if (!formik.isValid) {
        return
      }
      const { address, latitude, longitude, geo_object, is_main } = formik.values

      addAction({
        dto: {
          address,
          latitude,
          longitude,
          geo_object,
          is_main,
        },
      })
    },
  })

  const coordinateError =
    (formik.touched.latitude && formik.errors.latitude) ||
    (formik.touched.longitude && formik.errors.longitude)

  const addressError = formik.touched.address && formik.errors.address
  const error = addressError
    ? t('AddressForm:errors.emptyAddressString')
    : coordinateError
    ? t('AddressForm:errors.invalidCoords')
    : undefined

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle onBack={goBack}>{t('titleAdd')}</SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody>
        <Checkbox
          isChecked={formik.values.is_main}
          onChange={(_value, isChecked) => formik.setFieldValue('is_main', isChecked)}
          label={t('isMain')}
        />
        <AddressForm
          placeholder={t('AddressForm:addressPlaceholder')}
          noOptionsMessage={() => t('AddressForm:noOptionMessage')}
          apiKey={env.REACT_APP_YA_MAPS_API_KEY}
          invalid={!!error}
          errorText={error}
          focusOnMount
          onChange={({ coords, address, geoObject }) => {
            formik.setValues({
              address,
              latitude: coords?.[0] || null,
              longitude: coords?.[1] || null,
              geo_object: geoObject,
            })
          }}
        />
        <div>
          <Button
            disabled={!formik.dirty}
            filled
            intent="primary"
            onClick={formik.submitForm}
            progress={addProgress}
          >
            {t('common:save')}
          </Button>
        </div>
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileAddressAdd
