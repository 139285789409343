import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconButton, IconRefresh, Spinner } from '@agro-club/agroclub-shared'

import env from 'env'
import useFormatNumber from 'hooks/useFormatNumber'
import { BidPrice } from 'modules/domain/bid/types'
import { Progress } from 'modules/types'
import { PriceCard } from 'views/components/BidOfferPriceCards/PriceCard'
import { PriceType } from 'views/pages/BidOffer/BidOffer'
import * as S from 'views/pages/BidOffer/styled'

type Props = {
  priceProgress: Progress
  handlePriceFetchRetry?: () => void
  handleChangePrice: (priceType: PriceType) => void
  price: BidPrice | null
  priceType: PriceType
}

export const BidOfferPriceCards: React.FC<Props> = ({
  priceProgress,
  handlePriceFetchRetry,
  handleChangePrice,
  price,
  priceType,
}) => {
  const { t } = useTranslation('BidOffer')
  const formatNumber = useFormatNumber()
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  if (priceProgress === Progress.ERROR && handlePriceFetchRetry) {
    return (
      <S.PriceCardList>
        <S.PriceListErrorContainer>
          <S.PriceListErrorText>{t('priceFetchErrorText')}</S.PriceListErrorText>
          <IconButton onClick={handlePriceFetchRetry} Icon={IconRefresh}>
            {t('priceFetchRetryText')}
          </IconButton>
        </S.PriceListErrorContainer>
      </S.PriceCardList>
    )
  }

  if ([Progress.WORK, Progress.IDLE].includes(priceProgress)) {
    return (
      <S.PriceCardList>
        <S.PriceListLoadingContainer>
          <Spinner />
        </S.PriceListLoadingContainer>
      </S.PriceCardList>
    )
  }

  if (priceProgress === Progress.SUCCESS && price) {
    return (
      <S.PriceCardList>
        <PriceCard
          onClick={handleChangePrice}
          price={`${formatNumber(price.price_exw, true)} ${t('units', { currency })}`}
          selected={priceType === 'exw'}
          priceType="exw"
          quality={t('exwQualityText')}
          paymentTerms={t('exwPaymentTerms')}
        />
        <PriceCard
          onClick={handleChangePrice}
          price={`${formatNumber(price.price_cpt, true)} ${t('units', { currency })}`}
          selected={priceType === 'cpt'}
          priceType="cpt"
          quality={t('cptQualityText')}
          paymentTerms={t('cptPaymentTerms')}
        />
      </S.PriceCardList>
    )
  }

  return null
}
