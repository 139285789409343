import styled from 'styled-components'

export const GroupTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.secondary300};
  margin-bottom: 8px;
`
