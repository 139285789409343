import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount, useDidMountWithProgress } from '@agro-club/agroclub-shared'

import { Progress, ResourceHook } from 'modules/types'

import ProductActions from './duck'
import ProductSelectors from './selectors'
import { Product } from './types'

export const useProductList: ResourceHook<Product[]> = () => {
  const fetchProgress = useSelector(ProductSelectors.listFetchProgress)
  const list = useSelector(ProductSelectors.list)
  const fetchAction = useAction(ProductActions.listRequested)

  const progress = useDidMountWithProgress(fetchAction, fetchProgress)

  return [progress, list]
}

export const useProductListCached: ResourceHook<Product[]> = () => {
  const progress = useSelector(ProductSelectors.listFetchProgress)
  const list = useSelector(ProductSelectors.list)
  const fetchAction = useAction(ProductActions.listRequested)

  useDidMount(() => {
    if (progress !== Progress.SUCCESS) {
      fetchAction()
    }
  })

  return [progress, list]
}

export const useProductListManaged: ResourceHook<Product[]> = () => {
  const progress = useSelector(ProductSelectors.listFetchProgress)
  const list = useSelector(ProductSelectors.list)
  return [progress, list]
}

export const useProductItem: ResourceHook<Product> = (id: string) => {
  const meta = useSelector((state) => ProductSelectors.meta(state, id))
  const item = useSelector((state) => ProductSelectors.item(state, id))
  const fetchAction = useAction(ProductActions.itemRequested, id)

  const progress = useDidMountWithProgress(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, meta.fetchProgress)

  return [progress, item]
}

export const useProductItemById: ResourceHook<Product> = (id: string) => {
  const meta = useSelector((state) => ProductSelectors.meta(state, id))
  const item = useSelector((state) => ProductSelectors.item(state, id))
  const fetchAction = useAction(ProductActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
