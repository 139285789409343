import React, { VFC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useDidMount } from '@agro-club/agroclub-shared'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useBidTypeByProfile from 'hooks/useBidTypeByProfile'
import useHelmet from 'hooks/useHelmet'
import { BidType } from 'modules/domain/bid/types'
import { AnalyticsEvent } from 'modules/types'
import { Article } from 'views/layouts/MainLayout/MainLayout'
import { CategoryList } from 'views/pages/Main/CategoryList'
import HowItWorks from 'views/pages/Main/HowItWorks'
import { CategorySection, H2, HeaderGroup, PreFooter, Wrapper } from 'views/pages/Main/styled'
import FlatSegment from 'views/ui/FlatSegment/FlatSegment'

const MainPage: VFC = () => {
  const { t } = useTranslation('MainPage')
  const { track } = useAnalyticsSSR()
  const defaultBidType = useBidTypeByProfile()
  const [bidType, setBidType] = useState<BidType>(defaultBidType || BidType.purchase)

  useHelmet({ title: t('title') })

  const filterItems = Object.keys(BidType)
    .filter((type) => {
      if (!defaultBidType) {
        return true
      }
      return type === defaultBidType
    })
    .map((type) => ({
      id: type,
      title: t(`filterLabel.${type}`),
    }))

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'harvest_screen' })
  })

  return (
    <>
      <Article>
        <Wrapper>
          <CategorySection>
            <HeaderGroup>
              <H2>{t('h2')}</H2>
              <div>
                <FlatSegment
                  id="bid-type-select"
                  flat
                  value={bidType}
                  items={filterItems}
                  onChange={(bidType) => {
                    track('harvest_toggle_tap', { action: bidType })
                    setBidType(bidType as BidType)
                  }}
                />
              </div>
            </HeaderGroup>
            <CategoryList bidType={bidType} />
          </CategorySection>
        </Wrapper>
      </Article>
      <PreFooter>
        <HowItWorks />
      </PreFooter>
    </>
  )
}

export default MainPage
