import { BidType } from 'modules/domain/bid/types'
import { Deal } from 'modules/domain/deal/types'
import { Address, CropParameter, EntityMetadata } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { Dict } from 'types/generics'

export type UserBidState = {
  items: Dict<UserBid>
  meta: Dict<EntityMetadata>
  ids: string[]
  listFetchProgress: Progress
  listFetchError: unknown
  listFetchNextProgress: Progress
  listFetchNextError: unknown
  itemFetchProgress: Progress
  itemFetchError: unknown
  addProgress: Progress
  addError: unknown
  updateProgress: Progress
  updateError: unknown
  removeProgress: Progress
  removeError: unknown

  filter: UserBidListRequestFilter
  sorting: UserBidListRequestSorting
  page: number
  total: number
  pageSize: number

  // dict by bidId
  bidDeals: Dict<{
    fetchProgress: Progress
    deals: BidDeal[]
    // dict by BidDeal['id']
    offer?: Dict<{ sendProgress: Progress }>
  }>
}

export type ReducedBid = {
  address: Address | null
  company: {
    logo: string | null
  }
  parameters: CropParameter[]
}

export enum DealSource {
  response = 'response',
  matching = 'matching',
}

export type BidDeal = Omit<Deal, 'sale_bid' | 'purchase_bid'> & {
  sale_bid: ReducedBid
  purchase_bid: ReducedBid
  source: DealSource
}

export type UserBid = {
  id: string
  address: Address
  bid_type: BidType
  company: {
    name: string
    logo: string
  }
  parameters: CropParameter[]
  created: string
  modified: string
  parameters_description: string
  product: {
    id: string
    slug: string
    title: string
  }
  quantity: number
  status: string
  price_cpt: number
  price_exw: number
  distance: number
  offers_count: number
}

export type UserBidParameter = {
  parameter?: string
  condition: keyof typeof UserBidParameterCondition
  parameter_value?: number
}

export enum UserBidParameterCondition {
  lte = '≤',
  gte = '≥',
}

export enum UserBidFilterStatus {
  Published = 'published',
  Archived = 'archived',
}

export type UserBidListRequestFilter = {
  search?: string
  status?: UserBidFilterStatus
}

export type UserBidListRequestSorting = {
  sort_field?: keyof UserBid
  sort_reversed?: boolean
}

export type UserBidDTO = {
  address?: string // addressId
  product?: string // productId
  price?: number
  quantity?: number
  parameters: UserBidParameter[]
}

export type ProfileBidOffer = {
  quantity: number
  can_deliver: boolean
}
