import { all, call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import AuthSelectors from 'modules/domain/auth/selectors'
import UserAddressSelectors from 'modules/domain/userAddress/selectors'
import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { updateLocationQuery } from 'modules/sagaHelpers'
import { ListResponse } from 'types/api'
import BidItemRoutes from 'views/pages/BidItem/routes'
import BidOfferRoutes from 'views/pages/BidOffer/routes'
import BidsRoutes from 'views/pages/Bids/routes'

import BidActions from './duck'
import * as managers from './managers'
import { queryValidator, sortValidator } from './schemas'
import BidSelectors from './selectors'
import { Bid, BidPrice } from './types'

export const fetchList = function* () {
  try {
    let currentPage = yield select(BidSelectors.page)
    let filter = yield select(BidSelectors.filter)
    const sorting = yield select(BidSelectors.sorting)
    const pageSize = yield select(BidSelectors.pageSize)
    const isAuthorized = yield select(AuthSelectors.isAuthenticated)
    const addressId = yield select(UserAddressSelectors.selectedId)

    filter = { ...filter, ...(addressId && isAuthorized && { source_address: addressId }) }

    let response: ListResponse<Bid> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, current, count } = response
    yield put(BidActions.listRequestSucceed(results, count, current))

    yield call(updateLocationQuery, BidsRoutes.Bids, { page: currentPage, ...filter, ...sorting })
  } catch (err) {
    yield put(BidActions.listRequestFailed())
  }
}

export const fetchListNext = function* () {
  try {
    const page = yield select(BidSelectors.page)
    const filter = yield select(BidSelectors.filter)
    const sorting = yield select(BidSelectors.sorting)
    const pageSize = yield select(BidSelectors.pageSize)
    const { results, count }: ListResponse<Bid> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(BidActions.listRequestNextSucceed(results, count))
  } catch (err) {
    yield put(BidActions.listRequestNextFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof BidActions.itemRequested>) {
  try {
    const item: Bid = yield call(apiCall, managers.getItem, id)
    yield put(BidActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(BidActions.itemRequestFailed(id))
  }
}

export const addItem = function* ({ payload: { dto } }: ReturnType<typeof BidActions.addRequested>) {
  try {
    const item: Bid = yield call(apiCall, managers.addItem, dto)
    yield put(BidActions.addSucceed(item))
  } catch (err) {
    yield put(BidActions.addFailed())
  }
}
export const updateItem = function* ({ payload: [id, dto] }: ReturnType<typeof BidActions.updateRequested>) {
  try {
    const item: Bid = yield call(apiCall, managers.updateItem, id, dto)
    yield put(BidActions.updateSucceed(item))
  } catch (err) {
    yield put(BidActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof BidActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(BidActions.removeSucceed(payload))
  } catch (err) {
    yield put(BidActions.removeFailed(payload))
  }
}

export const createBidResponse = function* ({
  payload: [bidId, dto],
}: ReturnType<typeof BidActions.bidResponseCreateRequested>) {
  try {
    yield apiCall(managers.createBidResponse, bidId, dto)
    yield put(BidActions.bidResponseCreateSucceed())
  } catch (err) {
    yield put(BidActions.bidResponseCreateFailed())
  }
}

export const fetchItemPrice = function* ({
  payload: [itemId, addressId],
}: ReturnType<typeof BidActions.itemPriceRequested>) {
  try {
    const price: BidPrice = yield call(apiCall, managers.getItemPrice, itemId, addressId)
    yield put(BidActions.itemPriceRequestSucceed(itemId, addressId, price))
  } catch (err) {
    yield put(BidActions.itemPriceRequestFailed(itemId, addressId, err))
  }
}

const routeWatcher = makeRouteWatcher(
  { ...BidsRoutes, ...BidItemRoutes, ...BidOfferRoutes },
  function* ({ query, params, match, silent }) {
    if (silent) {
      return
    }
    switch (match) {
      case BidsRoutes.Bids: {
        const { query: resultQuery } = queryValidator({
          ...query,
          ...params,
        })

        const { query: sortQuery } = sortValidator(query)
        const { page = 1, ...filter } = resultQuery
        return yield put(BidActions.listRequested({ filter, page, sorting: sortQuery }))
      }
      case BidItemRoutes.BidItem: {
        yield put(BidActions.itemRequested(params.id))
        const addressId = yield select(UserAddressSelectors.selectedId)
        if (addressId) {
          yield put(BidActions.itemPriceRequested(params.id, addressId))
        }
        return
      }
      case BidOfferRoutes.BidItemOffer: {
        yield put(BidActions.itemRequested(params.id))
        const addressId = yield select(UserAddressSelectors.selectedId)
        if (addressId) {
          yield put(BidActions.itemPriceRequested(params.id, addressId))
        }
      }
    }
  },
)

const BidSaga = function* () {
  yield all([
    takeLatest(BidActions.itemRequested.type, fetchItem),
    takeLatest(BidActions.listRequested.type, fetchList),
    takeLatest(BidActions.filterUpdated.type, fetchList),
    takeLatest(BidActions.sortingUpdated.type, fetchList),
    takeLatest(BidActions.filterHasBeenReset.type, fetchList),
    takeLatest(BidActions.sortingHasBeenReset.type, fetchList),

    takeLatest(BidActions.listRequestedNext.type, fetchListNext),

    takeLatest(BidActions.addRequested.type, addItem),
    takeLatest(BidActions.updateRequested.type, updateItem),
    takeLatest(BidActions.removeRequested.type, removeItem),
    takeEvery(BidActions.itemPriceRequested.type, fetchItemPrice),
    takeLatest(BidActions.bidResponseCreateRequested.type, createBidResponse),

    fork(routeWatcher),
  ])
}

export default BidSaga
