import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'

import { SectionBody, SectionContainer, SectionTitle, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useUserBidListManaged } from 'modules/domain/userBid/hooks'
import { UserBid } from 'modules/domain/userBid/types'
import { AnalyticsEvent, Progress } from 'modules/types'
import EmptyList from 'views/pages/Profile/EmptyList'
import { ProfileEvents } from 'views/pages/Profile/events'
import {
  BidCardAddress,
  BidCardPrice,
  BidCardTitle,
  BidCardVolume,
  CardTitleContainer,
  NumberBadge,
} from 'views/pages/Profile/ProfileBidsPage/styled'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as Styled from 'views/pages/Profile/styled'
import { AddIconButton } from 'views/ui/AddIconButton/AddIconButton'
import CardLink from 'views/ui/CardLink/CardLink'
import CardLinkSkeleton from 'views/ui/CardLinkSkeleton/CardLinkSkeleton'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

const BidCard: React.FC<{ bid: UserBid }> = ({ bid }) => {
  const { t } = useTranslation('common')
  const { track } = useAnalyticsSSR()
  const formatNumber = useFormatNumber()
  const price = bid?.bid_type === 'purchase' ? bid?.price_cpt : bid?.price_exw
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return (
    <CardLink
      onClick={() => track('my_offers_offer_tap', { section: bid.product.title, id: bid.id })}
      $border
      to={generatePath(ProfileRoutes.ProfileBidItem, { id: bid.id })}
    >
      <CardTitleContainer>
        <BidCardTitle>{bid.product.title}</BidCardTitle>
        {!!bid.offers_count && <NumberBadge>{bid.offers_count}</NumberBadge>}
      </CardTitleContainer>
      <BidCardVolume>
        {formatNumber(bid.quantity)} {t('t')}
      </BidCardVolume>
      <BidCardPrice>
        {formatNumber(price, true)} {t('rubKg', { currency })}
      </BidCardPrice>
      <BidCardAddress>{bid?.address?.address}</BidCardAddress>
    </CardLink>
  )
}

const BidsListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 16px;
  padding: 0 8px;
`
const BidsList: React.FC<{ items: UserBid[] }> = ({ items }) => (
  <BidsListWrapper>
    {items.map((item) => (
      <BidCard key={item.id} bid={item} />
    ))}
  </BidsListWrapper>
)

const SkeletonList: React.FC = () => {
  const skeletons = new Array(3).fill(null).map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <CardLinkSkeleton key={`skeleton-${i}`} $border $minHeight={128}>
      <SkeletonText $height={24} $width={70} />
      <SkeletonText $height={20} $width={50} />
      <SkeletonText $height={24} $width={40} $marginTop={14} />
      <SkeletonText $height={20} $width={100} />
    </CardLinkSkeleton>
  ))
  return <BidsListWrapper>{skeletons}</BidsListWrapper>
}

const ProfileBidsList: React.FC = () => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('ProfileBidsList')
  const history = useHistory()

  const [progress, list = []] = useUserBidListManaged()

  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'my_offers_screen' })
  })

  useHelmet({ title: t('title') })

  return (
    <SectionContainer noDivider>
      <Styled.HiddenOnMediaBlock hideMobile>
        <SectionTitle>
          {t('title')}
          <AddIconButton
            onClick={() => {
              track(ProfileEvents.ProfileCreateOffer)
              history.push(ProfileRoutes.ProfileBidAdd)
            }}
            title={t('addBidTitle')}
          />
        </SectionTitle>
      </Styled.HiddenOnMediaBlock>
      <SectionBody paddedOnMobile>
        {[Progress.IDLE, Progress.WORK].includes(progress) ? (
          <SkeletonList />
        ) : !list.length && progress !== Progress.WORK ? (
          <EmptyList
            title={t('emptyListTitle')}
            description={t('emptyListDescription')}
            actionText={t('emptyListActionButtonText')}
            onClick={() => history.push(ProfileRoutes.ProfileBidAdd)}
          />
        ) : (
          <BidsList items={list} />
        )}
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileBidsList
