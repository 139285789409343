import React from 'react'
import { CellProps } from 'react-table'

import styled from 'styled-components'

import useBidTypeByProfile from 'hooks/useBidTypeByProfile'
import useFormatNumber from 'hooks/useFormatNumber'
import { Bid, BidType } from 'modules/domain/bid/types'

const ProductDescription = styled.div`
  white-space: pre-wrap;
`

export const ProductCell: React.FC<CellProps<Bid, Bid['product']>> = ({ row, column, value }) => {
  const { parameters } = row.original

  const formatNumber = useFormatNumber()

  const parameter = parameters?.length ? parameters[0] : undefined
  const mainParameter = parameter
    ? `${parameter.parameter.name} ${parameter.condition_label} ${formatNumber(parameter.parameter_value)}`
    : ''

  return (
    <div key={column.id}>
      <div>
        <b>{value.title}</b>
      </div>
      <ProductDescription>{mainParameter}</ProductDescription>
    </div>
  )
}
export const PriceExwCell: React.FC<CellProps<Bid, string>> = ({ cell }) => {
  const defaultBidType = useBidTypeByProfile()
  const formatNumber = useFormatNumber()
  return (
    <>
      {defaultBidType === BidType.purchase ? '~' : ''}
      {formatNumber(cell.value, true)}
    </>
  )
}
export const PriceCptCell: React.FC<CellProps<Bid, string>> = ({ cell }) => {
  const defaultBidType = useBidTypeByProfile()
  const formatNumber = useFormatNumber()
  return (
    <>
      {defaultBidType === BidType.sale ? '~' : ''}
      {formatNumber(cell.value, true)}
    </>
  )
}
export const QtyCell: React.FC<CellProps<Bid, number>> = ({ cell }) => {
  const formatNumber = useFormatNumber()
  return <>{formatNumber(cell.value)}</>
}

export const DistanceCell: React.FC<CellProps<Bid, number>> = ({ cell }) => {
  const formatNumber = useFormatNumber()
  return <>{formatNumber(cell.value)}</>
}
