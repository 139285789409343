import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useAction, useDidMount, usePageQuery } from '@agro-club/agroclub-shared'

import { ResourceHook } from 'modules/types'

import DealActions from './duck'
import DealSelectors from './selectors'
import { Deal } from './types'

export const useDealList: ResourceHook<Deal[]> = () => {
  const progress = useSelector(DealSelectors.listFetchProgress)
  const list = useSelector(DealSelectors.list)
  const page = usePageQuery()
  const fetchAction = useAction(DealActions.listRequested, page ? { page } : {})

  useDidMount(fetchAction)

  return [progress, list]
}

export const useDealListManaged: ResourceHook<Deal[]> = () => {
  const progress = useSelector(DealSelectors.listFetchProgress)
  const list = useSelector(DealSelectors.list)
  return [progress, list]
}

export const useDealItem: ResourceHook<Deal> = (id: string) => {
  const meta = useSelector((state) => DealSelectors.meta(state, id))
  const item = useSelector((state) => DealSelectors.item(state, id))
  const fetchAction = useAction(DealActions.itemRequested, id)

  useDidMount(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  })

  return [meta.fetchProgress, item]
}

export const useDealItemManaged: ResourceHook<Deal> = (id: string) => {
  const meta = useSelector((state) => DealSelectors.meta(state, id))
  const item = useSelector((state) => DealSelectors.item(state, id))
  return [meta.fetchProgress, item]
}

export const useDealItemById: ResourceHook<Deal> = (id: string) => {
  const meta = useSelector((state) => DealSelectors.meta(state, id))
  const item = useSelector((state) => DealSelectors.item(state, id))
  const fetchAction = useAction(DealActions.itemRequested, id)

  useEffect(() => {
    if (!item || item.id !== id) {
      fetchAction()
    }
  }, [item, id, fetchAction])

  return [meta.fetchProgress, item]
}
