import React from 'react'
import { useTranslation } from 'react-i18next'

import styled, { StyledProps, css } from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.color.onPrimaryLight};
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  font-style: normal;

  width: 100%;

  ${(props) => props.theme.media.mobile`
    flex-direction: column-reverse;
  `}
`

const FormSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  :first-child {
    margin-top: 24px;
  }

  ${(props) => props.theme.media.mobile`
    width: 100%;
    max-width: 400px;
  `}
`

const FormSectionTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: 100%;

  text-transform: uppercase;

  color: ${({ theme }) => theme.color.secondary200};
`

const Optional = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.accentNotify};
  margin-left: auto;
  text-transform: none;
`

export const FormSection: React.FC<{ title?: string; optional?: boolean }> = ({
  title,
  children,
  optional,
}) => {
  const { t } = useTranslation('common')
  return (
    <FormSectionContainer>
      {!!title && (
        <FormSectionTitle>
          {title}
          {!!optional && <Optional>{t('optional')}</Optional>}
        </FormSectionTitle>
      )}
      {children}
    </FormSectionContainer>
  )
}

export const OnlyDesktop = styled.div`
  width: 100%;
  ${(props) => props.theme.media.tablet`
    display: none;
  `}

  ${(props) => props.theme.media.mobile`
    display: none;
  `}
`

type HiddenOnMediaProps = {
  hideTablet?: boolean
  hideMobile?: boolean
  hideDesktop?: boolean
}

type FormRowProps = HiddenOnMediaProps & { width?: string }

export const HiddenOnMedia = css`
  ${(props) => props.theme.media.desktop`
    display: ${({ hideDesktop }: HiddenOnMediaProps) => (hideDesktop ? 'none' : 'inherit')};
  `}

  ${(props) => props.theme.media.tablet`
    display: ${({ hideTablet }: HiddenOnMediaProps) => (hideTablet ? 'none' : 'inherit')};
  `}

  ${(props) => props.theme.media.mobile`
    display: ${({ hideMobile }: HiddenOnMediaProps) => (hideMobile ? 'none' : 'inherit')};
  `}
`

export const HiddenOnMediaBlock = styled.div<HiddenOnMediaProps>`
  ${HiddenOnMedia};
`

export const FormRow = styled.div<StyledProps<FormRowProps>>`
  margin-bottom: 16px;

  width: ${({ width }) => width || '100%'};

  ${HiddenOnMedia};
`

export const UppercaseText = styled.span`
  text-transform: uppercase;
`

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-right: auto;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${(props) => props.theme.media.mobile`
    overflow: hidden;
    max-width: 194px;
    text-overflow: ellipsis;
`}
`

export const PartnershipInfo = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.onPrimaryDark};
  margin: -8px 0 16px;
`

export const PartnershipButton = styled.button`
  border: none;
  background: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.color.primary600};
  margin: 16px 0 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`
