import { useSelector } from 'react-redux'

import { Progress } from '@agro-club/agroclub-shared'

import UserAddressSelectors from './selectors'
import { UserAddress } from './types'

export const useUserAddressListManaged = (): [Progress, UserAddress[]] => {
  const progress = useSelector(UserAddressSelectors.listFetchProgress)
  const list = useSelector(UserAddressSelectors.list)

  return [progress, list]
}

export const useUserAddressItemManaged = (id?: string): [Progress, UserAddress | undefined] => {
  if (!id) return [Progress.IDLE, undefined]
  const meta = useSelector((state) => UserAddressSelectors.meta(state, id))
  const item = useSelector((state) => UserAddressSelectors.item(state, id))

  return [meta.fetchProgress, item]
}
