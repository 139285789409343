import env from 'env'

export const fixReceivedUrl = (url: string) => {
  if (!env.BROWSER && env.BASE_ROUTER_PATH && env.BASE_ROUTER_PATH !== '/') {
    return url.replace(env.BASE_ROUTER_PATH, '')
  }
  return url
}

export const fixReturnUrl = (url: string) => {
  if (!env.BROWSER && env.BASE_ROUTER_PATH && env.BASE_ROUTER_PATH !== '/') {
    return `${env.BASE_ROUTER_PATH}${url}`
  }
  return url
}
