import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SectionBody, SectionContainer, SectionTitle, useDidMount } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'
import useFormatNumber from 'hooks/useFormatNumber'
import useHelmet from 'hooks/useHelmet'
import { useDealListManaged } from 'modules/domain/deal/hooks'
import { Deal } from 'modules/domain/deal/types'
import { AnalyticsEvent, Progress } from 'modules/types'
import EmptyList from 'views/pages/Profile/EmptyList'
import { ProfileEvents } from 'views/pages/Profile/events'
import ProfileRoutes from 'views/pages/Profile/routes'
import * as S from 'views/pages/Profile/styled'
import CardLinkSkeleton from 'views/ui/CardLinkSkeleton/CardLinkSkeleton'
import SkeletonText from 'views/ui/SkeletonText/SkeletonText'

const DealsListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  grid-gap: 16px;
  padding: 0 8px;
`

const Card = styled.div<{ $minHeight?: number; $border?: boolean }>`
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: ${(props) => props.theme.color.onPrimaryDark};
  background-color: ${(props) => props.theme.color.onPrimaryLight};
  min-height: ${(props) => `${props.$minHeight ?? 128}px`};
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  transition: border-color 0.1s ease-in, background 0.1s ease-in;
  border: 1px solid ${(props) => (props.$border ? props.theme.color.onSurfaceLowEmphasys : 'transparent')};
`

const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

const CardBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.accentNotify};
  color: ${(props) => props.theme.color.onPrimaryLight};
  border-radius: 10px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
`

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
const CardSubTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const CardPriceContainer = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
`

const CardPrice = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`

const CardAddress = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const CardCompany = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
export const FirstCapitalize = styled.span`
  &::first-letter {
    text-transform: capitalize;
  }
`

const DealCard: React.FC<{ deal: Deal }> = ({ deal }) => {
  const { track } = useAnalyticsSSR()
  const { t } = useTranslation('ProfileDealsList')
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const formatNumber = useFormatNumber()
  useDidMount(() => {
    track(AnalyticsEvent.Page, { name: 'my_deals_screen' })
  })

  return (
    <Card $border $minHeight={152}>
      <CardTitleContainer>
        <CardTitle>{deal.product.title}</CardTitle>
        <CardBadge>
          <FirstCapitalize>{deal.status_label}</FirstCapitalize>
        </CardBadge>
      </CardTitleContainer>
      <CardSubTitle>{deal.product.title}</CardSubTitle>
      <CardPriceContainer>
        <CardPrice>
          {formatNumber(deal.price_exw, true)} {t('cardPrice', { currency })}
        </CardPrice>
      </CardPriceContainer>
      <CardAddress>{deal.sale_bid?.address?.address}</CardAddress>
      <CardCompany>{deal.sale_bid?.company?.name}</CardCompany>
    </Card>
  )
}

const DealsList: React.FC = () => {
  const { t } = useTranslation('ProfileDealsList')
  const { track } = useAnalyticsSSR()
  const [progress, list = []] = useDealListManaged()
  const history = useHistory()

  useHelmet({ title: t('title') })

  const bidAddClick = () => {
    track(ProfileEvents.ProfileCreateDeal)
    history.push(ProfileRoutes.ProfileBidAdd)
  }

  if (progress === Progress.WORK) {
    const skeletons = new Array(10).fill(0).map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <CardLinkSkeleton key={`skeleton-${i}`} $border $minHeight={152}>
        <SkeletonText $width={60} $height={20} />
        <SkeletonText $width={50} $height={20} />
        <SkeletonText $width={100} $height={24} $marginTop={14} />
        <SkeletonText $width={60} $height={20} />
        <SkeletonText $width={100} $height={20} $marginTop={8} />
      </CardLinkSkeleton>
    ))
    return <DealsListWrapper>{skeletons}</DealsListWrapper>
  }

  if (progress === Progress.SUCCESS && !list.length) {
    return (
      <EmptyList
        title={t('emptyListTitle')}
        description={t('emptyListDescription')}
        actionText={t('emptyListActionButtonText')}
        onClick={bidAddClick}
      />
    )
  }

  return (
    <DealsListWrapper>
      {list.map((item) => (
        <DealCard deal={item} key={item.id} />
      ))}
    </DealsListWrapper>
  )
}

const ProfileDealsList: React.FC = () => {
  const { t } = useTranslation('ProfileDealsList')

  return (
    <SectionContainer noDivider>
      <S.HiddenOnMediaBlock hideMobile>
        <SectionTitle>{t('title')}</SectionTitle>
      </S.HiddenOnMediaBlock>
      <SectionBody paddedOnMobile>
        <DealsList />
      </SectionBody>
    </SectionContainer>
  )
}

export default ProfileDealsList
