import { ImmerReducer, createActionCreators, createReducerFunction } from 'immer-reducer'

import { AddError } from 'modules/domain/types'
import { Progress } from 'modules/types'
import { AvailableCountries } from 'types/entities'

import {
  AuthState,
  ProfileAddressDTO,
  ProfileCompanyInfoDTO,
  ProfileInfoDTO,
  ProfileUpdateDTO,
  RegisterProfileDTO,
  TokensPair,
  UserProfile,
} from './types'

export type TokenRequestFailedPayload = {
  message: string
}

export type ResetPasswordRequestedPayload = { email: string }
export type ResetPasswordRequestFailedPayload = { message: string }

const initialState: AuthState = {
  isAuthenticated: false,
  isProfileFulfilled: false,
  error: '',
  resetPasswordError: '',
  email: '',
  selectedCountry: 'RU',
  phone: '',
  step: 'credentials',
  suggestedPhone: '',
  profile: undefined,
  submitCredentialsProgress: Progress.IDLE,
  smsCodeVerificationProgress: Progress.IDLE,
  registerProgress: Progress.IDLE,
  authProgress: Progress.IDLE,
  authError: null,
  initProgress: Progress.IDLE,

  profileInfoUpdateProgress: Progress.IDLE,
  profileCompanyUpdateProgress: Progress.IDLE,
  profileCompanyUpdateError: null,
  profileAddressUpdateProgress: Progress.IDLE,
  currentProfileUpdateProgress: Progress.IDLE,
}

const isProfileFulfilled = (profile: UserProfile): boolean => profile.signup_step === 'complete'

class AuthReducer extends ImmerReducer<AuthState> {
  emailSubmitted(email: string) {
    this.draftState.submitCredentialsProgress = Progress.WORK
    this.draftState.email = email
  }

  phoneSubmitted(phone: string, countryCode: AvailableCountries) {
    this.draftState.submitCredentialsProgress = Progress.WORK
    this.draftState.selectedCountry = countryCode
    this.draftState.phone = phone
  }

  submitSucceed(suggestedPhone?: string) {
    this.draftState.suggestedPhone = suggestedPhone || this.draftState.suggestedPhone || ''
    this.draftState.submitCredentialsProgress = Progress.SUCCESS
    this.draftState.step = 'sms'
    this.draftState.smsCodeVerificationProgress = Progress.IDLE
  }

  submitFailed(error: string) {
    this.draftState.submitCredentialsProgress = Progress.ERROR
    this.draftState.error = error
  }

  smsCodeVerificationRequested(_phone: string, _code: string) {
    this.draftState.smsCodeVerificationProgress = Progress.WORK
  }

  smsCodeVerificationsSucceed(_payload: TokensPair, profile: UserProfile) {
    this.draftState.smsCodeVerificationProgress = Progress.SUCCESS
    this.draftState.isAuthenticated = true
    this.draftState.profile = profile

    const isFulfilled = isProfileFulfilled(profile)
    this.draftState.isProfileFulfilled = isFulfilled

    if (!isFulfilled) {
      this.draftState.step = 'register'
    } else {
      this.draftState.authProgress = Progress.SUCCESS
    }
  }

  userRegisterRequested(_profile: RegisterProfileDTO) {
    this.draftState.registerProgress = Progress.WORK
  }

  userRegisterSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.isProfileFulfilled = isProfileFulfilled(profile)
    this.draftState.authProgress = Progress.SUCCESS
    this.draftState.registerProgress = Progress.SUCCESS
  }

  userRegisterFailed(err: unknown) {
    this.draftState.authProgress = Progress.ERROR
    this.draftState.registerProgress = Progress.ERROR
    this.draftState.authError = err
  }

  userRegisterCancelled() {
    this.draftState.authProgress = Progress.SUCCESS
  }

  smsCodeVerificationFailed(payload: TokenRequestFailedPayload) {
    this.draftState.error = payload.message
    this.draftState.smsCodeVerificationProgress = Progress.ERROR
  }

  initRequested(_authRequired?: boolean) {
    this.draftState.initProgress = Progress.WORK
  }

  initRequestSucceed(profile: UserProfile) {
    this.draftState.isAuthenticated = true
    this.draftState.profile = profile
    this.draftState.initProgress = Progress.SUCCESS

    const isFulfilled = isProfileFulfilled(profile)
    this.draftState.isProfileFulfilled = isFulfilled
    if (!isFulfilled) {
      this.draftState.step = 'register'
    }
  }

  initRequestFailed() {
    this.draftState.initProgress = Progress.ERROR
  }

  resetPasswordRequested(payload: ResetPasswordRequestedPayload) {
    this.draftState.email = payload.email
  }

  resetPasswordRequestFailed(payload: ResetPasswordRequestFailedPayload) {
    this.draftState.resetPasswordError = payload.message
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetPasswordRequestSucceed() {}

  setStep(step: 'sms' | 'credentials') {
    this.draftState.step = step
  }

  clearAuthProgress() {
    this.draftState.submitCredentialsProgress = Progress.IDLE
    this.draftState.smsCodeVerificationProgress = Progress.IDLE
    this.draftState.authProgress = Progress.IDLE
    this.draftState.step = 'credentials'
  }

  signOutRequested() {
    Object.assign(this.draftState, initialState)
  }

  profileInfoUpdateRequested(_dto: ProfileInfoDTO) {
    this.draftState.profileInfoUpdateProgress = Progress.WORK
  }

  profileInfoUpdateSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.profileInfoUpdateProgress = Progress.SUCCESS
  }

  profileInfoUpdateFailed() {
    this.draftState.profileInfoUpdateProgress = Progress.ERROR
  }

  profileCompanyUpdateRequested(_dto: ProfileCompanyInfoDTO) {
    this.draftState.profileCompanyUpdateProgress = Progress.WORK
  }

  profileCompanyUpdateSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.profileCompanyUpdateProgress = Progress.SUCCESS
  }

  profileCompanyUpdateFailed(error: AddError, errorDetail?: string, errorFields?: Record<string, string[]>) {
    this.draftState.profileCompanyUpdateProgress = Progress.ERROR
    this.draftState.profileCompanyUpdateError = error
    this.draftState.profileCompanyUpdateErrorDetail = errorDetail
    this.draftState.profileCompanyUpdateErrorFields = errorFields
  }

  profileAddressUpdateRequested(_dto: ProfileAddressDTO) {
    this.draftState.profileAddressUpdateProgress = Progress.WORK
  }

  profileAddressUpdateSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.profileAddressUpdateProgress = Progress.SUCCESS
  }

  profileAddressUpdateFailed() {
    this.draftState.profileAddressUpdateProgress = Progress.ERROR
  }

  currentProfileUpdateRequested(_dto: ProfileUpdateDTO) {
    this.draftState.currentProfileUpdateProgress = Progress.WORK
  }

  currentProfileUpdateSucceed(profile: UserProfile) {
    this.draftState.profile = profile
    this.draftState.currentProfileUpdateProgress = Progress.SUCCESS
  }

  currentProfileUpdateFailed() {
    this.draftState.currentProfileUpdateProgress = Progress.ERROR
  }
}

export const AuthActions = createActionCreators(AuthReducer)
export default AuthActions
export const reducer = createReducerFunction(AuthReducer, initialState)
