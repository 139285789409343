import styled from 'styled-components'

export const NumberBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.accentNotify};
  color: ${(props) => props.theme.color.onPrimaryLight};
  border-radius: 10px;
  height: 20px;
  padding: 0 6px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`

export const BidCardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const BidCardVolume = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

export const BidCardPrice = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-top: auto;
`

export const BidCardAddress = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const CardTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`

export const SectionSubTitle = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
`
