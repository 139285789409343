// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore fix that later
import { Middleware, Store, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { END, SagaMiddleware, SagaMonitor } from 'redux-saga'

import { AnalyticsInstance } from 'analytics'
import { routerMiddleware } from 'connected-react-router'
import { History } from 'history'
import { Logger } from 'pino'

import env from 'env'
import rootReducer from 'modules/rootReducer'
import { AppGlobalState } from 'modules/types'
import { middleware as sentryMiddleware } from 'sentry'
import { ApiService } from 'service/api/interface'
import { TokenService } from 'service/token/interface'

type ConfigureStoreOptions = {
  history: History
  initialState: Partial<AppGlobalState>
  apiService: ApiService
  tokenService: TokenService
  analyticsInstance: AnalyticsInstance
  extraMiddlewares?: Middleware[]
  sagaMonitor?: SagaMonitor
  serverLogger?: Logger
}

export const configureStore = async ({
  history,
  initialState,
  apiService,
  tokenService,
  analyticsInstance,
  extraMiddlewares = [],
  sagaMonitor,
  serverLogger,
}: ConfigureStoreOptions): Promise<[Store, SagaMiddleware, () => void]> => {
  const sagaMiddleware = createSagaMiddleware({
    context: {
      apiService,
      tokenService,
      analyticsInstance,
    },
    sagaMonitor,
  })

  const middlewares = [sentryMiddleware, sagaMiddleware, routerMiddleware(history)]
  if (env.REDUX_LOGGER) {
    if (env.BROWSER) {
      const logger = await import('redux-logger')
      middlewares.push(
        logger.createLogger({
          collapsed: true,
          logErrors: false,
          diff: true,
        }),
      )
    }
  }

  if (!env.BROWSER && serverLogger) {
    middlewares.push((_store) => (next) => (action) => {
      if (env.REDUX_LOGGER_SERVER_VERBOSE) {
        serverLogger.info(
          {
            action: action.type,
            payload: action.payload,
          },
          `Action ${action.type}`,
        )
      } else {
        serverLogger.info(`Action ${action.type}`)
      }
      next(action)
    })
  }

  const finalMiddleware = applyMiddleware(...middlewares, ...extraMiddlewares)
  const store = createStore(
    rootReducer(history),
    initialState,
    env.DEVELOPMENT ? composeWithDevTools(finalMiddleware) : finalMiddleware,
  )

  const close = () => store.dispatch(END)

  return [store, sagaMiddleware, close]
}
