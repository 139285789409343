import styled from 'styled-components'

import IconButton from 'views/ui/IconButton/IconButton'

export const StyledIconButton = styled(IconButton)`
  height: 40px;
  min-width: 40px;
  padding: 0 10px;
  ${(props) => props.theme.media.mobile`
    height: 32px;
    min-width: 32px;
    padding: 0 6px;
  `};
`
