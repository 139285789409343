import React from 'react'

import { IconAdd } from '@agro-club/agroclub-shared'

import { StyledIconButton } from 'views/ui/styled'

type Props = {
  title: string
  onClick: () => void
}

export const AddIconButton: React.FC<Props> = (props) => {
  const { title, onClick, ...other } = props
  return (
    <StyledIconButton onClick={onClick} Icon={IconAdd} {...other}>
      {title}
    </StyledIconButton>
  )
}
