import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  IconDeal,
  IconDocument,
  IconUser,
  IconWarehouse,
  MenuContainer,
  MenuItem,
  SectionContainer,
  SectionTitle,
} from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'

import { ProfileEvents } from './events'
import Routes from './routes'

const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 0;

  color: ${({ theme }) => theme.color.onPrimaryDark};

  ${(props) => props.theme.media.mobile`
    font-size: 16px;
  `}
`

const useNavigation = () => {
  const location = useLocation()
  const history = useHistory()

  const isCurrent = useCallback(
    (path?: string | string[]) => {
      if (Array.isArray(path)) {
        return path.some((str) => location.pathname.startsWith(str))
      }
      return path ? location.pathname.startsWith(path) : false
    },
    [location.pathname],
  )

  return {
    isCurrent,
    navigate: history.push,
  }
}

const Menu = () => {
  const { t } = useTranslation('profile')
  const { isCurrent, navigate } = useNavigation()
  const { track } = useAnalyticsSSR()

  return (
    <SectionContainer noDivider>
      <SectionTitle>
        <Title>{t('menu.accountTitle')}</Title>
      </SectionTitle>
      <MenuContainer>
        <MenuItem
          icon={IconDocument}
          onClick={() => {
            track(ProfileEvents.ProfileScreenTap, { type: 'profile_my_offers' })
            navigate(Routes.ProfileBids)
          }}
          active={isCurrent(Routes.ProfileBids)}
        >
          {t('menu.bidsPage')}
        </MenuItem>
        <MenuItem
          icon={IconDeal}
          onClick={() => {
            track(ProfileEvents.ProfileScreenTap, { type: 'my_deals' })
            navigate(Routes.ProfileDeals)
          }}
          active={isCurrent(Routes.ProfileDeals)}
        >
          {t('menu.dealsPage')}
        </MenuItem>
        <MenuItem
          icon={IconUser}
          onClick={() => {
            track(ProfileEvents.ProfileScreenTap, { type: 'profile_tap' })
            navigate(Routes.ProfileInfo)
          }}
          active={isCurrent(Routes.ProfileInfo)}
        >
          {t('menu.profilePage')}
        </MenuItem>
        <MenuItem
          icon={IconWarehouse}
          onClick={() => {
            track(ProfileEvents.ProfileScreenTap, { type: 'address_tap' })
            navigate(Routes.ProfileAddresses)
          }}
          active={isCurrent(Routes.ProfileAddresses)}
        >
          {t('menu.addressesPage')}
        </MenuItem>
      </MenuContainer>
    </SectionContainer>
  )
}

export default Menu
