import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconPickup, IconStore, IconTruck, IconUser } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import env from 'env'
import { useAnalyticsSSR } from 'hooks/useAnalyticsSSR'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`

const BlockList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 38px 0 -18px -24px;
`

const Block = styled.div`
  flex: 1 1 0;
  min-width: 250px;
  padding-left: 24px;
  margin-bottom: 18px;

  svg {
    width: 32px;
    height: 32px;
    fill: ${(props) => props.theme.color.primary600};
  }
`

const BlockHeader = styled.div`
  margin-top: 18px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`

const BlockText = styled.div`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.color.onSurfaceMidEmphasys};
`

const AppRoot = styled.div`
  margin-top: 80px;
  position: relative;
  background-color: #e2f1ff;
  width: 100%;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  padding: 40px 120px 40px 80px;
  ${(props) => props.theme.media.smallTablet`
    justify-content: center;
    padding: 16px 28px 24px;
    margin-bottom: 40px;
  `}
  ${(props) => props.theme.media.tablet`
    padding: 40px 80px;
    border-radius: 16px;
  `}
`

const AppTitleContainer = styled.div`
  max-width: 365px;
  ${(props) => props.theme.media.smallTablet`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  `}
`

const AppTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  ${(props) => props.theme.media.smallTablet`
    width: 100%;
    text-align: center;
  `}
`

const AppIconsContainer = styled.div`
  margin-top: 40px;
  max-width: 315px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  a {
    display: block;
  }

  ${(props) => props.theme.media.smallTablet`
    margin-top: 16px;
  `}
`

const IosBadge = styled.img`
  width: 120px;
  height: 40px;
`

const GooglePlayBadge = styled.img`
  width: 135px;
  height: 40px;
`

const AppPhoneContainer = styled.div`
  source,
  img {
    width: 218px;
    bottom: 0;
    right: 120px;
    position: absolute;
  }

  ${(props) => props.theme.media.tablet`
    right: 80px;
  `}
  ${(props) => props.theme.media.smallTablet`
    display: none;
  `}
`

const HowItWorks: React.FC = () => {
  const { t } = useTranslation('HowItWorks')
  const { track } = useAnalyticsSSR()

  return (
    <Wrapper>
      <Header>{t('title')}</Header>
      <BlockList>
        <Block>
          <IconPickup />
          <BlockHeader>{t('block_1_title')}</BlockHeader>
          <BlockText>{t('block_1_description')}</BlockText>
        </Block>
        <Block>
          <IconUser />
          <BlockHeader>{t('block_2_title')}</BlockHeader>
          <BlockText>{t('block_2_description')}</BlockText>
        </Block>
        <Block>
          <IconStore />
          <BlockHeader>{t('block_3_title')}</BlockHeader>
          <BlockText>{t('block_3_description')}</BlockText>
        </Block>
        <Block>
          <IconTruck />
          <BlockHeader>{t('block_4_title')}</BlockHeader>
          <BlockText>{t('block_4_description')}</BlockText>
        </Block>
      </BlockList>

      {env.SHOW_MOBILE_BANNER && (
        <AppRoot>
          <AppTitleContainer>
            <AppTitle>{t('mobileAppTitle')}</AppTitle>
            <AppIconsContainer>
              {/* eslint-disable max-len */}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://itunes.apple.com/ru/app/%D0%B0%D0%B3%D1%80%D0%BE-%D0%BA%D0%BB%D1%83%D0%B1/id1401935493?l=ru&ls=1&mt=8"
                onClick={() => track('harvest_app_tap', { type: 'ios' })}
              >
                {/* eslint-enable max-len */}
                <IosBadge
                  src="/pages/main/app-store.png"
                  srcSet="/pages/main/app-store.png, /pages/main/app-store@2x.png 2x, /pages/main/app-store@3x.png 3x"
                  alt="App Store Icon"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=ru.agroclub"
                onClick={() => track('harvest_app_tap', { type: 'android' })}
              >
                <GooglePlayBadge
                  src="/pages/main/google-play.png"
                  srcSet="/pages/main/google-play.png,
                          /pages/main/google-play@2x.png 2x,
                          /pages/main/google-play@3x.png 3x"
                  alt="Google Play Icon"
                />
              </a>
            </AppIconsContainer>
          </AppTitleContainer>
          <AppPhoneContainer>
            <picture>
              <source type="image/webp" srcSet="/pages/main/ru_app_iphone@2x.webp" />
              <img
                src="/pages/main/ru_app_iphone.png"
                srcSet="/pages/main/ru_app_iphone.png,
                        /pages/main/ru_app_iphone.png@2x.png 2x,
                        /pages/main/ru_app_iphone.png@3x.png 3x"
                alt="Agro Club app running"
              />
            </picture>
          </AppPhoneContainer>
        </AppRoot>
      )}
    </Wrapper>
  )
}
export default HowItWorks
