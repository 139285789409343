import styled from 'styled-components'

import IconButton from 'views/ui/IconButton/IconButton'

export const StyledIconButton = styled(IconButton)`
  height: 40px;
  min-width: 40px;
  background: ${({ theme }) => theme.color.accentDestruct16a};
  &:focus {
    box-shadow: 0 0 3px ${({ theme }) => theme.color.accentDestruct100};
  }
  &:hover {
    background: ${({ theme }) => theme.color.accentDestruct100};
  }
`
