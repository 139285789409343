import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import env from 'env'
import AuthSelectors from 'modules/domain/auth/selectors'
import { ProfileType } from 'modules/domain/auth/types'
import BidSelectors from 'modules/domain/bid/selectors'
import { BidType } from 'modules/domain/bid/types'
import {
  DistanceCell,
  PriceCptCell,
  PriceExwCell,
  ProductCell,
  QtyCell,
} from 'views/pages/Bids/CellRenderers'

export const useIsPriceHidden = () => {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const profileType = useSelector(AuthSelectors.profileType)
  const filter = useSelector(BidSelectors.filter)

  const isSeller = profileType === ProfileType.seller
  const isCustomer = profileType === ProfileType.customer

  const isCptHidden = isAuthenticated ? isSeller : filter.bid_type === BidType.sale
  const isExwHidden = isAuthenticated ? isCustomer : filter.bid_type === BidType.purchase
  return { isCptHidden, isExwHidden }
}

export const useColumns = () => {
  const { t } = useTranslation('BidsTable')
  const { isCptHidden, isExwHidden } = useIsPriceHidden()
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
  const currency = env.REACT_APP_CURRENCY_SYMBOL

  return useMemo(
    () => [
      {
        headerAlign: 'left',
        headerValign: 'top',
        cellAlign: 'left',
        Header: (
          <div>
            {t('productTableHeader_1')}
            <br />
            {t('productTableHeader_2')}
          </div>
        ),
        Cell: ProductCell,
        accessor: 'product' as const,
      },
      {
        headerAlign: 'right',
        headerValign: 'top',
        cellAlign: 'right',
        Header: (
          <div>
            {t('exwTableHeader_1')}
            <br />
            {t('priceHeaderRubKg', { currency })}
          </div>
        ),
        Cell: PriceExwCell,
        accessor: 'price_exw' as const,
        hidden: isExwHidden,
        sortable: true,
      },
      {
        headerAlign: 'right',
        headerValign: 'top',
        cellAlign: 'right',
        Header: (
          <div>
            {t('cptTableHeader_1')}
            <br />
            {t('priceHeaderRubKg', { currency })}
          </div>
        ),
        Cell: PriceCptCell,
        accessor: 'price_cpt' as const,
        hidden: isCptHidden,
        sortable: true,
      },
      {
        headerAlign: 'right',
        headerValign: 'top',
        cellAlign: 'right',
        Header: (
          <div>
            {t('volumeTableHeader_1')}
            <br />
            {t('unitsInTable')}
          </div>
        ),
        Cell: QtyCell,
        accessor: 'quantity' as const,
        sortable: true,
      },
      {
        headerAlign: 'right',
        headerValign: 'top',
        cellAlign: 'right',
        Header: <div>{t('distanceTableHeader_1')}</div>,
        accessor: 'distance' as const,
        Cell: DistanceCell,
        hidden: !isAuthenticated,
        sortable: true,
      },
    ],
    [t, isAuthenticated, isCptHidden, isExwHidden],
  )
}
