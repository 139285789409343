import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { apiCall } from 'modules/sagaEffects'
import { ListResponse } from 'types/api'

import CompanyActions from './duck'
import * as managers from './managers'
import CompanySelectors from './selectors'
import { Company } from './types'

export const fetchList = function* () {
  try {
    let currentPage = yield select(CompanySelectors.page)
    const filter = yield select(CompanySelectors.filter)
    const sorting = yield select(CompanySelectors.sorting)
    const pageSize = yield select(CompanySelectors.pageSize)

    let response: ListResponse<Company> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      currentPage = pages
    }

    const { results, current, count } = response
    yield put(CompanyActions.listRequestSucceed(results, count, current))

    // yield call(updateLocationQuery, CompanyRoutes.List, { page: currentPage })
  } catch (err) {
    yield put(CompanyActions.listRequestFailed())
  }
}

export const fetchListNext = function* () {
  try {
    const page = yield select(CompanySelectors.page)
    const filter = yield select(CompanySelectors.filter)
    const sorting = yield select(CompanySelectors.sorting)
    const pageSize = yield select(CompanySelectors.pageSize)
    const { results, count }: ListResponse<Company> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(CompanyActions.listRequestNextSucceed(results, count))
  } catch (err) {
    yield put(CompanyActions.listRequestNextFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof CompanyActions.itemRequested>) {
  try {
    const item: Company = yield call(apiCall, managers.getItem, id)
    yield put(CompanyActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(CompanyActions.itemRequestFailed(id))
  }
}

export const addItem = function* ({ payload: dto }: ReturnType<typeof CompanyActions.addRequested>) {
  try {
    const item: Company = yield call(apiCall, managers.addItem, dto)
    yield put(CompanyActions.addSucceed(item))
  } catch (err) {
    yield put(CompanyActions.addFailed())
  }
}
export const updateItem = function* ({
  payload: [id, dto],
}: ReturnType<typeof CompanyActions.updateRequested>) {
  try {
    const item: Company = yield call(apiCall, managers.updateItem, id, dto)
    yield put(CompanyActions.updateSucceed(item))
  } catch (err) {
    yield put(CompanyActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof CompanyActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(CompanyActions.removeSucceed(payload))
  } catch (err) {
    yield put(CompanyActions.removeFailed(payload))
  }
}

const CompanySaga = function* () {
  yield all([
    takeLatest(CompanyActions.itemRequested.type, fetchItem),
    takeLatest(CompanyActions.listRequested.type, fetchList),
    takeLatest(CompanyActions.filterUpdated.type, fetchList),
    takeLatest(CompanyActions.sortingUpdated.type, fetchList),
    takeLatest(CompanyActions.filterHasBeenReset.type, fetchList),
    takeLatest(CompanyActions.sortingHasBeenReset.type, fetchList),

    takeLatest(CompanyActions.listRequestedNext.type, fetchListNext),

    takeLatest(CompanyActions.addRequested.type, addItem),
    takeLatest(CompanyActions.updateRequested.type, updateItem),
    takeLatest(CompanyActions.removeRequested.type, removeItem),
  ])
}

export default CompanySaga
