import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects'

import { getCurrentPosition, getGeolocationStatus } from 'helpers/geolocation'
import { apiCall, makeRouteWatcher } from 'modules/sagaEffects'
import { ListResponse } from 'types/api'
import ProfileRoutes from 'views/pages/Profile/routes'

import UserAddressActions from './duck'
import * as managers from './managers'
import UserAddressSelectors from './selectors'
import { UserAddress } from './types'

export const fetchList = function* () {
  try {
    const currentPage = yield select(UserAddressSelectors.page)
    const filter = yield select(UserAddressSelectors.filter)
    const sorting = yield select(UserAddressSelectors.sorting)
    const pageSize = yield select(UserAddressSelectors.pageSize)

    let response: ListResponse<UserAddress> = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      currentPage,
      pageSize,
    )
    const pages = Math.ceil(response.count / pageSize)

    if (pages !== 0 && pages < currentPage) {
      response = yield call(apiCall, managers.getList, filter, sorting, pages, pageSize)
      // currentPage = pages
    }

    const { results, count, current } = response
    yield put(UserAddressActions.listRequestSucceed(results, count, current))
    yield put(UserAddressActions.itemSelected(results[0]?.id || null))
  } catch (err) {
    yield put(UserAddressActions.listRequestFailed())
  }
}

export const fetchListNext = function* () {
  try {
    const page = yield select(UserAddressSelectors.page)
    const filter = yield select(UserAddressSelectors.filter)
    const sorting = yield select(UserAddressSelectors.sorting)
    const pageSize = yield select(UserAddressSelectors.pageSize)
    const { results, count }: { results: UserAddress[]; count: number } = yield call(
      apiCall,
      managers.getList,
      filter,
      sorting,
      page,
      pageSize,
    )
    yield put(UserAddressActions.listRequestNextSucceed(results, count))
  } catch (err) {
    yield put(UserAddressActions.listRequestNextFailed())
  }
}

export const fetchItem = function* ({ payload: id }: ReturnType<typeof UserAddressActions.itemRequested>) {
  try {
    const item: UserAddress = yield call(apiCall, managers.getItem, id)
    yield put(UserAddressActions.itemRequestSucceed(item))
  } catch (err) {
    yield put(UserAddressActions.itemRequestFailed(id))
  }
}

export const addItem = function* ({ payload: { dto } }: ReturnType<typeof UserAddressActions.addRequested>) {
  try {
    const item: UserAddress = yield call(apiCall, managers.addItem, dto)
    yield put(UserAddressActions.addSucceed(item))
  } catch (err) {
    yield put(UserAddressActions.addFailed())
  }
}
export const updateItem = function* ({
  payload: [id, dto],
}: ReturnType<typeof UserAddressActions.updateRequested>) {
  try {
    const item: UserAddress = yield call(apiCall, managers.updateItem, id, dto)
    yield put(UserAddressActions.updateSucceed(item))
  } catch (err) {
    yield put(UserAddressActions.updateFailed(id))
  }
}

export const removeItem = function* ({ payload }: ReturnType<typeof UserAddressActions.removeRequested>) {
  try {
    yield call(apiCall, managers.removeItem, payload)
    yield put(UserAddressActions.removeSucceed(payload))
  } catch (err) {
    yield put(UserAddressActions.removeFailed(payload))
  }
}

export const detectLocation = function* (
  _action: ReturnType<typeof UserAddressActions.coordinatesRequested>,
) {
  try {
    const position: GeolocationPosition = yield call(getCurrentPosition)
    const lat = position.coords.latitude
    const lng = position.coords.longitude
    const regionName: string | null = yield apiCall(managers.getRegionByCoords, { lat, lng })

    yield put(UserAddressActions.coordinatesRequestSucceed({ lat, lng, regionName }))
  } catch (err) {
    yield delay(1000)
    if (err instanceof GeolocationPositionError && err.code === 1) {
      return yield put(UserAddressActions.geolocationStatusUpdated('declined'))
    }
    yield put(UserAddressActions.geolocationStatusUpdated('error'))
  }
}

const geolocationPermissionStateWatcher = function* () {
  if (!process.browser) {
    return
  }
  try {
    const status: PermissionStatus = yield call(getGeolocationStatus)
    switch (status.state) {
      case 'denied':
        return yield put(UserAddressActions.geolocationStatusUpdated('declined'))
      case 'granted':
        return yield put(UserAddressActions.coordinatesRequested())
    }
  } catch (err) {
    console.log(err)
    yield put(UserAddressActions.geolocationStatusUpdated('error'))
  }
}

const routeWatcher = makeRouteWatcher(ProfileRoutes, function* ({ match, params, silent }) {
  if (silent) {
    return
  }

  switch (match) {
    case ProfileRoutes.ProfileAddresses: {
      yield put(UserAddressActions.listRequested({}))
      break
    }
    case ProfileRoutes.ProfileAddressItem: {
      yield put(UserAddressActions.itemRequested(params.id))
    }
  }
})

const UserAddressSaga = function* () {
  yield all([
    takeLatest(UserAddressActions.itemRequested.type, fetchItem),
    takeLatest(UserAddressActions.listRequested.type, fetchList),
    takeLatest(UserAddressActions.filterUpdated.type, fetchList),
    takeLatest(UserAddressActions.sortingUpdated.type, fetchList),
    takeLatest(UserAddressActions.filterHasBeenReset.type, fetchList),
    takeLatest(UserAddressActions.sortingHasBeenReset.type, fetchList),

    takeLatest(UserAddressActions.listRequestedNext.type, fetchListNext),

    takeLatest(UserAddressActions.addRequested.type, addItem),
    takeLatest(UserAddressActions.updateRequested.type, updateItem),
    takeLatest(UserAddressActions.removeRequested.type, removeItem),
    takeLatest(UserAddressActions.coordinatesRequested.type, detectLocation),
    fork(geolocationPermissionStateWatcher),
    fork(routeWatcher),
  ])
}

export default UserAddressSaga
